import { from, interval as observableInterval, Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit, Inject, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { DataShareService } from './common/data-share.service';
import { ApiManager } from './common/api-manager.service';
import { ConstantsService } from './common/constants.service';
import { StorageService } from './common/storage.service';
import { EventEmitterService } from './common/event.emitter.service';
import { AppUtilService } from './common/app.util.service';
import { AuthService } from './common/auth.service';
import { UserAnalyticsClass } from "./model/api/user-analytics.class";
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { HelpTextService } from './common/help-text.service';
import { LoaderService } from "./common/loader.service";
import { LoaderState } from './model/api/loader.class';
// import { NgxCaptureService } from 'ngx-capture';
import html2canvas from 'html2canvas';
import { NavigationService } from './common/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class AppComponent implements OnInit {
  title = 'Kyyba-IEE';
  @ViewChild('screen') screen: ElementRef;
  loading: Boolean = false;
  public timeTravelFlag: boolean = false;
  public timer: any;
  public applicationDate: any = new Date();
  public isProd = environment.envName;
  public isStg4 = environment.envName;
  public previousAppDate = null;
  public timerSubscription: Subscription;
  public timeOut: any = environment.timeOut;
  public isNotEditable: boolean = false;
  public userAnalytics: UserAnalyticsClass;
  public currentUrl: string;
  show: number = 0;
  public subscription: Subscription;
  public readOnly: boolean = false;
  public botEmailList: any = ['venkataraghavend.mellacheruvu@maryland.gov', 'localUser', 'mdthink.eebot1@maryland.gov', 'mdthink.eebot2@maryland.gov', 'mdthink.eebot3@maryland.gov', 'mdthink.eebot4@maryland.gov', 'mdthink.eebot5@maryland.gov', 'mdthink.eebot6@maryland.gov', 'mdthink.eebot7@maryland.gov', 'mdthink.eebot8@maryland.gov', 'mdthink.eebot9@maryland.gov', 'mdthink.eebot10@maryland.gov', 'mdthink.eebot11@maryland.gov', 'mdthink.eebot12@maryland.gov'];

  public constructor(
    public router: Router,
    public titleService: Title,
    public dataShareService: DataShareService,
    public constantsService: ConstantsService,
    public apiManager: ApiManager,
    public cdr: ChangeDetectorRef,
    public storage: StorageService,
    public emitter: EventEmitterService,
    public utilService: AppUtilService,
    public authService: AuthService,
    public dialog: MatDialog,
    public loaderService: LoaderService,
    public navigationService: NavigationService,
    // public ngxCaptureService: NgxCaptureService
  ) {
    this.captureScreenshot();
    this.titleService.setTitle(this.title);
    router.events.subscribe((event: RouterEvent) => {
    });
    if (this.applicationDate)
      window["systemDate"] = this.applicationDate;
    this.utilService.setCurrentDate(this.applicationDate);
    this.apiManager.fetchData('accessSecurity', undefined,
      ApiManager.GET, undefined, undefined,
      undefined, undefined).toPromise()
      .then(res => {
        console.log(res);
        if (res) {
          if (environment.envName === 'stg') {
            const status = res.user.userStatus.toLowerCase()
            if (res.user && res.user.userId && status == 'active') {
              this.constantsService.userData = res;
              this.determineAccess();
              this.storage.setAccessSecurity(res);
              this.constantsService.setSecurityObject(res);
            } else {
              this.storage.destroyAll();
              this.authService.clientLogout();
            }
          }
          else {
            if (res.user && res.user.userId) {
              this.constantsService.userData = res;
              this.determineAccess();
              this.storage.setAccessSecurity(res);
              this.constantsService.setSecurityObject(res);
            } else {
              this.storage.destroyAll();
              this.authService.clientLogout();
            }
          }

          if (!this.apiManager.personalId && environment.addForgerockHeaders) {
            this.authService.wpSignOn();
          }
        }
        else this.authService.wpSignOn();
      }).catch(err => {
        location.reload();
        console.log(err);
      });
  }

  toggleTimeTravel(e) {
    this.storage.allowTimeTravel = this.timeTravelFlag;
    if (!this.storage.allowTimeTravel) {
      this.applicationDate = new Date();
      window["systemDate"] = this.applicationDate;
      this.utilService.setCurrentDate(this.applicationDate);
      this.router.navigate(['../../homeScreen']);
    } else {
      this.storage.setTimeTravelDate(this.applicationDate);
    }
  }

  captureScreenshot() {
    this.dataShareService.screenCapture$.subscribe((value) => {
      if (value) {
        this.getImageAsString(this.screen.nativeElement).subscribe((img) => {
          if (img) {
            // console.log(img);
            var FileSaver = require('file-saver');
            var blob = this.dataURItoBlob(img);

            //check if QC user save the file to QC DB
            const ref = this.constantsService.userData;
            const roleArraySize = ref.user.roles.filter(role => role.includes('EE_QC')).length; // TODO:Need to change EE_QC_
            if (roleArraySize > 0) {
              this.saveScreenshotFileToDB(blob);
            }

            // FileSaver.saveAs(blob, "sample.png");


            let currentURL: string = this.router.url;
            let getLastFragment = currentURL.split('/').pop() + new Date();
            FileSaver.saveAs(blob, getLastFragment + ".png");
          }
        })
      }
    })
  }

  getImageAsString(screen: HTMLElement): Observable<any> {
    let options: {
      logging: false
    }
    return from(html2canvas(screen, options).then(
      canv => {
        const img = canv.toDataURL('image/png');
        return img;
      }, err => {
        throw new Error(err);
      }
    ))
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  saveScreenshotFileToDB(pfileBLOB) {
    let pFileName = 'EE-Screenshot-' + new Date().toISOString();
    const currentNav = this.navigationService.getCurrentNav(this.router.url);
    if (currentNav && currentNav.length > 0) {
      pFileName = currentNav[0].tag_txt;
    }

    const sendObj = {
      caseId: (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase
        ? ConstantsService.humanServiceModel.benefitsCase.caseId
        : null),
      fileType: pfileBLOB.type,
      fileName: pFileName,
      file: pfileBLOB,
    };

    this.apiManager.fetchData(
      'saveScreenshotFileToDB',
      sendObj,
      ApiManager.FILEUPLOAD,
      undefined,
      undefined,
      () => {
        console.log("FILE FINISHED UPLOADING");
      },
      undefined
    ).subscribe(res => { });

  }

  determineAccess() {
    const ref = this.constantsService.userData;
    let roleArraySize = ref.user.roles.length;
    for (let i = 0; i < roleArraySize; i++) {
      if (ref.user.roles[i] == "EE_INQRY"
        || ref.user.roles[i] == 'EE_FIS_CENTRALWKR'
        || ref.user.roles[i] == "EE_DORS_USER"
        || ref.user.roles[i] == "EE_FIS_LDHWKR"
        || ref.user.roles[i] == "EE_UPLOD") {
        this.isNotEditable = true;
        this.constantsService.isNotEditable = true;
      }
    }
    if (ref.user.roles.indexOf('EE_INQRY') !== -1 && (ref.user.roles.indexOf('EE_EGWKR') !== -1 || ref.user.roles.indexOf('EE_SUPVRS') !== -1 ||
      ref.user.roles.indexOf('EE_SUPUSR') !== -1))
      this.constantsService.isNotEditable = false;
    if (ref.user.roles.indexOf('EE_UPLOD') !== -1 && ref.user.roles.indexOf('EE_CLERICAL_APP_REG') !== -1)
      this.constantsService.isNotEditable = false;

    if (this.botEmailList.indexOf(ref.user.userId) !== -1) {
      this.constantsService.isExparteAccessible = true;
    }

    if (this.botEmailList.indexOf(ref.user.userId) !== -1) {
      this.constantsService.isExparteAccessible = true;
    }

    // set it back to false if there is a role that will override this
    for (let i = 0; i < roleArraySize; i++) {
      if (ref.user.roles[i] != "EE_INQRY"
        && ref.user.roles[i] != "EE_DORS_USER"
        || ref.user.roles[i] == "EE_FIS_LDHWKR"
        && ref.user.roles[i] != "EE_UPLOD") {
        this.isNotEditable = false;
      }
    }
  }

  ngOnInit() {
    console.log(environment.envName);
    this.startTimer(25, false);
    this.emitter.subscribe('timer', (data) => {
      this.startTimer(25, true);
    });
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.show = state.show ? this.show + 1 : this.show - 1;
      console.log(this.show);
    });
    console.log(this.storage.getTimeTravelDate(), new Date());
  }

  // navigationInterceptor(event: RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     this.loading = true;
  //     this.userAnalyticsMethod(event.url);
  //     this.loader.show();
  //   }
  //   if (event instanceof NavigationEnd) {une
  //     this.loading = false;
  //     this.loader.hide();
  //   }
  //   if (event instanceof NavigationCancel) {
  //     this.loading = false;
  //     this.loader.hide();
  //   }
  //   if (event instanceof NavigationError) {
  //     this.loading = false;
  //     this.loader.hide();
  //   }
  //   this.cdr.detectChanges();
  // }

  userAnalyticsMethod(url) {
    if (url != this.currentUrl) {
      this.currentUrl = url;
      if (this.storage.getAccessSecurity()) {
        let caseId: string = ((ConstantsService.humanServiceModel.benefitsCase.caseId) ? ConstantsService.humanServiceModel.benefitsCase.caseId : null);
        let userDetails = this.storage.getAccessSecurity().user;
        this.userAnalytics = {
          'caseId': caseId,
          'email': userDetails.userId,
          'name': userDetails.name,
          'screenDefinition': url,
          'uniqueUserId': userDetails.uniqueId,
          'visitType': 'DHSWP'
        };
        this.apiManager.fetchData('userAnalytics', undefined, ApiManager.POST, this.userAnalytics)
          .toPromise().then(res => {
            this.constantsService.securityUserObj.user.uniqueId = res.uniqueUserId;
            console.log(res);
          }).catch(err => {
            console.log(err);
          })
      }
    }
  }

  changedAppDate(e): void {
    if (e.toString()) {
      // if ((new Date(e.toString())).toDateString() == new Date().toDateString()) {
      //   this.storage.allowTimeTravel = false;
      // } else {
      this.storage.allowTimeTravel = true;
      this.previousAppDate = this.storage.getTimeTravelDate();
      console.log("applicaiton date changed from nav", e, (new Date(e.toString())).toDateString(), this.previousAppDate.toDateString(),
        (new Date(e.toString())).toDateString() === this.previousAppDate.toDateString());
      if ((new Date(e.toString())).toDateString() !== this.previousAppDate.toDateString()) {
        this.storage.setTimeTravelDate(new Date(e.toString()));
        this.storage.storageIssueWorkaround(new Date(e.toString()));
        this.previousAppDate = this.storage.getTimeTravelDate();
        window["systemDate"] = this.previousAppDate;
        this.utilService.setCurrentDate(this.previousAppDate);
        this.router.navigate(['../../homeScreen']);
      }
      this.applicationDate = (this.utilService.prepareSave(e));
      // }
    }
    else {
      this.applicationDate = new Date();
    }
  }

  formatDate(el) {
    if (el) {
      const date = el.split('T');
      const dt = date[0].split('-');
      return (dt[1] + '/' + dt[2] + '/' + dt[0]);
    }
    return el;
  }

  getDate() {
    return new Date();
  }

  presumeTimer(time, byPass?) {
    var seconds;
    let sec;
    let min;
    let minutes;
    let storageTime = this.storage.getTimer();
    let presumeTime: boolean = true;
    if (storageTime != undefined && !byPass) {
      console.log(this.timerSubscription);
      minutes = storageTime.min;
      presumeTime = false;
      seconds = storageTime.sec + storageTime.min * 60;
      this.timer = minutes + ':' + ((storageTime.sec == 0) ? '00' : storageTime.sec);
    }
    else {
      minutes = time;
      seconds = time * 60;
      this.timer = minutes + ':00';
      presumeTime = false;
      this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    }
    if (presumeTime) {
      var interval = setInterval(() => {
        seconds = seconds - 1;
        min = Math.floor(seconds / 60);
        sec = seconds - min * 60;
        if (min < 9 && !this.storage.getModalOpen()) {
          console.log('Open popup');
          this.storage.setModalOpen(true);
          // document.getElementById('openTimeOutModal').click();
          this.openTimeoutDialogDialog();
          clearInterval(interval);
          // this.timerSubscription.unsubscribe();
        }
      }, 1000);
    }
  }

  startTimer(time, byPass?) {
    console.log('Time' + time);
    console.log('ByPasss' + byPass);
    let minutes;
    let seconds;
    let sec;
    let min;
    let action = true;
    let storageTime = this.storage.getTimer();
    if (storageTime != undefined && !byPass) {
      if (this.timerSubscription != undefined) {
        this.timerSubscription.unsubscribe();
      }
      console.log(this.timerSubscription);
      minutes = storageTime.min;
      seconds = storageTime.sec + storageTime.min * 60;
      this.timer = minutes + ':' + ((storageTime.sec == 0) ? '00' : storageTime.sec);
    }
    else {
      if (this.timerSubscription != undefined) {
        this.timerSubscription.unsubscribe();
      }
      minutes = time;
      seconds = time * 60;
      this.timer = minutes + ':00';
      this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    }
    console.log('Timer start' + this.timer);
    console.log('action:' + action);
    this.storage.setModalOpen(false);
    minutes = time;
    seconds = time * 60;
    this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    this.timerSubscription = observableInterval(1000).pipe(
      takeWhile(() => action))
      .subscribe(i => {
        if (this.storage.getTimerExtend()) {
          minutes = time;
          seconds = time * 60;
          this.storage.setTimerExtend(false);
        }
        var x = time;
        seconds = seconds - 1;
        min = Math.floor(seconds / 60);
        sec = seconds - min * 60;
        if (min < 5 && !this.storage.getModalOpen()) {
          console.log('Open popup');
          this.storage.setModalOpen(true);
          // document.getElementById('openTimeOutModal').click();
          this.timerSubscription.unsubscribe();
          this.openTimeoutDialogDialog();
        }
        sec = (sec.toString().length == 1) ? '0' + sec : sec;
        this.timer = min + ':' + ((sec == 0) ? '00' : sec);
        this.storage.setTimer({ 'min': min, 'sec': sec });
      })
  }

  @HostListener('click') onClick() {
    this.storage.setTimerExtend(true);
    environment.timeOut = 10;
  }

  extendTime() {
    this.apiManager.fetchData('accessSecurity', undefined,
      ApiManager.GET, undefined, undefined,
      undefined, undefined).subscribe(
        res => {
          this.storage.setModalOpen(false);
          this.storage.setTimerExtend(true);
          environment.timeOut = 10;
        }
      );
  }

  openTimeoutDialogDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    const dialogRef = this.dialog.open(TimeoutDialogComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
      this.extendTime();
    })
    // dialogRef.updateSize("80%", "80%");
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.extendTime();
      }
    });
  }
}

@Component({
  selector: 'timeout-dialog',
  templateUrl: 'timeout-dialog.html',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class TimeoutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public constantsService: ConstantsService,
    public appUtil: AppUtilService,
    public _utilService: AppUtilService,
    public fb: FormBuilder,
    public shareService: DataShareService,
    public helpTextService: HelpTextService,
    public apiManager: ApiManager,
    public storage: StorageService,
    public authService: AuthService) {
  }
  public timerSubscription: Subscription;
  public timer: any;
  onAdd = new EventEmitter();

  ngOnInit() {
    this.startTimer(10);

  }

  startTimer(time, byPass?) {
    console.log('Time' + time);
    console.log('ByPasss' + byPass);
    let minutes;
    let seconds;
    let sec;
    let min;
    let action = true;
    let storageTime = this.storage.getTimer();
    minutes = time;
    seconds = time * 60;
    this.timer = minutes + ':00';
    this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    console.log('Timer start' + this.timer);
    console.log('action:' + action);
    this.timerSubscription = observableInterval(1000).pipe(
      takeWhile(() => action))
      .subscribe(i => {
        seconds = seconds - 1;
        min = Math.floor(seconds / 60);
        sec = seconds - min * 60;
        sec = (sec.toString().length == 1) ? '0' + sec : sec;
        this.timer = min + ':' + ((sec == 0) ? '00' : sec);
        this.storage.setTimer({ 'min': min, 'sec': sec });
        if (min == 0 && sec == 0) {
          this.storage.destroyAll();
          this.timerSubscription.unsubscribe();
          this.dialogRef.close(false);
          this.authService.wpSignOn();
        }
      })
    // var interval = setInterval(() => {
    //   seconds = seconds - 1;
    //   min = Math.floor(seconds / 60);
    //   sec = seconds - min * 60;
    //   sec = (sec.toString().length == 1) ? '0' + sec : sec;
    //   this.timer = min + ':' + ((sec == 0) ? '00' : sec);
    //   this.storage.setTimer({ 'min': min, 'sec': sec });
    //   if (min == 0 && sec == 0) {
    //     clearInterval(interval);
    //     this.storage.destroyAll();
    //     this.dialogRef.close(false);
    //     this.authService.clientLogout();
    //   }
    // }, 1000);
    // this.timerSubscription = observableInterval(1000).pipe(
    //   takeWhile(() => action))
    //   .subscribe(i => {
    //     seconds = seconds - 1;
    //     min = Math.floor(seconds / 60);
    //     sec = seconds - min * 60;
    //     // if (min < 9) {
    //     //   console.log('Open popup');
    //     //   this.storage.setModalOpen(true);
    //     // }
    //     if (min == 0 && sec == 0) {
    //       action = false;
    //       console.log('close popup')
    //       this.storage.destroyAll();
    //       this.dialogRef.close(false);
    //       this.authService.clientLogout();
    //     }

    //     sec = (sec.toString().length == 1) ? '0' + sec : sec;
    //     this.timer = min + ':' + ((sec == 0) ? '00' : sec);
    //     this.storage.setTimer({ 'min': min, 'sec': sec });
    //   })
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }

  extendTime() {
    this.dialogRef.close(true);
    this.apiManager.fetchData('accessSecurity', undefined, ApiManager.GET, undefined, undefined, undefined, undefined).subscribe(res => {
      this.storage.setModalOpen(false);
      this.storage.setTimerExtend(true);
      environment.timeOut = 10;
    });
  }
}
