import { Person } from './person.class';
import {BaseBean} from "./base-bean.class";

export class AbsentParentMilitaryInfo  extends BaseBean{
  constructor (
    public veteranStatus: string = '',
    public veteranServiceBranch: string = '',
    public veteranPayingAllotmentYN: string = '',
    public veteranRecepient: string = '',
    public veteranId: string = '',
    public veteranServiceStartDate: Date = null,
    public veteranServiceEndDate: Date = null
  ) { 
    super();
  }
}
