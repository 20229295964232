import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppAuthGuard } from './app-auth.guard';
import { AppCustomPreloader } from './app.custom.preloader';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ErrorComponentComponent } from 'app/error-component/error-component.component';
// import { LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/homeScreen',
    // component: LandingPageComponent,
    // redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'Login',
    component: LoginComponent
  },
  {
    path: 'error',
    component: ErrorComponentComponent
  },
  {
    path: 'ForgotPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'dashboard',
    canActivate: [AppAuthGuard],
    loadChildren: './dashboard.module#DashBoardModule',
    data: { preload: true }
  },
  {
    path: '**',
    redirectTo: '/dashboard/homeScreen',
    // component: LandingPageComponent,
    // redirectTo: 'dashboard'
  }
];

export const routing = RouterModule.forRoot(routes, {
  useHash: true,
  preloadingStrategy: AppCustomPreloader
});
