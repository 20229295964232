import { Incarceration } from './incarceration.class';
import { BaseBean } from './base-bean.class';
import { DeathDetails } from './death-details.class';
import { VoterRegistration } from './voter-registration.class';
import { LivingArrangement } from './living-arrangement.class';
import { ConcurrentOutStateBenefits } from './concurrent-out-state-benefits.class';
import { MaritalStatus } from './marital-status.class';
import { Veteran } from './veteran.class';
import { F99Details } from './f99-details.class';
import { Pregnancy } from './pregnancy.class';
import { Exemption } from './exemption.class';
import { Penalty } from './penalty.class';
import { Address } from './address.class';
import { Race } from './race.class';
import { X02Details } from './x02-details.class';
import { Striker } from './striker.class';
import { Primaryprevention } from './ppi.class';

export class IndividualDetails extends BaseBean {
  constructor(
    public deathDetails: DeathDetails = new DeathDetails(),
    public voterRegistartionYN: string = '',
    public voterRegistrationStatus: string = '',
    public voterRegistration: VoterRegistration = new VoterRegistration(),
    public veterans: Array<Veteran> = new Array<Veteran>(),
    public maritals: Array<MaritalStatus> = new Array<MaritalStatus>(),
    public livingArrangements: Array<LivingArrangement> = new Array<LivingArrangement>(),
    public f99Details: Array<F99Details> = new Array<F99Details>(),
    public x02Details: Array<X02Details> = new Array<X02Details>(),
    //public pregnancy: Pregnancy =  new Pregnancy(),
    public pregnancy: Array<Pregnancy> =  new Array<Pregnancy>(),
    public birthCity: string = '',
    public birthState: string = '',
    public hospital: string = '',
    public destituteMigrantYN: string = '',
    public boarderMeals: number = undefined,
    public amountPaidForBoarderMeals: number = undefined,
    public concurrentOutStateBenefits: Array<ConcurrentOutStateBenefits> = new Array<ConcurrentOutStateBenefits>(),
    public tcaCounter: string = '',
    public exemptions: Array<Exemption> = new Array<Exemption>(),
    public domesticViolanceYN: string = '',
    public strikerStatus: string = '',
    public penalties: Array<Penalty> = new Array<Penalty>(),
    public primaryRace: Race = new Race(),
    public ethnicity: string = '',
    public primaryVerificationSource: string = '',
    public incarcerations: Array<Incarceration> = new Array<Incarceration>(),
    public ssnApplicationDate: Date = null,
    public differentAddressYN: string = '',
    public individualAddress: Address = new Address(),
    public strikers: Array<Striker> = new  Array<Striker>(),
    public primaryPrevention: Array<Primaryprevention> = new Array<Primaryprevention>(),
  ) {
    super();
  }
}
