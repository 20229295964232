import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ConstantsService } from 'app/common/constants.service';
import { AuthService } from './common/auth.service';
import { CanActivateChild } from '@angular/router/src/interfaces';

@Injectable()
export class AppAuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
    private authService: AuthService,
    private constantsService: ConstantsService) { }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(state.url);
  }

  checkLogin (url: string): boolean {
    ConstantsService.isReadOnlyScreen = ConstantsService.forceReadOnlyMode || this.constantsService.identifyAccess(url);
    if (ConstantsService.goToHomePage && !this.authService.getLoginStatus() && !this.blockNonEERoleAccess()) {
      this.router.navigate(['/dashboard/homeScreen']);
      this.authService.setLoginStatus(true);
      return true;
    } 
    else if (this.authService.getLoginStatus() || ConstantsService.goToHomePage) {
      return this.isAllowedToAccess(url);
    } 
    else {
      this.router.navigate(['/Login']);
      return false;
    }
    //return true;
  }

  isAllowedToAccess(url) {
    console.log('Checking if the user is allowed to access the URL ' + url);
    if (url == '/dashboard/homeScreen') {
      return true;
    } 
    else if (url == '/dashboard/newHomeScreen'){
      return true;
    }
    if (!this.constantsService) {
      return this.unAuthorizedAction();
    }
    let res = this.constantsService.getSecurityObject();
    if (!res || !res.accessMap || (url != '' && (res.accessMap[url] === undefined || res.accessMap[url].accessMode === 'h'))) {
      return this.unAuthorizedAction();
    }
    if (this.blockNonEERoleAccess()) {
      return this.unAuthorizedAction();
    }
    return true;
  }

  // If they have at least one EE role then we can allow access to the system.
  blockNonEERoleAccess() {
    let res = this.constantsService.getSecurityObject();
  
    let roleArraySize = res.user.roles.length;
    for (let i = 0; i < roleArraySize; i++) {
      if (res.user.roles[i].startsWith('EE')) {
          return false;
      }
    }   
    return true;
  }

  unAuthorizedAction(): any {
    this.router.navigate(['error']);
    return false;
  }
}