import { DataShareService } from './../common/data-share.service';
import { Component, OnInit } from '@angular/core';
import { StorageService } from '../common/storage.service';
import { ApiManager } from '../common/api-manager.service';
import { AppUtilService } from '../common/app.util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  systemDate: any = '';

  constructor(private shareService: DataShareService,
    private apiManager: ApiManager,
    private utilService: AppUtilService,
    private storageService: StorageService) { 
    
    this.storageService.getTimeSubject().subscribe(dt => {
      this.systemDate = dt.toDateString();
    });
  }

  ngOnInit() {    
    let systemDate = this.utilService.getCurrentDate().toDateString();
    this.systemDate = systemDate ? systemDate : new Date().toDateString();
  }

  getCopyrightYear() {
    return this.shareService.getDataFromMap('copyrightYear');
  }
}