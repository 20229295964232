import { BaseBean } from "./base-bean.class";
import { ClientVerification } from './client-verification.class';
import { PersonName } from "./person-name.class";
import { SocialSecurityNumber } from "./social-security-number.class";
import { ABChild } from './ab-child.class';
export class AbsentParentIdentification extends BaseBean {
  constructor(
    public nameUnknownYN: string = '',
    // public ivdCoop: string = '',
    public referralDate: Date = null,
    public ivdAssignRightsYN: string = '',
    public gender: string = '',
    public children: ABChild[] = [],
    public ivdNumber: string = '',
    public childSpprt: string = '',
    public soSec: SocialSecurityNumber = new SocialSecurityNumber(),
    public absentParentName: PersonName = new PersonName()
  ) {
    super();
  }
}
