import { BaseBean } from './base-bean.class';
export class MaritalStatus extends BaseBean {
    constructor(
        public marriedYN: string = '',
        public verificationSourceCode: string = '',
        public effectiveBeginDate: Date = undefined,
        public effectiveEndDate: Date = undefined,
        public marriageBeginDate: Date = undefined,
        public marriageEndDate: Date = undefined,
        public recordId: number = undefined
    ) {
        super();
    }

}
