import { BaseBean } from './base-bean.class';
import { Asset } from './asset.class';
import { EarnedIncome } from './earned-income.class';
import { UnearnedIncome } from './unearned-income.class';

export class DependentAllowance extends BaseBean {
    constructor(
    public totalAssets: number = undefined,
    public totalGrossEarnedIncome: number = undefined,
    public totalGrossIncome: number = undefined,
    public totalGrossUnearnedIncome: number = undefined,
    public earnedIncomes: EarnedIncome [] = [],
    public unearnedIncomes: UnearnedIncome [] = [],
    public assets: Asset [] = [],
) {
    super()
}
}