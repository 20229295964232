import { of as observableOf, Observable, Subject, EMPTY, timer, observable } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { flatMap } from 'rxjs/operators';
import { ObservableInput } from 'rxjs/Observable';

// export class AppCustomPreloader implements PreloadingStrategy {
//   preload(route: Route, load: Function): Observable<any> {

//     // return route.data && route.data.preload ? load() : observableOf(null);
//     return route.data && route.data.preload ? this.loadRoute(route.data.delayTime, load) : observableOf(null);
//   }

//   loadRoute(delayTime: number = 0, load: Function) {
//     return delayTime
//             ? timer(delayTime).pipe(flatMap(_ => load()))
//             : load();
//   }
// }


export class OnDemandPreloadOptions {
  constructor(public routePath: string, public preload = true) {}
}

@Injectable({
  providedIn: 'root'
})
export class OnDemandPreloadService {
  private subject = new Subject<OnDemandPreloadOptions>();
  state = this.subject.asObservable();

  startPreload(routePath: string) {
    const message = new OnDemandPreloadOptions(routePath, true);
    this.subject.next(message);
  }
}


@Injectable({ providedIn: 'root', deps: [OnDemandPreloadService] })
export class AppCustomPreloader implements PreloadingStrategy {
  private preloadOnDemand$: Observable<OnDemandPreloadOptions>;

  constructor(private preloadOnDemandService: OnDemandPreloadService) {
    this.preloadOnDemand$ = this.preloadOnDemandService.state;
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.preloadOnDemand$.pipe(
      mergeMap(preloadOptions => {
        const shouldPreload = this.preloadCheck(route, preloadOptions);
        return shouldPreload ? load() : EMPTY;
      })
    );
  }

  private preloadCheck(route: Route, preloadOptions: OnDemandPreloadOptions) {
    return (
      route.data &&
      route.data['preload'] &&
      [route.path, '*'].includes(preloadOptions.routePath) &&
      preloadOptions.preload
    );
  }
}