import { BaseBean } from './base-bean.class';
import {PhoneNumber} from "./phone-number.class";
import {ABCurrentAddress} from "./ab-current-address.class";
export class ABContactInfo extends BaseBean{
  constructor(
    public previousAddresses: ABCurrentAddress [] =[],
    public currentAddress: ABCurrentAddress = new ABCurrentAddress(),
    public phoneNumber: PhoneNumber = new PhoneNumber(),
    public previousPhoneNumbers: PhoneNumber [] =[],
    public email: string = ''
  )
  {
    super();
  }
}
