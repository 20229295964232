import { InstitutionalAdditionalInfo } from './institutional-additional-info.class';
import { BaseBean } from './base-bean.class';
import { Institution } from './institution.class';
import { ClientVerification } from './client-verification.class';
import { InstitutionalWaiver } from './Institutional-waiver.class';
export class InstitutionalInformation extends BaseBean {
  institutionalWaiver: any;
    constructor(
        // public currentInstitution: Institution = new Institution(),
        public institutions: Institution[] = [],
        public institutionalAdditionalInfo: InstitutionalAdditionalInfo[] = [],
        public waivers: InstitutionalWaiver[] =[],
    ) {
        super();
    }
}
