import {BaseBean} from "./base-bean.class";

export class AbsentParentIncarceration  extends BaseBean {
  constructor (
    public incarcerationYN: string = '',
    // public previousIncarcerationYN: string = '',
    public institution: string = '',
    public incarcerationCity: string = '',
    public incarcerationState: string = '',
    public incarcerationCountry: string = ''
  ) {  
    super();
  }
}
