import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ConstantsService } from './constants.service';

@Injectable()
export class DirtyStatusService {

  checkDirtyStatus(form: FormGroup, model: any): boolean {
    let dirtyFormFlag = false;
    if (form && model) {
      if (!form.pristine) {
        model.dirtyStatus = ConstantsService.DIRTY_UPDATE;
        dirtyFormFlag = true;
        for (let formControlField in form.controls) {
          const tempFormControl = form.get(formControlField);
          if (tempFormControl && !tempFormControl.pristine) {
            if (tempFormControl instanceof FormGroup) {
              model[formControlField].dirtyStatus = ConstantsService.DIRTY_UPDATE;
            } 
            else if (tempFormControl instanceof FormArray) {
              let tempModelArray = model[formControlField];
              if (tempModelArray) {
                for (let formArrayControlField in tempFormControl.controls) {
                  const tempFormControlFromArray = tempFormControl.get(formArrayControlField);
                  let tempModel = tempModelArray[formArrayControlField];
                  if (tempFormControlFromArray && tempFormControlFromArray instanceof FormGroup && tempModel) {
                    this.checkDirtyStatus(tempFormControlFromArray, tempModel);
                  }
                }
              }
            }
          }
        }
      }
    }
    return dirtyFormFlag;
  }

  isFormValueChanged(oldModel: any, newModel: any): boolean {
    let changeStatus = false;
    if (oldModel && newModel) {
      let original = JSON.stringify(oldModel).replace(/null/g, '""').replace(/[,]*['"]dirtyStatus['"]:\s*(?!.*delete)[^,}]*/gi, '');
      let updated = JSON.stringify(newModel).replace(/null/g, '""').replace(/[,]*['"]dirtyStatus['"]:\s*(?!.*delete)[^,}]*/gi, '');
      // console.log('Original model: ', original);
      // console.log('Submitted model: ', updated);
      if (original !== updated) {
        changeStatus = true;
      }
    }
    return changeStatus;
  }

  checkFormStatusAndSetDirty(oldModel: any, newModel: any, form: FormGroup): boolean {
    let returnFlag: boolean = false;
    let changeStatus: boolean = false;
    if (oldModel && newModel && form) {
      changeStatus = this.isFormValueChanged(oldModel, newModel);
      if (changeStatus) {
        returnFlag = this.checkDirtyStatus(form, newModel);
        //console.log('Form is updated and dirty flag has been set');
      } 
      else {
        console.log('Form is unchanged');
      }
    }
    return returnFlag;
  }
}