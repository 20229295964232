import { BaseBean } from './base-bean.class';
import { DependentAllowance } from './dependent-allowance.class';
import { FamilyAllowance } from './family-allowance-class';
import { GuardianAllowance } from './guardian-allowance.class';
import { IncomeAvailCOC } from './income-allowance.class';
import { MedicalAllowance } from './medical-allowance.class';
import { PersonalNeedsAllowance } from './personal-need-allowance.class'
import { ResidentialMaintenanceAllowance } from './residential-maintenance-allowance.class';
import { SpousalAllowance } from './spousal-allowance.class';
import { SupportAllowance } from './support-allowance.class';

export class ProgramFinancial extends BaseBean {
    constructor(
        public netAssetStandard: number = undefined,
        public preservedAmount: number = undefined,
        public totalAssets: number = undefined,
        public grossIncome: number = undefined,
        public personNeedsAllowance: number = undefined,
        public spouseFamilyAmount: number = undefined,
        public maintenanceAllowance: number = undefined,
        public nonCoveredMed: number = undefined,
        public appliedIncomeAmount: number = undefined,
        public grossUnearnedIncome: number = undefined,
        public caseDeemedIncome: number = undefined,
        public netUnearnedIncome: number = undefined,
        public grossEarnedIncome: number = undefined,
        public deduction: number = undefined,
        public netEarnedIncome: number = undefined,
        public netIncome: number = undefined,
        public netIncomeStandard: number = undefined,
        public excess: number = undefined,
        public spenddownAmount: number = undefined,
        public medicalExpenseAmount: number = undefined,
        public netSpenddownAmount: number = undefined,
        public costOfCare: number = undefined,
        public totalInsurancePolicy: number = undefined,
        public totalLiquidAssets: number = undefined,
        public totalOtherProperties: number = undefined,
        public totalRealProperties: number = undefined,
        public totalVehicularAssets: number = undefined,  
        public protectedAmount: number = undefined,
        public dependentAllowance: DependentAllowance = new DependentAllowance(),
        public familyAllowance: FamilyAllowance = new FamilyAllowance(),
        public providerId: number = undefined,
        public unearnedIncomeDisregardAmt: number = undefined,
        public earnedIncomeDisregardAmt: number = undefined
        // public guardianAllowance: GuardianAllowance = new GuardianAllowance(),
        // public incomeAvailCOC: IncomeAvailCOC = new IncomeAvailCOC(),
        // public medicalAllowance: MedicalAllowance = new MedicalAllowance(),
        // public personalNeedsAllowance: PersonalNeedsAllowance = new PersonalNeedsAllowance(),
        // public residentialMaintenanceAllowance: ResidentialMaintenanceAllowance = new ResidentialMaintenanceAllowance(),
        // public spousalAllowance: SpousalAllowance = new SpousalAllowance(),
        // public supportAllowance: SupportAllowance = new SupportAllowance()

    ) {
        super()
    }
}
