import {BaseBean} from "./base-bean.class";
import {ClientVerification} from "./client-verification.class";
import {AbsentParentGoodCause} from "./ab-good-cause.class";

export class AbsentParentDemographics  extends BaseBean {
  constructor (
    public relationshipTo: string = '',
    public birthCity: string = '',
    public birthState: string = '',
    public approximateAge: string = '',
    public birthCountry: string = '',
    public dob: string = '',
    public race: string = '',
    public gender: string = '',
    public dobVerification: ClientVerification = new ClientVerification()
    // public abGoodCause: AbsentParentGoodCause = new AbsentParentGoodCause()
  ) {
    super();
  }
}
