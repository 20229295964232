import { AbsentParentMilitaryInfo } from './ab-military-info.class';
import { BaseBean } from "./base-bean.class";
import { Person } from './person.class';
import { PersonName } from './person-name.class';
import { ContactInformation } from './contact-information.class';
import { AbsentParentIncarceration } from "./ab-incarceration.class";
import { AbsentParentMaritalStatus } from "./ab-marital-status.class";
import { AbsentParentIdentification } from "./ab-identification.class";
import { AbsentParentDemographics } from "./ab-demographics.class";
import { AbsentParentPhysicalAttributes } from './ab-physical-attribute.class';
import { AbsentParentGoodCause } from './ab-good-cause.class';
import { AbsentParentMVA } from './ab-mva.class';

export class AbsentParentDetails extends BaseBean {
  constructor(
    public absentParentId: number = undefined,
    public abIdentification: AbsentParentIdentification = new AbsentParentIdentification(),
    public abDemographicInfo: AbsentParentDemographics = new AbsentParentDemographics(),
    public abMaritalInfo: AbsentParentMaritalStatus = new AbsentParentMaritalStatus(),
    public abIncarceration: AbsentParentIncarceration = new AbsentParentIncarceration(),
    public abPhysicalAttributes: AbsentParentPhysicalAttributes = new AbsentParentPhysicalAttributes(),
    public unionOrLocal: string = '',
    public healthInsuranceYN: string = '',
    public effectiveEndDate: Date = null,
    public effectiveBeginDate: Date = null,
    public abMilitaryInfo: AbsentParentMilitaryInfo = new AbsentParentMilitaryInfo(),
    public fatherName: PersonName = new PersonName(),
    public fatherContactInformation: ContactInformation = new ContactInformation(),
    public abGoodCause: AbsentParentGoodCause = new AbsentParentGoodCause(),
    public absentParentMVA:AbsentParentMVA = new AbsentParentMVA(),

    public motherName: PersonName = new PersonName(),
    public motherMaidenName: string = '',
    public motherContactInformation: ContactInformation = new ContactInformation(),
  ) {
    super();
  }
}
