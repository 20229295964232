import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { ApiManager } from "../common/api-manager.service";
// import {Observable} from "rxjs";
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// import {IntakeService} from "../common/intake.service";
import { NavigationService } from "../common/navigation.service";
// import {ProgramsSelected} from "../model/api/programs-selected.class";
// import {hohMembersClass} from "../model/api/InterimChange/ic-hohmembers.class";
import * as _ from "underscore";
import { ConstantsService } from '../common/constants.service';

@Injectable()
export class NavigationResolver implements Resolve<any>{

  constructor(
    // private intakeService: IntakeService,
    private navigationService: NavigationService,
    private constantsService: ConstantsService,
    private apiManager: ApiManager) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let appCd = '';
    if (state.url.includes('/screening/')) {
      appCd = 'AR';
    }
    else if (state.url.includes('/settings/')) {
      appCd = 'ST';
    }
    else if (state.url.includes('/benefitIssue/')) {
      appCd = 'BI';
    }
    else if (state.url.includes('/benefitRecovery/')) {
      appCd = 'BV';
    }
    else {
      appCd = 'ED';
    }

    return observableForkJoin(this.navigationService.getPrograms(appCd)).map(([programs]) => ({ programs }));
  }

  // getPrograms(appCd):Observable<any>{
  //   getPrograms(appCd){
  //     let id: string[] = [];
  //     if(appCd == 'AR'){
  //       this.navigationService.setSelectedNavHead('parentAppRegistraion');
  //     }else {
  //       this.navigationService.setSelectedNavHead('parentEligibilityDetermination');
  //     }
  //   if (ConstantsService.humanServiceModel
  //       && ConstantsService.humanServiceModel.benefitsCase
  //        && ConstantsService.humanServiceModel.benefitsCase.caseId) {
  //     id.push(ConstantsService.humanServiceModel.benefitsCase.caseId);

  //     return  this.apiManager.fetchData('getSelectedPrograms', undefined, ApiManager.GET,
  //     undefined, undefined, undefined, id)
  //       .map(res =>{
  //         if(res ){
  //           this.navigationService.setPrograms(res.selectedPrograms,appCd).toPromise().then(val =>{
  //             this.navigationService.setNavHead(val.filter(v => {return v.is_parent == true;}));
  //             this.navigationService.setNavItems(val);
  //             // this.navigationService.enableHOH();
  //             // this.getMembers();
  //           }).catch(err =>{
  //             console.log(err);
  //           });
  //         }
  //       })
  //     }else {
  //         return this.navigationService.navDBCall(appCd).then(val => {
  //           this.navigationService.setNavHead(val.filter(v => {return v.is_parent == true;}));
  //           this.navigationService.setNavItems(val);
  //         }).catch(err =>{
  //           console.log(err);
  //         })
  //       }


  // }

  // getMembers(){
  //  this.intakeService.getPageAsObs('dashboard/consumer-app/intake-app/summary/homePage').toPromise()
  //     .then(res =>{
  //       if(res != this.intakeService.noJson){
  //         let members: hohMembersClass[] = JSON.parse(res);
  //         if(members.find(v => {return v.relationship == 'SE'})){
  //           this.navigationService.enableAll();
  //         }
  //       }
  //     }).catch(err =>{
  //    console.log(err);
  //  })
  // }
}