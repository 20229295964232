import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable()
export class DataShareService {

  private recallInititated = new BehaviorSubject<Boolean>(false);
  recallSuccess = this.recallInititated.asObservable();

  dataMap: Map<string, any>;
  ClientInfo: any[];
  consumerControlId: number = 0;
  felonyRecordToEdit: any;
  private screenCapture = new BehaviorSubject(null);
  screenCapture$ = this.screenCapture.asObservable();
  recallInitiateSource:string='';

  constructor(private router: Router) {
    this.dataMap = new Map();
    this.addDataToMap('sugPrograms', [
      {
        programName: 'first',
        programType: 'programType1',
        medCoverageGroup: 'medCoverageGroup1',
        availableAuId: 'availableAuId1',
        ppiGroup: 'ppiGroup1',
        selectedYN: 'selectedYN1',
        requestedYN: 'requestedYN1'
      }, {
        programName: 'second',
        programType: 'programType2',
        medCoverageGroup: 'medCoverageGroup2',
        availableAuId: 'availableAuId2',
        ppiGroup: 'ppiGroup2',
        selectedYN: 'selectedYN2',
        requestedYN: 'requestedYN2'
      }
    ])
  }

  screenCaptureEvent(event: boolean) {
    this.screenCapture.next(event);
  }

  screenCaptureDialog(elementRef: ElementRef) {
    this.getImageAsString(elementRef.nativeElement).subscribe((img) => {
      if (img) {
        var FileSaver = require('file-saver');
        var blob = this.dataURItoBlob(img);
        let currentURL: string = this.router.url;
        let getLastFragment = currentURL.split('/').pop() + new Date();
        FileSaver.saveAs(blob, getLastFragment + ".png");
      }
    })
  }
  getImageAsString(screen: HTMLElement): Observable<any> {
    let options: {
      logging: false,
      background: '#FFFFFF',
    }
    return from(html2canvas(screen, options).then(
      canv => {
        const img = canv.toDataURL('image/png');
        return img;
      }, err => {
        throw new Error(err);
      }
    ))
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  addDataToMap(key: string, value: any) {
    if (key) {
      this.dataMap.set(key, value);
    }
  }
  getClientData(key: string, value: any) {
    if (key) {
      this.ClientInfo = this.dataMap.get(key);
    }
    return value;
  }

  consumerPortalControlId(id?) {
    if (id) {
      this.consumerControlId = id;
    }
    else {
      return this.consumerControlId;
    }
  }

  getDataFromMap(key: string): any {
    let val: any;
    if (key) {
      val = this.dataMap.get(key);
    }
    if (key === 'currentOffice' && (this.dataMap.get(key) === undefined || this.dataMap.get(key) === '')) {
      return 'Harbor View';
    }
    return val;
  }
  getDataFromMapAndClearData(key: string): any {
    const val: any = this.getDataFromMap(key);
    this.dataMap.delete(key);
    return val;
  }

  removeDataFromMap(key: string) {
    this.dataMap.delete(key);
  }

  public _toggleSidebar() {
    this.addDataToMap('showRightSidebarMenu', !this.getDataFromMap('showRightSidebarMenu'));
  }

  hideLeftMenu() {
    console.log('Shared Service: hiding left nav');
    this.addDataToMap('hideHeaderMenus', true);
    this.addDataToMap('hideHeaderBar', true);
  }

  showLeftMenu() {
    console.log('Shared Service: show left nav');
    this.addDataToMap('hideHeaderMenus', false);
    this.addDataToMap('hideHeaderBar', false);
  }

  storeFelonyRecord(felonyRecord) {
    this.felonyRecordToEdit = felonyRecord;
  }

  removeFelonyRecord() {
    this.felonyRecordToEdit = null;
  }

  setRecallStatus(recallSuccess):void {
    this.recallInititated.next(recallSuccess);
  }
  setRecallInitiateSource(input){
    this.recallInitiateSource=input;
  }
}