import { ErrorComponentComponent } from "./error-component/error-component.component";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule, Title } from "@angular/platform-browser";
import { TextMaskModule } from "angular2-text-mask";
import { SlimLoadingBarModule } from "ng2-slim-loading-bar";
import { AppCustomPreloader } from "./app.custom.preloader";
import { AppAuthGuard } from "./app-auth.guard";
import { routing } from "./app.routes";
import { FooterModule } from "./footer/footer.module";
import { ApiManager } from "app/common/api-manager.service";
import { AuthService } from "./common/auth.service";
import { ConstantsService } from "app/common/constants.service";
import { DataShareService } from "app/common/data-share.service";
import { AlertService } from "app/common/alert.service";
import { DirtyStatusService } from "./common/dirty-status.service";
import { HelpTextService } from "app/common/help-text.service";
import { DataShareObservableService } from "app/common/data-share-observable.service";
import { MapToFile } from "app/model/custom/map-to-file.class";
import { NguiDatetimePickerModule } from "assets/datetime-picker";
import { AppComponent, TimeoutDialogComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { Ng2AutoCompleteModule } from "ng2-auto-complete";
import { Ng2Webstorage } from "ngx-webstorage";
import { StorageService } from "./common/storage.service";
import { EventEmitterService } from "./common/event.emitter.service";
import { AppUtilService } from "./common/app.util.service";
import { PopoverModule } from "ngx-popover";
import { TooltipModule } from "ngx-tooltip";
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule } from "@angular/common/http";
import { AngularMaterialModule } from "./angular-material/angular-material.module";
import { MatDialogModule, MatIconModule, MatMenuModule } from "@angular/material";
// import { InterviewMemberDetailsModule } from "./InterviewModule/interview-member-details/interview-member-module";
import { LookupManagerService } from "./common/lookup-manager.service";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material";
import { MatRadioModule } from '@angular/material/radio';
import { NavigationService } from "./common/navigation.service";
import { NavigationResolver } from "./resolvers/navigation.resolver";
import { StyleManager } from "./common/style-manager.service";
import { ThemeStorage } from "./common/theme-storage.service";
import { MatButtonModule, MatCardModule, MatToolbarModule } from '@angular/material';
import { MdePopoverModule } from '@material-extended/mde';
import { LoaderService } from "./common/loader.service";
import { LandingPageComponent } from './landing-page/landing-page.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
// import { NgxCaptureModule } from "ngx-capture";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ErrorComponentComponent,
    TimeoutDialogComponent,
    LandingPageComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    MatDialogModule,
    MatRadioModule,
    DragDropModule,
    MatMenuModule,
    // NgxCaptureModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SlimLoadingBarModule.forRoot(),
    FooterModule,
    routing,
    NgxDatatableModule,
    AngularMaterialModule,
    TextMaskModule,
    Ng2AutoCompleteModule,
    Ng2Webstorage,
    NguiDatetimePickerModule,
    PopoverModule,
    TooltipModule,
    NgxSpinnerModule,
    // InterviewMemberDetailsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MdePopoverModule,
    MatIconModule
  ],
  providers: [
    Title,
    LoaderService,
    AppAuthGuard,
    AuthService,
    DataShareService,
    ConstantsService,
    ApiManager,
    MapToFile,
    AppCustomPreloader,
    AlertService,
    DirtyStatusService,
    DataShareObservableService,
    HelpTextService,
    StorageService,
    EventEmitterService,
    AppUtilService,
    LookupManagerService,
    NavigationService,
    NavigationResolver,
    StyleManager,
    ThemeStorage,
  ],
  entryComponents: [TimeoutDialogComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }