
export const logout = {
  stg2: 'https://stag.access.mdthink.maryland.gov/mdtsso/UI/Logout?realm=/dhs&goto=https://stg.ee2.mdthink.maryland.gov/ee/',
  local: 'https://dtabbq7bhl64k.cloudfront.net/UI/Logout?realm=/tesTrealm&goto=https://kcarewp.kyybaapps.com/ee/',
  stg3: 'https://stag.access.mdthink.maryland.gov/mdtsso/UI/Logout?realm=/dhs&goto=https://stg3.ee2.mdthink.maryland.gov/ee/',
  trn: 'https://training.access.mdthink.maryland.gov/openam/UI/Logout?realm=/dhs&goto=https://trn.ee2.mdthink.maryland.gov/ee/',
  uat2: 'https://uat.access.mdthink.maryland.gov/mdtsso/UI/Logout?realm=/dhs&goto=https://uat.ee2.mdthink.maryland.gov/ee/',
  uat1: 'https://uat1.access.mdthink.maryland.gov/mdtsso/UI/Logout?realm=/dhs&goto=https://uat1.ee2.mdthink.maryland.gov/ee/',
  stg4: 'https://stag4.access.mdthink.maryland.gov/mdtsso/UI/Logout?realm=/dhs&goto=https://stag4.nwd.fsp.mdthink.maryland.gov/ee/',
  dev2NWD: 'https://dev4.access.mdthink.maryland.gov/mdtsso/UI/Logout?realm=/dhs&goto=https://dev2.nwd.fsp.mdthink.maryland.gov/ee/',
  dev2: 'https://dtabbq7bhl64k.cloudfront.net/UI/Logout?realm=/tesTrealm&goto=https://kcarewp.kyybaapps.com/ee/'
};
