import {BaseBean} from "./base-bean.class";

export class ABCurrentAddress extends BaseBean{
  constructor(
    public addressId: number  = undefined,
    public addressCareOf :string = '',
    public addressLine1: string = '',
    public addressLine2: string = '',
    public city: string = '',
    public state: string = '',
    public zipcode: string = '',
    public addressStartDate: Date = null,
    public addressEndDate: Date = null,
  )
  {
    super();
  }
}
