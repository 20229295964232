import {logout} from "./logout";
export const environment = {
  production: false,
  envName: 'dev',
  disableConsole: false,
//  baseUrlDev:'https://dtabbq7bhl64k.cloudfront.net/eeapi/',
// baseUrlDev:'http://54.166.102.107:8080/eeapi/',
  baseUrlDev:'http://54.167.180.142:8080/eeapi/',
// baseUrlDev: 'http://54.165.39.194',
 // loginURL: 'http://54.166.102.107:8080/eeapi/',
// loginURL: 'http://182.73.228.218:8080/eeapi/',
  loginURL: 'http://internal-CMEEe1Dev4App-ELB-1375046792.us-east-1.elb.amazonaws.com/',
  logoutURL: logout.local,
  enterpriseSearchURL: '',
  sdrUrl: 'api/',
  addForgerockHeaders: true,
  contactSupport: true,
  timeOut: 10,
  financialHistoryRows: 15,
  OnlineLongTermCareForms: '',
  MarylandLandRecords: '',
  marylandHealthConnection: '',
  MarylandEBT: '',
  ZipCodeSearch: 'http://www.zip-info.com/cgi-local/zipsrch.exe?cnty=cnty&zip=20601&Go=Go',
  MarylandCaseSearch: '',
  MarylandLandRecordsAndDeeds: '',
  DHSSecurityForms:'',
  MDJudiciaryCaseSearch:'',
  DivStateDocs:'',
  DHSKnowledgeBaseFIA:'',
  FIAActionTransmittals:'',
  MedicaidCOMAR:'',
  SDAT:'',
  AVS:'',
  QlikSense: '',
  MMIS:'',
  SAVE: 'https://save.uscis.gov/Web/vislogin.aspx?JS=YES',
  MDMarriageLicenses: '',
  ecmLink: '',
  xpression:'',
  showIC: true,
  myDhrUrl: '',
};
