import { throwError as observableThrowError, of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BenefitsCase } from '../model/api/benefits-case.class';
import { DummyFinalizeMedicalEligibility } from '../model/custom/dummy-finalize-medical-eligibility-class';
import { environment } from 'environments/environment';
import { MapToFile } from '../model/custom/map-to-file.class';
import { FileList } from '../model/custom/file-list.class';
import { Injectable } from '@angular/core';
import { KeyValue } from '../model/api/key-value.class';
import { HouseholdMember } from '../model/api/household-member.class';
import { LookupContainerModel } from '../model/api/lookup-container-model.class';
import { HelpText } from '../model/api/help-text.class';
import { ContactInformation } from '../model/api/contact-information.class';
import { EmergencyAssistance } from '../model/api/emergency-assistance.class';
import { ProgramName } from '../model/api/program-name.class';
import { Employer } from '../model/api/employer.class';
import { Signature } from '../model/api/signature.class'
import { AdditionalInformation } from '../model/api/additional-information.class';
import { AbsentParent } from '../model/api/absent-parent.class';
import { Address } from '../model/api/address.class';
import { Eligibility } from '../model/api/eligibility.class'
import { PhoneNumber } from '../model/api/phone-number.class';
import { Program } from '../model/api/program.class';
import { HumanServices } from '../model/api/human-services.class';
import { AuthorizedRepresentative } from '../model/api/authorized-representative.class';
import { Vendor } from '../model/api/vendor.class';
import { DataShareService } from 'app/common/data-share.service';
import { SchoolInformation } from 'app/model/api/school-information.class';
import { StudentInfo } from 'app/model/api/student-info.class';
import { ClientVerification } from 'app/model/api/client-verification.class';
import { Institution } from 'app/model/api/institution.class';
import { Asset } from 'app/model/api/asset.class';
import { ScheduleInterview } from 'app/model/api/schedule-interview.class';
import { InsurancePolicyDetails } from 'app/model/api/insurance-policy-details.class';
import { EligibilityParamsClass } from 'app/model/api/eligibility-parameter.class';
import { CaseAssignedTo } from "../model/api/case-assigned-to.class";
import { HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders } from "@angular/common/http";
import { LdssCapacityClass } from '../model/api/ldss-capacity.class';
import { IndividualAssetClass } from "../model/api/individual-asset.class";
import { CaseModeEnum } from 'app/enums/case-mode-enum.enum';
import { CaseLevelDetails } from "../model/api/case-level-details.class";
import { AppointmentSchedulingClass } from "../model/api/appointment-scheduling.class";

let logoutURL: any = environment.logoutURL;
let loginURL: any = environment.loginURL;

@Injectable()
export class ConstantsService {
  static forceReadOnlyMode: boolean = false;
  static isReadOnlyScreen: boolean;
  static changeReportingMode: boolean = false;
  static parentalEnable: boolean = false;
  static changedFields: Set<string> = new Set<string>();
  maxDate: string = '9999-12-31';
  static readonly selections = [
    { 'key': 'CH', 'value': 'Cash Assistance', 'selected': false },
    { 'key': 'FS', 'value': 'SNAP', 'selected': false },
    { 'key': 'MA', 'value': 'Medical Assistance', 'selected': false },
    { 'key': 'EA', 'value': 'Emergency Assistance', 'selected': false },
    { 'key': 'IF', 'value': 'Individual and Family Grants', 'selected': false },
    { 'key': 'RP', 'value': 'Repatriated Citizen Assistance', 'selected': false }
  ];

  static showTooltip: boolean;

  isDeletedMember = new BehaviorSubject<boolean>(false);
  static goToHomePage: boolean = true;
  static loaderFlag: boolean = false;
  static logoutUrl: string = logoutURL;
  static loginURL: string = loginURL;
  static eligibilityHistoryInd: boolean = false;
  static dsnapInd: boolean = false;
  // Deployment Environments Random, LTC, EA
  static isIB5 = false;
  static readonly deployment_LTC: string = '/LTC';
  public deployment_LTCOnly: boolean = false;
  public caseNarrativeClicked = false;
  static deployment_EA = '/EA';
  static deployment_Random = '/Random';
  static deploymentMode: string = ConstantsService.deployment_Random;
  static contactInfoData: AuthorizedRepresentative;
  static openCaseNarrativeAdd: boolean = false;
  static caseLevelDetails: CaseLevelDetails;
  static householdDetails: HouseholdMember[] = [];
  public isNotEditable: boolean = false;
  public isExparteAccessible: boolean = false;
  public closedCase: boolean = false;
  static appointmentSchedule: AppointmentSchedulingClass;
  static finalizeServiceModel: DummyFinalizeMedicalEligibility[];
  static showClearanceToolTips = true;
  static isEAInFinalize = true;
  static eduacationlDetail: SchoolInformation;
  static readonly programs = 'programs';
  static readonly Program = 'Program';
  static readonly ProgramType = 'ProgramType';
  static listOfApprovedPrograms: any[];
  static readonly IndividualRelationship = 'IndividualRelationship';
  static readonly members = 'members';
  static recordsInLdss: LdssCapacityClass[] = [];
  static helpTextMap: Map<string, string> = new Map();
  static helpTextMapBehaviorSubject: BehaviorSubject<Map<string, string>> = new BehaviorSubject(ConstantsService.helpTextMap);
  static helpTextMapBehaviorSubjectNext = () => ConstantsService.helpTextMapBehaviorSubject.next(ConstantsService.helpTextMap);
  // DIRTYSTATUS FLAG VALUE
  static readonly DIRTY_INSERT = 'insert';
  static readonly DIRTY_UPDATE = 'update';
  static readonly DIRTY_DELETE = 'delete';
  // TODO remove me
  static standardizedAddress: Address[] = [];
  // STATIC MESSAGES
  static readonly lastNameRequired = 'Last Name is required';
  static readonly firstNameRequired = 'First Name is required';
  static readonly ssnRequired = 'SSN Number Required';
  static readonly invalidSSN = 'Please enter valid SSN in format XXX-XX-XXXX';
  // REGEX's
  static readonly ssnPattern = '^(?!666|000\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$';
  static readonly individualIdPattern = '^I\\w{15}$';
  static readonly caseIdPattern = '\\w{8}$';
  static readonly navbarComponent = 'SIDEBAR';
  static readonly screeningModule = 'SCREENING_MODULE';
  static readonly cpImportModule = 'CP_IMPORT_MODULE';
  static readonly settingsModule = 'SETTINGS_MODULE';
  static readonly customerRelationshipModule = 'CUSTOMER_RELATIONSHIP_MODULE';
  static readonly VerifyChecklistModule = 'VERIFY_CHECKLIST_MODULE';
  static readonly trialBudgetModule = 'TRIAL_BUDGET_MODULE';
  static readonly interviewModule = 'INTERVIEW_MODULE';
  static readonly finalizeModule = 'FINALIZE_MODULE';
  static readonly benefitIssueModule = 'BENEFIT_ISSUE_MODULE';
  static readonly benefitRecoveryModule = 'BENEFIT_RECOVERY_MODULE';
  static readonly interfaceModule = 'INTERFACE_MODULE'
  static readonly caseNarrativeModule = 'CASE_NARRATIVE_MODULE';
  static readonly inquiryModule = 'INQUIRY_MODULE';
  static readonly caseHomeModule = 'CASE_HOME_MODULE';
  static readonly tkstscd = 'tkstscd';
  static readonly vtmclkverf = 'vtmclkverf';
  static readonly linkCpModule = 'LINKCP_MODULE';
  static readonly AlertSubType = 'AlertSubType';
  static readonly AlertType = 'AlertType';
  static readonly changeReportingModule = 'CHANGE_REPORTING_MODULE';
  static readonly redeterminationModule = 'REDETERMINATION_MODULE';
  static readonly correspondenceModule = 'CORRESPONDENCE_MODULE';
  static readonly simplifiedReportModule = 'SIMPLIFIED_REPORT_MODULE';
  static readonly manualNoticesModule = 'MANUAL_NOTICES_MODULE';
  static readonly legacyCaseSearchModule = 'LEGACYCASESEARCH_MODULE';
  static readonly inbox = 'INBOX';
  static readonly myCases = 'RECENT CASES';
  // LOOKUPS
  static readonly AssesmentQuestion = 'AssesmentQuestion';
  static readonly PrimaryLanguage = 'PrimaryLanguage';
  static readonly HearingImpaired = 'HearingImpaired';
  static readonly States = 'States';
  static readonly County = 'County';
  static readonly BiCounty = 'bicounty';
  static readonly appliedunearnedassettype = 'appliedunearnedassettype';
  static readonly VehicleUseCashMa = 'VehicleUse(Cash&Ma)';
  static readonly CommunicationMode = 'CommunicationMode';
  static readonly ContactTime = 'ContactTime';
  static readonly VerificationName = 'VerificationName';
  static readonly SignatureFileType = 'SignatureFileType';
  static readonly OverrideEligibilityStatus = 'ELIGSSTATUS';
  //EALookups
  static readonly EAgoodcause = 'EAgoodcause';
  static readonly EAasset = 'EAasset';
  static readonly EAassetVer = 'EAassetVer';
  static readonly EAincome = 'EAincome';
  static readonly EAincomeverf = 'EAincomeverf';
  static readonly EAexpense = 'EAexpense';
  static readonly EAexpenseverf = 'EAexpenseverf';
  static readonly MedicareVerf = 'MedicareVerf';
  // static readonly EAgoodcause = 'EAgoodcause';
  static readonly DSNAPQuestion = 'DSNAPQuestion';
  static readonly Department = 'Department';
  static readonly Roles = 'Roles';
  static readonly EarningType = 'EarningType';
  static readonly UnearnedIncomeType = 'UnearnedIncomeType';
  static readonly tmclkrmdrs = 'tmclkrmdrs';
  static readonly AssetType = 'AssetType';
  static readonly MedCoverageGroup = 'MedCoverageGroup';
  static readonly ProgramCode = 'ProgramCode';
  static readonly ProgramTypeMA = 'ProgramTypeMA';
  static readonly ProgramTypePA = 'ProgramTypePA';
  static readonly ProgramTypeFS = 'ProgramTypeFS';
  // static readonly EAgoodcause = 'EAgoodcause';
  static readonly ProgramTypeEA = 'ProgramTypeEA';
  static readonly ProgramTypeAF = 'ProgramTypeAF';
  static readonly ProgramTypeGA = 'ProgramTypeGA';
  // contact info dropdowns
  static readonly ProgramTypeIF = 'ProgramTypeIF';
  static readonly ProgramTypeRP = 'ProgramTypeRP';
  static readonly ProgramTypeRF = 'ProgramTypeRF';
  static readonly AuthRepRelation = 'AuthRepRelation';
  // household circumstances
  static readonly AuthRepType = 'AuthRepType';
  static readonly WaiverAutismSchoolCounty = 'WaiverAutismSchoolCounty';
  static readonly MedicalAssistance = 'MedicalAssistance';
  static readonly bvNoticeType = 'bvntctype';
  // recommended programs
  static readonly ApplicationStatus = 'ApplicationStatus';
  static readonly Suffix = 'Suffix';
  static readonly SsnVerificationOption = 'SsnVerificationOption';
  static readonly DobVerificationOption = 'DobVerificationOption';
  static readonly RelationshipType = 'RelationshipType';
  static readonly Relationship = 'Relationship';
  static readonly VerificationWbcchp = 'VerificationWbcchp';
  static readonly Sex = 'Gender';
  static readonly State = 'State';
  static readonly Race = 'Race';
  static readonly SsnReferral = 'SsnReferral';
  static readonly YesNo = 'YesNo';
  static readonly EligibilityOverRide = 'ELIGOVRDE';
  static readonly PPIGroup = 'PPIGroup';
  static readonly AppliedIncomeVerification = 'AppliedIncomeVerification';
  static readonly Verification = 'Verification';
  static readonly LivingArrangementVerificatin = 'LivingArrangementVerificatin';
  static readonly FosterAdoptionType = 'FosterCarAdoptType';
  static readonly ParentalStatus = 'ParentalStatus';
  static readonly ParentalStatusVerification = 'ParentalStatusVerif';
  static readonly NoticeType = 'NoticeType';
  static readonly ManualNotice = 'ManualNotice';
  static readonly RequestedPrograms = 'RequestedPrograms';
  static readonly MDMRace = 'MDMRace';
  static readonly MDMMergeStatus = 'MDMMergeStatus';
  static readonly MartialStatus = 'MartialStatus';
  static readonly StrikerStatus = 'StrikerStatus';
  static readonly PenaltyOrigin = 'PenaltyOrigin';
  static readonly PenaltyDecision = 'PenaltyDecision';
  static readonly Documents = 'Documents';
  static readonly PenaltyType = 'PenaltyType';
  static readonly StatusReason = 'StatusReason';
  static readonly AuthRepAccessType = 'AuthRepAccessType';
  static readonly AuthRepTypeCd = 'AuthRepTypeCd';
  static readonly StatewideFipGroup = 'StatewideFipGroup';
  static readonly VoterRegisteration = 'VoterRegisteration';
  static readonly DisabilityType = 'DisabilityType';
  static readonly BICCodes = 'BICCodes';
  static readonly DisabilityStatus = 'DisabilityStatus'
  static readonly AbawdMonthStatus = 'AbawdMonthStatus';
  static readonly AbawdGoodCause = 'AbawdGoodCause';
  static readonly prmryprvntnsrcverf = 'prmryprvntnsrcverf';
  static readonly ReportStatus = 'RPTSTS';
  static readonly ReasonForHearing = 'ReasonForHearing';
  static readonly PreHearingConferenceRequested = 'PreHearingConferenceRequested';
  static readonly PreHearingConferenceOutcome = 'PreHearingConferenceOutcome';
  static readonly AdministrativeHearingDecision = 'AdministrativeHearingDecision';
  static readonly AppealStatus = 'AppealStatus';
  static readonly Eidicsprmpaid = 'Eidicsprmpaid';
  static readonly Grcprdsts = 'Grcprdsts';
  static readonly disabilitysrcverf = 'disabilitysrcverf';
  static readonly Hrdshpsts = 'Hrdshpsts';
  static readonly ReportMethod = 'RCVMTHD';
  static readonly CategoryOfAssistance = 'CategoryOfAssistance';
  static readonly SSADiscrepancyCodes = 'SSADiscrepancyCodes';
  static readonly SSAErrorCodes = 'SSAErrorCodes';
  static readonly SpecialRequest = 'SpecialRequest';
  static readonly srhousehold = 'srhousehold';
  static readonly sr_address = 'sraddress';
  static readonly sr_shelter = 'srshelter';
  static readonly sr_earned_inc = 'srearnedinc';
  static readonly sr_unearned_inc = 'srunearnedinc';
  static readonly sr_child_support = 'srchildsupport';
  static readonly sr_questions = 'srquestions';
  static readonly SR_GCRS = 'SRGCRS';
  static readonly SimplifiedReportStatus = 'SimplifiedReportStatus';
  static readonly DisabilityApprovalSource = 'DisabilityApprovalSource';
  static readonly ManualNoticesStatus = 'ManualNoticesStatus';
  static readonly UtilityExpense = 'UtilityExpense';
  static readonly ShelterExpense = 'ShelterExpense';
  static readonly GAType = 'GAType';
  static readonly MedicalReviewRecieved = 'MedicalReviewReceivedGA';
  static readonly TreatmentParticipation = 'TreatmentParticipation';
  static readonly TreatmentParticipationVerification = 'TreatmentParticipationVerification';
  static readonly TDAPType = 'TDAPType';
  static readonly PremiumOK = 'PremiumOK';
  static readonly Expenses = 'Expenses';
  static readonly GoodCauseReasons = 'GoodCauseReasons';
  static readonly GoodCauseStatus = 'GoodCauseStatus';
  static readonly RelationOfAbsentParentToChild = 'RelationOfAbsentParentToChild';
  static readonly UtilityExpenseMethods = 'UtilityExpenseMethods';
  static readonly LiquidAsset = 'CLLIQASSETAMTVERCD';
  static readonly RedeterminationStatus = 'RedeterminationStatus';
  static readonly AmountReceivedVerification = 'CLTRNSFAMTVERCD';
  static readonly FareMarketValueVeri = 'CLTRNSFFMVVERCD';
  static readonly MotorVehicleEncumbranceAmount = 'CLVEHENCUMBVERCD';
  static readonly MotorVehicleFareMarketVeri = 'CLVEHFMVAMTVERCD';
  static readonly RealEstateFareMarketVer = 'CLPROPFMVAMTVERCD';
  static readonly RealEstateEncumbranceVeri = 'CLPROPENCUMBVERCD';
  static readonly OtherPropertyFareMarketVeri = 'CLPRSNLPROPFMVVERCD';
  static readonly OtherPropertyEncumbranceVeri = 'CLPRSNLENCUMBVERCD';
  static readonly StudentVerification = 'CLSTDNTSTSVERCD';
  static readonly GoodStandingVerification = 'GOODSTUDENTVERCD';
  static readonly GradDateVerification = 'GRADDATEVERDCODES';
  static readonly EarnedIncomeVerification = 'EarnedIncomeVerificationCode';
  static readonly UnearnedIncomeVerification = 'CLUIAMTVERCD';
  static readonly VerfSourceYesNo = 'VerfSourceYesNo';
  static readonly DeathVerification = 'CLDEATHVERCD';
  static readonly Citizenship = 'Citizenship';
  static readonly CitizenshipVerification = 'CitizenshipVerification';
  static readonly MedicareCertificationCode = 'MedicareCertificationCode';
  static readonly StudentStatusCode = 'StudentStatusCode';
  static readonly StudentStatusVerification = 'StudentStatusVerifiction';
  static readonly EducationalLevel = 'EducationalLevel';
  static readonly IV_DCooperation = 'IVDCooperation';
  static readonly IV_DCooperationIndicator = 'IVDCooperationIndicator';
  static readonly HIGHESTGRADE = 'HighestGrade';
  static readonly GoodStanding = 'GoodStandingSchoolAttendance';
  static readonly IVDCooperationIndicator = 'IVDCooperationIndicator';
  static readonly IssuanceStatusNames = 'isssta';
  //static readonly IssuanceMethod = 'issmthd';
  static readonly IssuanceMethod = 'IssuanceMethod';
  static readonly IssuanceStatus = 'Issuancstatus'; //"bistscds";
  static readonly ClaimThreshold = 'Clmthreshold';
  static readonly IssuanceReason = "bireissrsncds";
  static readonly IssuanceSource = 'ownrtype';
  static readonly aidCd = "aidcd";
  static readonly OTOMedicalVerification = "OTOMedicalVerification";
  static readonly maxLimit = 'issmaxlmt';
  static readonly ReissuaceRsnCd = 'bireissrsncds';
  static readonly IssuanceStsChange = 'bistssrc';
  static readonly PaymentType = 'pyeetyp';
  static readonly cctop = 'ccutopstscd';
  static readonly clmerrtype = 'clmerrtypecd';
  static readonly VendorPayeeType = 'vendorpayeetyp';
  static readonly issntypcd = 'issntypcd';
  static readonly Refundsts = 'Refundsts';
  static readonly Clmtrack = 'Clmtrack';
  static readonly ClaimDoc = 'ClaimDoc';
  // static readonly
  static readonly AFDC = 'AFDC';
  static readonly Status = 'Status';
  static readonly StatusReasonCode = 'StatusReasonCode';
  static readonly HMOType = 'HMOType';
  static readonly HCBWaiver = 'HCBWaiver';
  static readonly HCBWaiverType = 'HCBWaiverType';
  static readonly Waiver = 'Waiver';
  static readonly InstituteProvider = 'InstituteProvider';
  static readonly PhoneType = 'PhoneType';
  static readonly ServiceProvided = 'ServiceProvided';
  static readonly TransferOfAssetType = 'TransferOfAssetType';
  static readonly LevelAuth = 'LevelAuth';
  static readonly PenaltyReason = 'PenaltyReason';
  static readonly RefugeeResettlementAgency = 'RefugeeResettlementAgency';
  static readonly ParoleConvictionType = 'ParoleConvictionType';
  static readonly INSNumberVerif = 'INSNumberVerif';
  static readonly CountryOfOrigin = 'CountryOfOrigin';
  static readonly INSStatusVerificationCodes = 'INSStatusVerificationCodes';
  static readonly INSStatus = 'INSStatus';
  static readonly ImmigrationDocumentType = 'ImmigrationDocumentType';
  static readonly NewlyLegalStatus = 'NewlyLegalStatus';
  static readonly EarnedIncomeExpense = 'EarnedIncomeExpense';
  static readonly TimeLimitedEligiilityExemptionReasons = 'TimeLimitedEligiilityExemptionReasons';
  static readonly MinorParentExemptionReasons = 'MinorParentExemptionReasons';
  static readonly MedicalFileType = 'MedicalFileType';
  static readonly SRTDisbaility = 'SRTDisbaility';
  static readonly IncomeVerification = 'IncomeVerification';
  static readonly ParentsRelationship = 'ParentsRelationship';
  static readonly RelationshipTo = 'RelationshipTo';
  static readonly administrativeinfo = 'administrativeinfo';
  static readonly VeteransStatus = 'VeteransStatus';
  static readonly SupportService = 'SupportService';
  static readonly VeteranBranch = 'VeteranBranch';
  static readonly PayeeDropDown = 'PayeeDropDown';
  static readonly RecepientDropDown = 'RecepientDropDown';
  static readonly FrequencyDropDown = 'FrequencyDropDown';
  static readonly ClockTypeDropDown = 'ClockTypeCd';
  static readonly ChildrenCovered = 'ChildrenCovered';
  static readonly HairColor = 'HairColor';
  static readonly EyeColor = 'EyeColor';
  static readonly OtherCircumstances = 'OtherCircumstances';      // Household Circumstances
  // Institutional history
  static readonly VendorType = 'VendorType';
  static readonly SpecialtyCodes = 'SpecialtyCodes';
  // EA lookups
  static readonly EmergencyType = 'EmergencyType';
  static readonly EmergencyTypeVerification = 'EmergencyTypeVerification';
  static readonly NeedType = 'NeedType';
  static readonly NeedTypeVerification = 'NeedTypeVerification';
  static readonly Eligibility = 'Eligibility';
  static readonly ProgramStatus = 'ProgramStatus';
  static readonly EligibilityStatusCode = 'EligibilityStatusCode';
  static readonly Reasons = 'Reasons';
  static readonly InsuranceDropped = 'InsuranceDropped';
  static readonly ReasonforTansfer = 'ReasonforTansfer';
  static readonly UndueHardshipReasons = 'UndueHardshipReasons';
  static readonly FMVVerification = 'FMVVerification';
  static readonly TransfereeRelationship = 'TransfereeRelationship';
  static readonly DelayReason = 'DelayReason';
  static readonly PolicySource = 'PolicySource';
  static readonly AddAssetsAssetTypeParent = 'AddAssetsAssetTypeParent';
  static readonly LiquidAssetType = 'LiquidAssetType';
  static readonly Insurance = 'Insurance';
  static readonly VehicleType = 'VehicleType';
  static readonly VehicleFairMarketValueVerification = 'VehicleFairMarketValueVerification';
  static readonly RealPropertyFMVVerification = 'RealPorpertyFMVVerification';
  static readonly RealPropertyEncumbranceAmount = 'RealPropertyEncumbranceAmount';
  static readonly LiquidAssetVerification = 'LiquidAssetVerification';
  static readonly VehicleEncumbranceVerification = 'VehicleEncumbranceVerification';
  static readonly AddAssetsAssetTypeRealEstateProperties = 'AddAssetsAssetTypeRealEstateProperties';
  static readonly PropertyType = 'PropertyType()';
  static readonly AddAssetsAssetTypeTransferOfAssets = 'AddAssetsAssetTypeTransferOfAssets';
  static readonly VehicleUse = 'VehicleUse';
  static readonly Frequency = 'Frequency';
  static readonly AppliedUnearnedIncomeType = 'AppliedUnearnedIncomeType';
  static readonly AppliedUnearnedIcomeStatus = 'AppliedUnearnedIcomeStatus';
  static readonly Vendors = 'Vendors';
  static readonly CompletionStatus = 'CompletionStatus';
  static readonly Institution = 'Institution';
  static readonly InstitutionType = 'InstitutionType';
  static readonly Ethnicity = 'Ethnicity';
  static readonly Deprivation = 'Deprivation';
  static readonly ReasonCode = 'ReasonCode';
  static readonly LevelOfCare = 'LevelOfCare';
  static readonly IEPVerify = 'IEPVerify';
  static readonly FinancialResponsibility = 'FinancialResponsibility';
  // static readonly ProgramType = 'ProgramType';
  static readonly DistrictOffice = 'DistrictOffice';
  static readonly Worker = 'Worker';
  static readonly AppointType = 'AppointType';
  static readonly SignatureType = 'SignatureType';
  static readonly PresumptiveEligibility = 'PresumptiveEligibility';
  static readonly VeteranStatusCode = 'VeteranStatusCode';
  static readonly TaxFilingStatus = 'TaxFilingStatus';
  static readonly WaiverDenial = 'WaiverDenial';
  // OtherIncome lookups
  static readonly OtherIncomeType = 'OtherIncomeType';
  static readonly OtherIncomeSubType = 'OtherIncomeSubType';
  static readonly OtherIncomeCompensationPayments = 'OtherIncomeCompensationPayments';
  static readonly OtherIncomeSupportAlimony = 'OtherIncomeSupportAlimony';
  static readonly ClEthnicity = 'ClEthnicity';
  static readonly OtherIncomeContributionsInKind = 'OtherIncomeContributionsInKind';
  static readonly OtherIncomeEducationalAssistance = 'OtherIncomeEducationalAssistance';
  static readonly OtherUI = 'OtherUI';
  static readonly Other = 'Other';
  static readonly VariableInfrequente = 'VariableInfrequent';
  static readonly VolunteerRelated = 'VolunteerRelated';
  static readonly LumpSum = 'LumpSum';
  static readonly ProjectIndependence = 'ProjectIndependence';
  static readonly ShelterRelated = 'ShelterRelated';
  static readonly Relocation = 'Relocation';
  static readonly PublicAssistance = 'PublicAssistance';
  static readonly CashAssistance = 'CashAssistance';
  static readonly NeedsBasedAssistance = 'NeedsBasedAssistance';
  static readonly FosterCareAdoption = 'FosterCareAdoption';
  static readonly ReturnOnInvestments = 'ReturnOnInvestments';
  static readonly Loans = 'Loans';
  static readonly VendorPayments = 'VendorPayments';
  //finalize
  static readonly EligibilityReasonCode = 'EligibilityReasonCode';
  // trial budget dropdowns
  static readonly Type = 'Type';
  static readonly FoodStampsHHtype = 'FoodStampsHHtype';
  // static readonly MedCoverageGroup='MedCoverageGroup';
  static readonly Pregnant = 'Pregnant';
  static readonly Family = 'Family';
  static readonly Refugees = 'Refugees';
  static readonly ABorD = 'ABorD';
  static readonly MASO = 'MASO';
  static readonly FamilyLTC = 'FamilyLTC';
  static readonly MASOLTC = 'MASOLTC';
  static readonly HealthInsurance = 'HealthInsurance';
  // static readonly HCBWaiver='HCBWaiver';
  static readonly FCandSA = 'FCandSA';
  static readonly MiscellaneousM = 'MiscellaneousM';
  static readonly EIexpenseType = 'EIexpenseType';
  // static readonly EarningType='EarningType';
  static readonly MotorVehUse = 'MotorVehUse';
  static readonly EmployerInsuranceAvailabilityIndicator = 'EmployerInsuranceAvailabilityIndicator';
  static readonly EmploymentVerification = 'EmploymentVerification';
  static readonly EmploymentQuitReason = 'EmploymentQuitReason';
  static readonly MedBPayer = 'MedBPayer';
  static readonly CorrespondenceType = 'CorrespondenceType';
  static readonly shelterVerification = 'CLSHLTREXPVERCD';
  static readonly HousingVerification = 'CLSHLTRAMTVERCD';
  static readonly UtilityVerification = 'CLSHLTRAMTVERCD';
  static readonly MedicalExpenseVerification = 'CLMEDEXPNSEAMTVERCD';
  static readonly ChildSpousalExpense = 'ChildSpousalExpense'
  static readonly ssnVerification = 'CLSSNVERCD';
  static readonly dobVerification = 'CLDOBVERCD';
  static readonly CPFelonyConvictionType = 'CPFelonyConvictionType';
  //updated lookups according to DB
  static readonly InsuranceCashValue = 'InsuranceCashValue';
  static readonly PropertyTypeOther = 'PropertyTypeOther';
  // static readonly FMVVerification = 'FMVVerification';
  static readonly EncumbranceAmountVerification = 'EncumbranceAmountVerification';
  static readonly ReturnHomeIn6MonthVerification = 'ReturnHomeIn6MonthVerification';
  static readonly ReasonForTransfer = 'ReasonForTransfer';
  static readonly AmountRecievedVerification = 'AmountRecievedVerification';
  static readonly AUTransfereeRelationship = 'AUTransfereeRelationship';
  static readonly UndueHardshipReason = 'UndueHardshipReason';
  static readonly ChildSupportExpenseVerification = 'ChildSupportExpenseVerification';
  static readonly FrequencyOfPayment = 'FrequencyOfPayment';
  static readonly DayCareExpense = 'DayCareExpense';
  static readonly DayCareExpenseVerification = 'DayCareExpenseVerification';
  static readonly PublicHousingVerification = 'PublicHousingVerification';
  static readonly ShelterUtilityAmountVerification = 'ShelterUtilityAmountVerification';
  static readonly MedicalExpenseType = 'MedicalExpenseType';
  static readonly ChildSpousalExpenseType = 'ChildSpousalExpenseType';
  static readonly CitizenshipVerificationSource = 'CitizenshipVerification';
  static readonly SponsorshipVerification = 'SponsorshipVerification';
  static readonly MedialExpenseVerification = 'MedialExpenseVerification';
  static readonly FrequencyValidVValue = 'FrequencyValidVValue';
  static readonly FairMarketValuationVerification = 'FairMarketValuationVerification';
  static readonly LivingArrangment = 'CLLIVARNGMTVERCD';
  static readonly LivingArrangement = 'LivingArrangement';
  static readonly DisqualificationVerification = 'Disqualificationverificationoptions';
  static readonly FederalReporting = 'fedrepopts';
  static readonly DisqualificationSupervisorVerification = 'DisqVerificationSupervisorOptions';
  static readonly NewHireDispOpts = 'NewHireDisposition';
  static readonly RealPropertyUse = 'RealPropertyUse';
  static readonly LivingArrangementDocumentType = 'LivingArrangementDocumentType';
  static readonly RaceDocumentType = 'RaceDocumentType';
  static readonly DeathDocumentType = 'DeathDocumentType';
  static readonly ParisDispositionCode = 'ParisDispositionCode';
  static readonly AUStatusCode = 'AUStatusCode';
  // social security benefits page lookups
  static readonly ssbType = 'SocialSecurityBenefits';
  static readonly ssbVerification = 'CLUIAMTVERCD';
  static readonly ssbStatus = 'SocialSecurityBenefitsStatus';
  static readonly ssbResult = 'SocialSecurityBenefitsResult';
  static readonly ssbResultReason = 'SocialSecurityBenefitsReason';
  // wag lookups
  static readonly ReasonForWag = 'ReasonForWag';
  //Works Exemptions
  static readonly FSExemptReason = 'FSExemptReason';
  //Non compliance
  static readonly NonComplianceReason = 'NonComplianceType';
  static readonly NonComplianceSNAPReason = 'NonComplianceSNAPReasonCodesAndDescriptions';
  static readonly MRQRGoodCause = 'MRQRGoodCause';
  // Works lookups
  static readonly WorkPlanType = 'WorkPlanType';
  //Initiate Screening Error Message
  static readonly screeningCRErrMessage = 'Application Registration is disabled as case is under change reporting';
  static readonly screeningRDErrMessage = 'Application Registration is disabled as case is under Redetermination';
  static readonly intakeCRErrMessage = 'Eligibility Determination is disabled as case is under change reporting';
  static readonly intakeRDErrMessage = 'Eligibility Determination is disabled as case is under Redetermination';
  static readonly screeningDeterminedYNErrMessage = 'Application Registration is disabled for this case';
  static readonly intakeDeterminedYNErrMessage = 'Eligibility Determination is disabled for this case';
  static readonly VerificationForms = 'VerificationForms';
  static readonly MissedInterviewReasonCode = 'MissedInterviewReasonCode';
  static readonly ReinstationReopeningReason = 'ReinstationReopeningReason';
  static readonly CaseStatusCode = 'CaseStatusCode';
  // sanction Dropdown
  static readonly ReversalReason = 'ReversalReason';
  static readonly SanctionLevel = 'SanctionLevel';
  static readonly SanctionStatus = 'SanctionStatus';
  static citizenshipFileUpload: boolean = false;
  // static caseIdHouseholds = {};
  static readonly datePlaceHolder = 'MM/DD/YYYY';
  //recovery dropdown
  static readonly ClaimStatus = 'ClmStatus';
  static readonly clmpymtplntyp = 'clmpymtplntyp';
  static readonly PaymentPlanSource = 'clmpymtplnsts';
  static readonly PaymentPlanStatus = 'PaymentPlanStatus'
  //static readonly recoverysource = 'RecoverySource';
  static readonly recoverytype = 'RecoveryType';
  static readonly SourceOfPayment = 'clmpymtsrc';
  static readonly ApproverStatus = 'clmsupervsrsts';
  static readonly pymnt_pln_sts_cd = 'pymnt_pln_sts_cd';
  static readonly PaymentPlanSrc = 'clmpymtplnsrc';
  static readonly topMatchCd = 'topmatchcode';
  static readonly clmadjstmntcode = 'clmadjstmntcode';
  // static caseId = 'C452154';
  static readonly clmRecoveryType = 'clmrcvrytype';
  static readonly clmRcvrySrc = 'clmrcvrysrc';
  static readonly clmAdjRsn = 'clmadjrsn';
  // issuance maintainance
  static readonly ReIssnReqTypCd = 'reisreqtypcd';
  static readonly ReIssnReasonCd = 'reisrsncd';
  static readonly IssnStatus = 'Issuancstatus';
  //discrepency dropdown
  static readonly discrepancySource = 'clmdscrpdscvrysrccd';
  static readonly clmtypecd = 'clmtypecd';
  static readonly errorType = 'clmerrtypecd';
  //interface
  static readonly sdxOptions = 'sdxopts';

  //pregnancy
  static readonly PregnancyVerification = 'CLPREGVERCD';



  //static case modes
  static readonly IN = 'Intake';
  static readonly RD = 'Redetermination';
  static readonly IC = 'Interim Change';
  // static householdMembers3: HouseholdMember[] = [];
  static humanServiceModel: HumanServices;
  static docInfo: any;
  static individualAssets: IndividualAssetClass[] = [];
  static humanServiceModelWithDOB: HumanServices;
  static CIDhumanServiceModel: HumanServices;
  static CIShumanServiceModel: HumanServices;
  static selectedProgram: any[] = [];
  static individualAssistance: any[] = [];
  static districtOffice: any;
  static caseId: any;
  static showSpousal: boolean = false;
  static recalculateButton: boolean = false;
  static waiverRes: boolean = false;
  static deniedPrgm: boolean = false;
  static existingRecords: boolean = false;
  static addInfoRes: boolean = false;
  static systemCd = 'W';
  static WorkItemType = 'WorkItemType';
  static childrenData: any = [{
    'individualId': 'ID001',
    'personName': {
      'firstName': 'Steve',
      'lastName': 'Smith'
    }
  },
  {
    'individualId': 'ID002',
    'personName': {
      'firstName': 'Adam',
      'lastName': 'Gilchrist'
    }
  }
  ]
  static interviewHealthDisabilityInsurance: any = {
    'disabilityType': '',
    'tdapType': '',
    'tdapCounter': '',
    'disabilityApprovalSource': '',
    'disabilityApprovalDate': '',
    'disabilityBeginDate': '',
    'disabilityEndDate': '',
    'loanDate': '',
    'interimAssistanceFormSigningDate': '',
    'swCoopYN': '',
    'healthInsurance': '',
    'hmo': '',
    'liabilityCD': '',
    'managedCareYN': '',
    'insuranceDroppedYN': '',
    'willingToPayYN': '',
    'buyingYN': '',
    'tplYN': '',
    'entitledToMedicarePartAYN': '',
    'purchaseForChildYN': '',
    'medicalReviewReceived': '',
    'treatmentParticipation': '',
    'medicalCheckupDate': '',
    'treatmentParticipationVerification': '',
    'medicalCheckupDateVerification': '',
  }
  static absentParentList: AbsentParent[] = [];
  static absentParent: AbsentParent = new AbsentParent();
  static apAddMode = true;
  static empAddMode = false;
  static courtOrderAddMode = true;
  static abContactInfo: any = {
    'contactInformation': ContactInformation,
    'previousContactInformation': Address,
    'phoneNumber': PhoneNumber
  }
  static courtOrderList = [];
  static specialNeeds = [];
  static setPreviouModuleFinalize: boolean = false;
  static eligibilityPrograms: Program[] = [];
  static editAuthRep: AuthorizedRepresentative[] = [];
  static selectedCourtOrder: any = {
    'docketNumber': '',
    'frequency': '',
    'supportObligation': '',
    'children': []
  };
  static CourtOrderPayment: any = {
    'payee': '',
    'lastPaid': '',
    'amount': '',
    'supportChild': ''
  };
  static previousEmployers: Employer[] = [];
  static currentEmployers: Employer[] = [];
  static selectedPreviousEmployee: Employer = new Employer();
  static selectedCurrentEmployee: Employer = new Employer();
  static selectedPreviousIndex = 0;
  static selectedCurrentIndex = 0;
  static selectedCourtIndex = -1;
  static selectedAbsentParent: AbsentParent = new AbsentParent();
  static selectedAbsentParentIndex = -1;
  static contactInfo: any = {
    'additionalInformation': AdditionalInformation,
    'homeAddress': Address,
    'mailingAddress': Address,
    'phoneNumber': PhoneNumber,
    'altPhoneNumber': PhoneNumber,
    'sameMailingAddressYN': 'Y',
    'email': '',
    'preferredModeOfCommunication': '',
    'preferredContactTime': ''
  };
  static programParticipation: string = '';
  static clientComponent: any = {
    'thirtyAndOneThirdYN': '',
    'thirtyYN': '',
    'dependentCareExpense': '',
    'earnedIncomes': '',
    'earnedIncomeExpenseType': '',
    'earnedIncomeExpense': ''
  }
  static signature: Signature = null;
  static signatureStatus: any = {
    'withdrawAppBeforeSigningYN': '',
    'withdrawAppAfterSigningYN': '',
    'lastNameAndRemarks': '',
    'email': '',
    // 'newSignature':'any',
    'email_assistance': ''
  };
  static scheduleInterviewStatus: any = {
    'worker': Worker,
    'inquiryDate': Date,// date
    'districtOffice': '',
    'emailAssistanceRequestFormYN': ''
  };
  static studentInfo: StudentInfo;
  static studentDetailsData: any = {
    'studentStatus': '',
    'studentStatusVerification': ClientVerification,
    'schools': SchoolInformation,
  };
  static schoolInformation: SchoolInformation[] = [];
  static schoolDetailsData: any = [{
    'educationLevel': '',
    'highestGrade': '',
    'schoolName': '',
    'schoolNumber': '',
    'graduationDate': '',
    'graduationDateVerification': ClientVerification,
    'goodStanding': '',
    'goodStandingVerification': ClientVerification,
    'schoolAddress': Address,
  }];
  static institutions: Institution;
  // }];
  static vendor: Vendor;
  static assets: Asset;
  static assetDetails: any = [{
    'recordId': '',
    'assetType': '',
    'assetDetailType': '',
    'amount': '',
    'accountNumber': '',
    'fdic': '',
    'institutionName': '',
    'faceAmount': '',
    'cashAmount': '',
    'policyNumber': '',
    'fareMarketValue': '',
    'encumberedAmount': '',
    'year': '',
    'make': '',
    'model': '',
    'licenseNumber': '',
    'vin': '',
    'registration': '',
    'state': '',
    'liceneseAgreementDate': '',
    'use': '',
    'tryingToSellYN': '',
    'address': '',
    'transferDate': '',
    'name': '',
    'reasonOfTransfer': '',
    'amountReceived': '',
    'transfereeRelationship': '',
    'undueHardshipReason': '',
    'discoveryDate': '',
    'effectiveBeginDate': '',
    'effectiveEndDate': '',
    'amountVerification': '',
    'insurancePolicyVerification': '',
    'fareMarketValueVerification': '',
    'encumberedAmountVerification': '',
    'amountReceivedVerification': '',
    'addressLine1': '',
    'addressLine2': '',
    'city': '',
    'zipcode': '',
    // to be changed when api is ready
    'institutionVerification': '',
    'ownedByHouseholdMember': '',
    'accountStartDate': '',
    'accountEndDate': '',
    'member': '',
    'deemorType': '',
    'otherAssetName': ''
  }];
  static programName: ProgramName = null;
  static programNameSelect: any = {
    'programName': ''
  }
  static additionalInformation: AdditionalInformation = null;
  static contactInformation: ContactInformation = null;
  static emergencyAssistanceIndex: number;
  static eaAddMode = true;
  static emergencyAssistance: EmergencyAssistance[] = [];
  // static headerTitle: string = 'Screening: Initiate Screening';
  static headerTitle: string = 'Legacy Case Search'; //to be renoved if legacy is removed
  static interviewHeaderTitle = 'Eligibility Determination: Initiate Eligibility Determination';
  static headerClientIdTitle: string = '';
  static headerClientIdTitle2: string = '';
  static headerCaseStatus: string = '';
  static _navName = {
    SCREENING_MODULE: [
      'initStatus', 'clientStatus', 'contactStatus', 'programSelection', 'caseDocuments',
      'screeningStatus'
    ],
    INTERVIEW_MODULE: [
      'caseHome', 'intStatus', 'intakeClientStatus', 'contactInfoStatus', 'authRep', 'programRequestSummary', 'houseStatus',
      'financialStatus', 'deemedStatus', 'eaStatus', 'ltcStatus', 'intake_spousal_resource',
      'intake_admin_info', 'intake_verification_checklist', 'intake_errors_summary', 'timeClock', 'nonCompliance'
    ],
    FINALIZE_MODULE: [
      'intStatus', 'benefitsSummary', 'medElig', 'eligibilityHistory','benefitHistory', 'EAElig', 'FSElig',
      'cashMAElig', 'finalize'
    ],
    BENEFIT_ISSUE_MODULE: [
      'benefitsIssuanceSummary', 'auxuliaryIssuanceSummary', 'issuanceMaintainenceV1', 'manuallyIssuance', 'issuanceQueue',
      'checkIssuanceRange', 'issRequest', 'issMethod', 'massIssuance'
    ],
    BENEFIT_RECOVERY_MODULE: [
      'claimSummary', 'paymentPlanDetials', 'recordPayment', 'recoverySummary', 'liableClaim', 'address', 'claimMaintenance',
      'claimWorkItem', 'claimManualCreation', 'claimWorkFlow', 'iarSummary', 'refundProcess', 'refundSummary'
    ],
    INTERFACE_MODULE: [
      'interface'
    ],
    SIMPLIFIED_REPORT_MODULE: [
      'simplifiedReporting'
    ],
    MANUAL_NOTICES_MODULE: [
      'manualNotices'
    ],
    TRIAL_BUDGET_MODULE: [
      'trialStatus'
    ],
    CASE_NARRATIVE_MODULE: [
      'caseNarrative'
    ],
    CHANGE_REPORTING_MODULE: [
      'caseHome', 'intStatus', 'intakeClientStatus', 'contactInfoStatus', 'authRep', 'programRequestSummary', 'houseStatus',
      'financialStatus', 'deemedStatus', 'eaStatus', 'ltcStatus', 'intake_spousal_resource',
      'intake_admin_info', 'intake_verification_checklist', 'intake_errors_summary', 'timeClock', 'nonCompliance'
    ],
    REDETERMINATION_MODULE: [
      'caseHome', 'intStatus', 'intakeClientStatus', 'contactInfoStatus', 'authRep', 'programRequestSummary', 'houseStatus',
      'financialStatus', 'deemedStatus', 'eaStatus', 'ltcStatus', 'intake_spousal_resource',
      'intake_admin_info', 'intake_verification_checklist', 'intake_errors_summary', 'timeClock', 'nonCompliance'
    ],
    CORRESPONDENCE_MODULE: [
      'correspondence'
    ],
    LEGACYCASESEARCH_MODULE: [
      'initiateLegacy'
    ],
    CASE_HOME_MODULE: [
      'caseHome', 'intStatus', 'intakeClientStatus', 'contactInfoStatus', 'authRep', 'programRequestSummary', 'houseStatus',
      'financialStatus', 'deemedStatus', 'eaStatus', 'ltcStatus', 'intake_spousal_resource',
      'intake_admin_info', 'intake_verification_checklist', 'intake_errors_summary', 'timeClock', 'nonCompliance'
    ],
    SETTINGS_MODULE: [
      'initiateVendor', 'holidays', 'eligibilityParameter', 'districtOffice', 'districtOfficeChange'
    ]
  };
  // static institutionDetails: any = [{
  //   'currentInstitution': Institution,
  //   'previousInstitutions': Institution,
  //   'maintenanceAmountYN': '',
  //   'goodCauseClaimYN': '',
  //   'returnHomeIn6MonthsYN': '',
  //   'return6MonthsVerification': '',
  //   'waiverType': '',
  //   'waiverDate': '',
  //   'guardianPersonYN': '',
  //   'guardianPersonFeeAmount': '',
  //   'guardianPropertyYN': '',
  //   'guardianPropertyFeeAmount': ''
  static readonly householdEmptyModel = {
    'householdMemberId': undefined,
    'individualId': '',
    'headOfHouseholdYN': '',
    'relationshipCd': '',
    'altNames': [],
    'additionalSsns': [],
    'pregnantYN': '',
    'individualDetails': {
      'pregnancy': {
        'pregnancyDueDate': null,
        //  'pregnancyStartDate': null
      }
    },
    'aliasClientIds': [],
    'memberClearanceId': undefined,
    'memberClearedYN': '',
    'memberEditableYN': '',
    'cases': [],
    // 'verification': {
    //   'ssnVerificationModel': {
    //     'verificationDocumentCd': '',
    //     'verificationRequestedYN': '',
    //     'docNames': new Map<number, string>(),
    //     'verificationType': '0',
    //     'verifiedYN': '',
    //     'dueDate': undefined,
    //     'automatedVerificationYN': '',
    //     // 'verificationFileList': [],
    //     'retryCount': undefined,
    //     'messages': []
    //   }, 'dobVerificationModel': {
    //     'verificationDocumentCd': '',
    //     // 'verificationFileList': [],
    //     'verificationRequestedYN': '',
    //     'docNames': new Map<number, string>(),
    //     'verificationType': '0',
    //     'verifiedYN': '',
    //     'dueDate': undefined,
    //     'automatedVerificationYN': '',
    //     'retryCount': undefined,
    //     'messages': []
    //   }
    // },
    'hasSsnYN': '',
    'ssnReferral': '',
    'personName': {
      'firstName': '',
      'lastName': '',
      'middleName': '',
      'type': '',
      'suffix': ''
    },
    'dob': undefined,
    'dobVerification': undefined,
    'soSec':
    {
      'soSecPart1': undefined,
      'soSecPart2': undefined,
      'soSecPart3': undefined,
      'ssnVerification': undefined
    },
    'gender': '',
    'race': {
      'primaryRace': '',
      'otherRaces': []
    },
    'deletedYN': '',
    'eligibility': {},
  };

  static clearanceSearchModel: HouseholdMember = JSON.parse(JSON.stringify(ConstantsService.householdEmptyModel));

  static readonly contactInformationEmptyModel = {
    'homeAddress': {
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'county': '',
      'district': '',
      'zipcode': '',
      'homeAddressYN': 'N'
    },
    'mailingAddress': {
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'county': '',
      'district': '',
      'homeAddressYN': 'N',
      'zipcode': ''
    },
    'sameMailingAddressYN': 'Y',
    'phoneNumber': {
      'phoneNumber': '',
      'phoneNumberType': '',
      'phoneNumberExt': ''
    },
    'altPhoneNumber': {
      'phoneNumber': '',
      'phoneNumberType': '',
      'phoneNumberExt': ''
    },
    'email': '',
    'preferredModeOfCommunication': '',
    'preferredContactTime': ''
  };

  static additionalInformationEmptyModel = {
    'preferredLanguage': 'E',
    'otherLanguage': null,
    'interpreterNeededYN': 'N',
    'visuallyImparedYN': 'N',
    'hearingImparedYN': 'N',
    'hearingImparedType': null
  };

  static trailBudgetData: any = {
    'livingArrangement': '',
    'householdMemberSize': '',
    'program': '',
    'maOrFoodHHType': '',
    'subType': '',
    'irsDependents': '',
    'clients': '',
    'totalEarnedIncome': '',
    'deemarEarnedIncome': '', // number
    'deemarEarnedIncomeExpense': '', // number
    'unearnedIncomes': '',
    'spouseUnearnedIncome': '', // number
    'deemarUnearnedIncome': '', // number
    'rentMortgageExpense': '', // number
    'utilityExpense': '', // number
    'medicalExpense': '', // number
    'tcDeductionExpense': '', // number
    'utilityMethodType': '',
    'assets': [],
    'vehicularAssets': [],
  };
  // beg recommended program
  static appDate: string;
  static foodStampYN: boolean;
  static disableRecommendedForAdditional: boolean = false;
  static disableRecommendedForSugg: boolean = false;
  /* static emergencyAssistance: any[] = [{
     'eaType': '',
     'eaApplicationDate': '',
     'eaNeeds': []
   }];*/
  static eligibility: Program[] = [
    new Program(
      1,
      'Medical Assistance',
      'Aged',
      'L98 ABD LTC MN',
      '',
      'l',
      '', // selectedYN
      'Y', // requestedYN,
      '',
      'Not Applicable'),
  ];
  static requestedPrograms: Program[] = [];
  public initiaLoadingDone: boolean = false;
  public eligParamsMenu: EligibilityParamsClass[] = [];
  public setEligParamsMenu: EligibilityParamsClass[] = [];
  public discrGetData: any[] = [];
  public issGetData: any[] = [];
  langSelected: any;
  public csBIDailySummaryDetailsModel: any;
  staticPage: string;
  showNoResults: boolean = true;
  userData: any;
  vendordata: any;
  wagheadData: any;
  // public maxDate = new Date();
  //claim summary
  public claimNumbers: any = [];
  public currentClaim: any;
  public claimData: any[] = [];
  public iarBreakDownList: any[] = [];
  public securityUserObj: any = {
    "navigationFlowRequested": false,
    "accessMap": {
      "/dashboard/interview/financialInformation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewCourtOrder": {
        "accessMode": "rw"
      },
      "/dashboard/interview/clearanceSearchComponent": {
        "accessMode": "rw"
      },
      "/dashboard/screening/customerRegistrationV1": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/vendorSearch": {
        "accessMode": "rw"
      },
      "/dashboard/screening/householdCircumstances": {
        "accessMode": "rw"
      },
      "/dashboard/redetermination/initiateRedetermination": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/cisResults": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/intiateInquiry": {
        "accessMode": "rw"
      },
      "/dashboard/interview/summary": {
        "accessMode": "rw"
      },
      "/dashboard/screening/authRepresentative": {
        "accessMode": "rw"
      },
      "/dashboard/screening/customerRelationship": {
        "accessMode": "rw"
      },
      "/dashboard/homeScreen": {
        "accessMode": "rw",
        "fieldMapping": {}
      },
      "/dashboard/interview/verification": {
        "accessMode": "rw"
      },
      "/dashboard/trialBudget/trialBudgetPublicAssistence": {
        "accessMode": "rw"
      },
      "/dashboard/screening/recommendedProgramsNew": {
        "accessMode": "rw"
      },
      "/dashboard/screening/customerRegisterationAddHouseholdMembersComponent": {
        "accessMode": "rw"
      },
      "/dashboard/screening/clearanceSearchComponent": {
        "accessMode": "rw"
      },
      "/dashboard/trialBudget/trialBudget": {
        "accessMode": "rw",
        "fieldMapping": {
          "finalize_id": "h",
          "inquiry_id": "h",
          "interview_id": "h",
          "screen_id": "h",
          "benefit_issue_id": "h"
        }
      },
      "/dashboard/interview/programStatus": {
        "accessMode": "rw"
      },
      "/dashboard/finalize/medicalEligibility": {
        "accessMode": "rw"
      },
      "/dashboard/finalize/eligibilityConfirmation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/medicalExpense": {
        "accessMode": "rw"
      },
      "/dashboard/interview/eaInformation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/addHealthAndDisability": {
        "accessMode": "rw"
      },
      "/dashboard/screening/recommendedPrograms": {
        "accessMode": "rw"
      },
      "/dashboard/interview/verificationChecklist": {
        "accessMode": "rw"
      },
      "/dashboard/interview/formerEmployer": {
        "accessMode": "rw"
      },
      "/dashboard/interview/individualDetails": {
        "accessMode": "rw"
      },
      "/dashboard/interview/addPriorCitizenship": {
        "accessMode": "rw"
      },
      "/dashboard/interview/authRepresentative": {
        "accessMode": "rw"
      },
      "/dashboard/trialBudget/trialBudgetFoodStamp": {
        "accessMode": "rw"
      },
      "/dashboard/interview/currentEmployer": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/customerSearch": {
        "accessMode": "rw"
      },
      "/dashboard/screening/screeningDisposition": {
        "accessMode": "rw"
      },
      "/dashboard/interview/customerRegistrationV1": {
        "accessMode": "rw"
      },
      "/dashboard/interview/addShelterExpenses": {
        "accessMode": "rw"
      },
      "/dashboard/myCases": {
        "accessMode": "rw"
      },
      "/dashboard/interview/adminInformation": {
        "accessMode": "rw"
      },
      "/dashboard/benefitIssue/benefitsIssuanceSummary": {
        "accessMode": "rw"
      },
      "/dashboard/benefitIssue/checkIssuanceRange": {
        "accessMode": "rw"
      },
      "/dashboard/interface/womisClientComponent": {
        "accessMode": "rw"
      },
      "/dashboard/interface/fedRepCase": {
        "accessMode": "rw"
      },
      "/dashboard/interface/manualEntry": {
        "accessMode": "rw"
      },
      "/dashboard/interface/quarterlyFile": {
        "accessMode": "rw"
      },
      "/dashboard/interface/systemParms": {
        "accessMode": "rw"
      },
      "/dashboard/interface/sdnhDetails": {
        "accessMode": "rw"
      },
      "/dashboard/interface/ndnhDetails": {
        "accessMode": "rw"
      },
      "/dashboard/finalize/benefitsSummary": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewAbsentParent": {
        "accessMode": "rw"
      },
      "/dashboard/interview/educationalDetails": {
        "accessMode": "rw"
      },
      "/dashboard/interview/felonyHome": {
        "accessMode": "rw"
      },
      "/dashboard/interview/roomerBoarder": {
        "accessMode": "rw"
      },
      "/dashboard/interview/pregnancyInformation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/incomeInformation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/taxInformation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/addUtilityExpense": {
        "accessMode": "rw"
      },
      "/dashboard/finalize/initiateFinalization": {
        "accessMode": "rw"
      },
      "/dashboard/interview/studentInformation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/errors": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewFinancialDependentCareExpenses": {
        "accessMode": "rw"
      },
      "/dashboard/interview/addInsurancePolicy": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/caseSearch": {
        "accessMode": "rw"
      },
      "/dashboard/interview/financialInformationUnEarnedIncome": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/cidResults": {
        "accessMode": "rw"
      },
      "/dashboard/finalize/alternateBenefitsSummary": {
        "accessMode": "rw"
      },
      "/dashboard/interview/emergencyAssistance": {
        "accessMode": "rw"
      },
      "/dashboard/caseNarrative/caseNarrative": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewContactInfo": {
        "accessMode": "rw"
      },
      "/dashboard/interview/financialInformationEarnedIncome": {
        "accessMode": "rw"
      },
      "/dashboard/interview/healthAndDisability": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/caseSummary": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewAddassetComponent": {
        "accessMode": "rw"
      },
      "/dashboard/interview/customerRegisterationAddHouseholdMembersComponent": {
        "accessMode": "rw"
      },
      "/dashboard/interview/citizenshipDetails": {
        "accessMode": "rw"
      },
      "/dashboard/trialBudget/trialBudgetMedical": {
        "accessMode": "rw"
      },
      "/dashboard/screening/contactInformationV1": {
        "accessMode": "rw"
      },
      "/dashboard/interview/initiateInterview": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewAbsentParentEmploymentHistory": {
        "accessMode": "rw"
      },
      "/dashboard/inquiry/customerDetails": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewInstitute": {
        "accessMode": "rw"
      },
      "/dashboard/inbox": {
        "accessMode": "rw"
      },
      "/dashboard/changeReporting/initiateChangeReporting": {
        "accessMode": "rw"
      },
      "/dashboard/interview/contactInformation": {
        "accessMode": "rw"
      },
      "/dashboard/screening/initiateScreening": {
        "accessMode": "rw"
      },
      "/dashboard/caseNarrative/initiateNarrative": {
        "accessMode": "rw"
      },
      "/dashboard/interview/courtOrderDetails": {
        "accessMode": "rw"
      },
      "/dashboard/interview/householdInformation": {
        "accessMode": "rw"
      },
      "/dashboard/interview/interviewFinancialAppliedUnearnedIncome": {
        "accessMode": "rw"
      },
      "/dashboard/consumer-app/intake/demographics": {
        "accessMode": "rw"
      },
      "/dashboard/interview/memberDetails": {
        "accessMode": "rw"
      }
      ,
      "/dashboard/interview/socialsecuritysenefit": {
        "accessMode": "rw"
      }
    },
    "user": {
      "userId": "",
      "name": "",
      "roles": [
        "EE_SUPER",
        "EE_SCREE",
        "EE_FINAL",
        "EE_INTER",
        "Internal Users",
        "EE_SUPVRS"
      ],
      "county": "Harford County",
      "districtOffice": "033"
    }
  }
  public auxiliaryData = [];
  public applicationDate: any;
  public filteredProgramsList = [];
  loginModel = {
    'email': 'screener1', // 'user' need to be removed after proper functioning of logout service
    'pwd': ''
  }
  // Header Titles
  headTitle = {
    'LINKCP_MODULE': {
      'initStatus': 'Link/Delink CP Users'
    },

    'SCREENING_MODULE': {
      'initStatus': 'Initiate',
      'programSelection': 'Register',
      'clientStatus': 'Customer Registration',
      'customerDetails': 'Customer Details',
      'initateNotice': 'Customer Relationships',
      'contactStatus': 'Contact Information',
      'authRep': 'Authorized Representative',
      'caseDocuments': 'Case Document',
      // 'houseHoldStatus': 'Household Circumstances',
      // 'recommendedStatus': 'Recommended Programs',
      'screeningStatus': 'App Disposition',
    },
    // 'CASE_HOME_MODULE': {
    //   'caseHome': 'Case Home',
    // },
    // Interview
    'INTERVIEW_MODULE': {
      'caseHome': 'Case Home',
      'intStatus': 'Initiate Eligibility Determination',
      // 'progStatus': 'Program Status',
      'intakeClientStatus': 'Customer Registration',
      'contactInfoStatus': 'Contact Information',
      'authRep': 'Authorized Representative',
      'programRequestSummary': 'Program Request',
      'houseStatus': 'Household Information',
      'taxInfoStatus': 'Tax Information',
      'financialStatus': 'Financial Information',
      // 'deemedStatus': 'Deemed Financial Info',
      'eaStatus': 'EA Information',
      'intake_spousal_resource': 'Spousal Resource',
      'intake_admin_info': 'Administrative Information',
      'timeClock': 'Time Clock',
      'nonCompliance': 'Non Compliance',
      // 'interview_verification_info': 'Verification',
      'intake_errors_summary': 'Summary',
      'individual_details': 'Individual Details',
      'citizenship': 'Citizenship/Immigration',
      'studentInfo': 'Student Information',
      'healthAndDisability': 'Health/Disability',
      'medicare': 'Medicare',
      'institution': 'Institution Information',
      'assets': 'Assets',
      'earnedIncome': 'Earned Income',
      'unearnedIncome': 'Unearned Income',
      'dependentCareExpenses': 'Dependent Care Expenses',
      'shelterExpenses': 'Shelter Expenses',
      'utilityExpenses': 'Utility Expenses',
      'medicalExpense': 'Medical Expense',
      'demographics': 'Demographics',
      'AbsentParent': 'Non-Custodial Parent',
      'absentParentContactInfo': 'Contact Information',
      'absentParentEmploymentHistory': 'Employment History',
      'addInsurancePolicy': 'Add Insurance Policy',
      'courtOrder': 'Court Order',
      'educationalDetails': 'Educational Details',
      'felonyHome': 'Felony Home',
      'roomerBoarder': 'Roomer Boarder',
      'socialsecuritysenefit': 'Social Security Benefit',
      'pregnancyInformation': 'Pregnancy Information',
      'errors': 'Errors',
      'verification': 'Verification',
      'unearnedIncomeApplications': 'Unearned Income Applications',
      'spousalResource': 'Spousal Resource',
      'work': 'Work',
      'workProgram': 'Work Program',
      'welfareAvoidanceGrant': 'Welfare Avoidance Grant'
    },
    // Finalize
    'FINALIZE_MODULE': {
      'intStatus': 'Initiate Finalization',
      'benefitsSummary': 'Determine Eligibility',
      'history': 'Eligibility History',
      'assistance': 'Individual Assistance',
      'sortID11issuanceDateDt': "Summary"
    },
    'BENEFIT_ISSUE_MODULE': {
      'benefitsIssuanceSummary': ' Issuance Summary',
      'auxuliaryIssuanceSummary': 'Auxiliary Summary',
      'issuanceMaintainenceV1': 'Issuance Maintenance',
      'manuallyIssuance': 'Record Manual Issuance',
      'issuanceQueue': 'EA Check Issuance',
      'checkIssuanceRange': 'Check Issuance Range',
      'issRequest': 'ISRQ',
      'issMethod': 'Issuance Method',
      'massIssuance': 'Mass Issuance'
    },
    'BENEFIT_RECOVERY_MODULE': {
      'claimSummary': 'Claim Summary',
      'paymentPlanDetials': 'Payment Plan Details',
      'recordPayment': 'Record Payment',
      'recoverySummary': 'Recovery Summary',
      'liableClaim': 'Liable Parties',
      'auxuliaryIssuanceSummary': 'Auxiliary',
      'address': 'IRA Address Verfication',
      'claimMaintenance': 'Claim Maintenance',
      'claimWorkItem': 'Claim Review',
      'claimManualCreation': 'Claim Manual Creation',
      'claimWorkFlow': 'Claim Work Flow ',
      'iarSummary': 'IAR Summary',
      'refundProcess': 'Refund Claim',
      'refundSummary': 'Refund Summary'
    },
    // Trial Budget
    'TRIAL_BUDGET_MODULE': {
      'trialStatus': '',
      'trialBudget': 'Trial Budget'
    },
    // Case Narrative
    'CASE_NARRATIVE_MODULE': {
      'initStatus': 'Initiate Case Comments',
      'caseNarrative': 'Case Comments'
    },
    // Inquiry
    'INQUIRY_MODULE': {
      'initStatus': ' ',
      'customerSearch': 'Consumer Search',
      'customerDetails': 'Consumer Details',
      'caseSearch': 'Case Search',
      'vendorSearch': 'Vendor Search'
    },
    // Change Reporting
    'CHANGE_REPORTING_MODULE': {
      'caseHome': 'Case Home',
      'intStatus': 'Initiate Eligibility Determination',
      // 'progStatus': 'Program Status',
      'intakeClientStatus': 'Customer Registration',
      'contactInfoStatus': 'Contact Information',
      'authRep': 'Authorized Representative',
      'programRequestSummary': 'Program Request',
      'houseStatus': 'Household Information',
      'taxInfoStatus': 'Tax Information',
      'financialStatus': 'Financial Information',
      // 'deemedStatus': 'Deemed Financial Info',
      'eaStatus': 'EA Information',
      'intake_spousal_resource': 'Spousal Resource',
      'intake_admin_info': 'Administrative Information',
      'timeClock': 'Time Clock',
      'nonCompliance': 'Non Compliance',
      // 'interview_verification_info': 'Verification',
      'intake_errors_summary': 'Summary',
      'individual_details': 'Individual Details',
      'citizenship': 'Citizenship/Immigration',
      'studentInfo': 'Student Information',
      'healthAndDisability': 'Health/Disability',
      'medicare': 'Medicare',
      'institution': 'Institution Information',
      'assets': 'Assets',
      'earnedIncome': 'Earned Income',
      'unearnedIncome': 'Unearned Income',
      'dependentCareExpenses': 'Dependent Care Expenses',
      'shelterExpenses': 'Shelter Expenses',
      'utilityExpenses': 'Utility Expenses',
      'medicalExpense': 'Medical Expense',
      'demographics': 'Demographics',
      'AbsentParent': 'Non-Custodial Parent',
      'absentParentContactInfo': 'Contact Information',
      'absentParentEmploymentHistory': 'Employment History',
      'addInsurancePolicy': 'Add Insurance Policy',
      'courtOrder': 'Court Order',
      'educationalDetails': 'Educational Details',
      'felonyHome': 'Felony Home',
      'roomerBoarder': 'Roomer Boarder',
      'socialsecuritysenefit': 'Social Security Benefit',
      'work': 'Work',
      'workProgram': 'Work Program',
      'pregnancyInformation': 'Pregnancy Information',
      'errors': 'Errors',
      'verification': 'Verification',
      'unearnedIncomeApplications': 'Unearned Income Applications',
      'intake_verification_checklist': 'Verification Status',
      'interview_errors_summary': 'Summary',
      'benefitsSummary': 'Determine Eligibility',
      'history': 'Eligibility History',
      'assistance': 'Individual Assistance',
      'bnftIssncsummary': 'Benefit Issuance Summary',
      'simplifiedReport': 'Benefit Review Form',
      'manualNotices': 'Manual Notices',
      'sortID11issuanceDateDt': 'Summary'
    },
    // Change Reporting
    'REDETERMINATION_MODULE': {
      'caseHome': 'Case Home',
      'intStatus': 'Initiate Eligibility Determination',
      // 'progStatus': 'Program Status',
      'intakeClientStatus': 'Customer Registration',
      'contactInfoStatus': 'Contact Information',
      'authRep': 'Authorized Representative',
      'programRequestSummary': 'Program Request',
      'houseStatus': 'Household Information',
      'taxInfoStatus': 'Tax Information',
      'financialStatus': 'Financial Information',
      // 'deemedStatus': 'Deemed Financial Info',
      'eaStatus': 'EA Information',
      'intake_spousal_resource': 'Spousal Resource',
      'intake_admin_info': 'Administrative Information',
      'timeClock': 'Time Clock',
      'nonCompliance': 'Non Compliance',
      // 'interview_verification_info': 'Verification',
      'intake_errors_summary': 'Summary',
      'individual_details': 'Individual Details',
      'citizenship': 'Citizenship/Immigration',
      'studentInfo': 'Student Information',
      'healthAndDisability': 'Health/Disability',
      'medicare': 'Medicare',
      'institution': 'Institution Information',
      'assets': 'Assets',
      'earnedIncome': 'Earned Income',
      'unearnedIncome': 'Unearned Income',
      'dependentCareExpenses': 'Dependent Care Expenses',
      'shelterExpenses': 'Shelter Expenses',
      'utilityExpenses': 'Utility Expenses',
      'medicalExpense': 'Medical Expense',
      'demographics': 'Demographics',
      'AbsentParent': 'Non-Custodial Parent',
      'absentParentContactInfo': 'Contact Information',
      'absentParentEmploymentHistory': 'Employment History',
      'addInsurancePolicy': 'Add Insurance Policy',
      'courtOrder': 'Court Order',
      'educationalDetails': 'Educational Details',
      'felonyHome': 'Felony Home',
      'roomerBoarder': 'Roomer Boarder',
      'socialsecuritysenefit': 'Social Security Benefit',
      'pregnancyInformation': 'Pregnancy Information',
      'errors': 'Errors',
      'verification': 'Verification',
      'unearnedIncomeApplications': 'Unearned Income Applications',
      'spousalResource': 'Spousal Resource',
      'work': 'Work',
      'workProgram': 'Work Program',
      'welfareAvoidanceGrant': 'Welfare Avoidance Grant'
    },
    'CORRESPONDENCE_MODULE': {
      // 'initStatus': ' ',
      // 'customerSearch': 'Customer Search',
      // 'customerDetails': 'Customer Details',
      'correspondence': 'Correspondence Search',
      // 'vendorSearch': 'Vendor Search'
    },
    'SIMPLIFIED_REPORT_MODULE': {
      // 'initStatus': ' ',
      // 'customerSearch': 'Customer Search',
      // 'customerDetails': 'Customer Details',
      'simplifiedReporting': 'Benefit Review Form',
      // 'vendorSearch': 'Vendor Search'
    },
    'MANUAL_NOTICES_MODULE': {
      'manualNotices': 'Manual Notices',
    },
    'LEGACYCASESEARCH_MODULE': {
      'initiateLegacy': 'Initiate Legacy'
    },
    'CASE_HOME_MODULE': {
      'caseHome': 'Case Home',
      'intStatus': 'Initiate Eligibility Determination',
      // 'progStatus': 'Program Status',
      'intakeClientStatus': 'Customer Registration',
      'contactInfoStatus': 'Contact Information',
      'authRep': 'Authorized Representative',
      'programRequestSummary': 'Program Request',
      'houseStatus': 'Household Information',
      'taxInfoStatus': 'Tax Information',
      'financialStatus': 'Financial Information',
      // 'deemedStatus': 'Deemed Financial Info',
      'eaStatus': 'EA Information',
      'intake_spousal_resource': 'Spousal Resource',
      'intake_admin_info': 'Administrative Information',
      'timeClock': 'Time Clock',
      'nonCompliance': 'Non Compliance',
      // 'interview_verification_info': 'Verification',
      'intake_errors_summary': 'Summary',
      'individual_details': 'Individual Details',
      'citizenship': 'Citizenship/Immigration',
      'studentInfo': 'Student Information',
      'healthAndDisability': 'Health/Disability',
      'medicare': 'Medicare',
      'institution': 'Institution Information',
      'assets': 'Assets',
      'earnedIncome': 'Earned Income',
      'unearnedIncome': 'Unearned Income',
      'dependentCareExpenses': 'Dependent Care Expenses',
      'shelterExpenses': 'Shelter Expenses',
      'utilityExpenses': 'Utility Expenses',
      'medicalExpense': 'Medical Expense',
      'demographics': 'Demographics',
      'AbsentParent': 'Non-Custodial Parent',
      'absentParentContactInfo': 'Contact Information',
      'absentParentEmploymentHistory': 'Employment History',
      'addInsurancePolicy': 'Add Insurance Policy',
      'courtOrder': 'Court Order',
      'educationalDetails': 'Educational Details',
      'felonyHome': 'Felony Home',
      'roomerBoarder': 'Roomer Boarder',
      'socialsecuritysenefit': 'Social Security Benefit',
      'work': 'Work',
      'workProgram': 'Work Program',
      'pregnancyInformation': 'Pregnancy Information',
      'errors': 'Errors',
      'verification': 'Verification',
      'unearnedIncomeApplications': 'Unearned Income Applications',
      'intake_verification_checklist': 'Verification Status',
      'interview_errors_summary': 'Summary',
      'benefitsSummary': 'Determine Eligibility',
      'history': 'Eligibility History',
      'assistance': 'Individual Assistance',
      'bnftIssncsummary': 'Benefit Issuance Summary',
      'simplifiedReport': 'Benefit Review Form',
      'manualNotices': 'Manual Notices',
      'sortID11issuanceDateDt': 'Summary'
    },
    'INTERFACE_MODULE': {
      'interface': 'Interface Search'
    },
    // SETTINGS MODULE
    'SETTINGS_MODULE': {
      'initiateVendor': 'Vendor Details',
      'holidays': 'Holiday List',
      'eligibilityParameter': 'Eligibility Parameters',
      'districtOffice': 'District Office',
      'districtOfficeChange': 'Change District Office'
    }
  }
  // Left Navigation elements
  navigation = {
    'trialBudget': [],
    'screening': [
      {
        'id': 'initStatus',
        'navTitle': 'Initiate',
        'headerTitle': 'Initiate',
        'routerPath': '/dashboard/screening/initiateScreening',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'programSelection',
        'navTitle': 'Register',
        'headerTitle': 'Register',
        'routerPath': '/dashboard/screening/programSelection',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'clientStatus',
        'navTitle': 'Customer Registration',
        'headerTitle': 'Customer Registration',
        'routerPath': '/dashboard/screening/customerRegisterationAddHouseholdMembersComponent',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'contactStatus',
        'navTitle': 'Contact Information',
        'headerTitle': 'Contact Information',
        'routerPath': '/dashboard/screening/contactInformationV1',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'authRep',
        'navTitle': 'Authorized Representative',
        'headerTitle': 'Authorized Representative',
        'routerPath': '/dashboard/screening/authRepresentative',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'caseDocuments',
        'navTitle': 'Case Documents',
        'headerTitle': 'Case Documents',
        'routerPath': '/dashboard/screening/caseDocuments',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': [{
          'navTitle': 'Scan Documents',
          'scrollTo': 'scanDocuments',
          'routerPath': '/dashboard/screening/fileScan',
          'clickable': true,
          'visible': true
        },]
      },
      // {
      //   'id': 'houseHoldStatus',
      //   'navTitle': 'Household Circumstances',
      //   'headerTitle': 'Household Circumstances',
      //   'routerPath': '/dashboard/screening/householdCircumstances',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': '',
      //   'children': []
      // },
      // {
      //   'id': 'recommendedStatus',
      //   'navTitle': 'Recommended Programs',
      //   'headerTitle': 'Recommended Programs',
      //   'routerPath': '/dashboard/screening/recommendedPrograms',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': '',
      //   'children': []
      // },
      {
        'id': 'screeningStatus',
        'navTitle': 'App Disposition',
        'headerTitle': 'App Disposition',
        'routerPath': '/dashboard/screening/screeningDisposition',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ],
    'interview': [
      {
        'id': 'intStatus',
        'navTitle': 'Initiate Eligibility Determination',
        'headerTitle': 'Initiate Eligibility Determination',
        'routerPath': '/dashboard/interview/initiateInterview',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      // {
      //   'id': 'progStatus',
      //   'navTitle': 'Program Status',
      //   'headerTitle': 'Program Status',
      //   'routerPath': '/dashboard/interview/programStatus',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': '',
      //   'children': []
      // },
      {
        'id': 'intakeClientStatus',
        'navTitle': 'Customer Registration',
        'headerTitle': 'Customer Registration',
        'routerPath': '/dashboard/interview/customerRegisterationAddHouseholdMembersComponent',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'contactInfoStatus',
        'navTitle': 'Contact Information',
        'headerTitle': 'Contact Information',
        'routerPath': '/dashboard/interview/contactInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'authRep',
        'navTitle': 'Authorized Representative',
        'headerTitle': 'Authorized Representative',
        'routerPath': '/dashboard/interview/authRepresentative',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'programRequestSummary',
        'navTitle': 'Program Request',
        'headerTitle': 'Program Request',
        'routerPath': '/dashboard/interview/programRequestSummary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'houseStatus',
        'navTitle': 'Household Information',
        'headerTitle': 'Household Information',
        'routerPath': '/dashboard/interview/householdInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'householdInfo',
        'children': [
          {
            'navTitle': 'Household Members',
            'scrollTo': 'householdMember',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Non-Custodial Parents',
            'scrollTo': 'absentParent',
            'clickable': true,
            'visible': true
          }
        ]
      },
      {
        'id': 'taxInfoStatus',
        'navTitle': 'Tax Information',
        'headerTitle': 'Tax Information',
        'routerPath': '/dashboard/interview/taxInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'financialStatus',
        'navTitle': 'Financial Information',
        'headerTitle': 'Financial Information',
        'routerPath': '/dashboard/interview/financialInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'financialInfo',
        'children': [
          {
            'navTitle': 'Assets',
            'scrollTo': 'addAsset',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Incomes',
            'scrollTo': 'addEarnedIncome',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Expenses',
            'scrollTo': 'individualDetails',
            'clickable': true,
            'visible': true
          },
          // {
          //   'navTitle': 'Unearned Income Applications',
          //   'scrollTo': 'addAppliedUnearnedIncome',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Dependent Care Expenses',
          //   'scrollTo': 'addDependentCareExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Shelter Expenses',
          //   'scrollTo': 'addShelterExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Utility Expense',
          //   'scrollTo': 'addUtilityExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Child Support Expense',
          //   'scrollTo': 'addSupportExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Medical Expenses',
          //   'scrollTo': 'addMedicalExpense',
          //   'clickable': true,
          //   'visible': true
          // }
        ]
      },
      // {
      //   'id': 'deemedStatus',
      //   'navTitle': 'Deemed Financial Info',
      //   'headerTitle': 'Deemed Financial Info',
      //   'routerPath': '/dashboard/interview/deemedFinancialInformation',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': 'deemedFinancialInfo',
      //   'children': [
      //     {
      //       'navTitle': 'Assets',
      //       'scrollTo': 'addAsset',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Earned Income',
      //       'scrollTo': 'addEarnedIncome',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Unearned Income',
      //       'scrollTo': 'addUnearnedIncome',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Dependent Care Expenses',
      //       'scrollTo': 'addDependentCareExpense',
      //       'clickable': true,
      //       'visible': true
      //     }
      //   ]
      // },
      {
        'id': 'eaStatus',
        'navTitle': 'EA Information',
        'headerTitle': 'EA Information',
        'routerPath': '/dashboard/interview/eaInformation',
        'clickable': true,
        'visible': false,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_spousal_resource',
        'navTitle': 'Spousal Resource',
        'headerTitle': 'Spousal Resource',
        'routerPath': '/dashboard/interview/spousalResource',
        'clickable': true,
        'visible': false,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_admin_info',
        'navTitle': 'Administrative Information',
        'headerTitle': 'Administrative Information',
        'routerPath': '/dashboard/interview/adminInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'timeClock',
        'navTitle': 'Time Clock',
        'headerTitle': 'Time Clock',
        'routerPath': '/dashboard/interview/timeClock',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'nonCompliance',
        'navTitle': 'Non Compliance',
        'headerTitle': 'Non Compliance',
        'routerPath': '/dashboard/interview/nonCompliance',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_verification_checklist',
        'navTitle': 'Verification Status',
        'headerTitle': 'Verification Status',
        'routerPath': '/dashboard/interview/verificationChecklist',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_errors_summary',
        'navTitle': 'Summary',
        'headerTitle': 'Summary',
        'routerPath': '/dashboard/interview/summary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
    ],
    'caseInquiry': [
      {
        'id': 'caseHome',
        'navTitle': 'Case Home',
        'headerTitle': 'Case Home',
        'routerPath': '/dashboard/caseInquiry/caseHome',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },

      {
        'id': 'reinstantReopen',
        'navTitle': 'Reinstantiation/Reopen',
        'headerTitle': 'Reinstantiation/Reopen',
        'routerPath': '/dashboard/caseInquiry/reinstantReopen',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },

      // {
      //   'id': 'progStatus',
      //   'navTitle': 'Program Status',
      //   'headerTitle': 'Program Status',
      //   'routerPath': '/dashboard/interview/programStatus',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': '',
      //   'children': []
      // },
      // {
      //   'id': 'intakeClientStatus',
      //   'navTitle': 'Customer Registration',
      //   'headerTitle': 'Customer Registration',
      //   'routerPath': '/dashboard/interview/customerRegisterationAddHouseholdMembersComponent',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': '',
      //   'children': []
      // },
      {
        'id': 'intakeClientStatus',
        'navTitle': 'Member Details',
        'headerTitle': 'Member Details',
        'routerPath': '/dashboard/interview/memberDetails',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'contactInfoStatus',
        'navTitle': 'Contact Information',
        'headerTitle': 'Contact Information',
        'routerPath': '/dashboard/interview/contactInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'authRep',
        'navTitle': 'Authorized Representative',
        'headerTitle': 'Authorized Representative',
        'routerPath': '/dashboard/interview/authRepresentative',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'reinstantReopen',
        'navTitle': 'Reinstantiation/Reopen',
        'headerTitle': 'Reinstantiation/Reopen',
        'routerPath': '/dashboard/interview/reinstantReopen',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },

      {
        'id': 'caseDocuments',
        'navTitle': 'Case Documents',
        'headerTitle': 'Case Documents',
        'routerPath': '/dashboard/interview/caseDocuments',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': [
          {
            'navTitle': 'Scan Documents',
            'scrollTo': 'scanDocuments',
            // 'routerPath': '/dashboard/interview/fileScan',
            'clickable': true,
            'visible': true
          },
        ]
      },
      {
        'id': 'programRequestSummary',
        'navTitle': 'Program Request',
        'headerTitle': 'Program Request',
        'routerPath': '/dashboard/interview/programRequestSummary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'houseStatus',
        'navTitle': 'Household Information',
        'headerTitle': 'Household Information',
        'routerPath': '/dashboard/interview/householdInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'householdInfo',
        'children': [
          {
            'navTitle': 'Individual Details',
            'scrollTo': 'individualDetails',
            'routerPath': '/dashboard/interview/individualDetails',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Citizenship Details',
            'scrollTo': 'citizenshipDetails',
            'routerPath': '/dashboard/interview/citizenshipDetails',
            'clickable': true,
            'visible': true
          },

          {
            'navTitle': 'Educational Details',
            'scrollTo': 'studentInformation',
            'routerPath': '/dashboard/interview/studentInformation',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Health/Disability',
            'scrollTo': 'healthAndDisability',
            'routerPath': '/dashboard/interview/healthAndDisability',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Medicare',
            'scrollTo': 'medicare',
            'routerPath': '/dashboard/interview/medicare',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Felony Home',
            'scrollTo': 'felonyHome',
            'routerPath': '/dashboard/interview/felonyHome',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Roomer Boarder',
            'scrollTo': 'roomerBoarder',
            'routerPath': '/dashboard/interview/roomerBoarder',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Social Security Benefit',
            'scrollTo': 'socialsecuritysenefit',
            'routerPath': '/dashboard/interview/socialsecuritysenefit',
            'clickable': true,
            'visible': true
          },
          ,
          {
            'navTitle': 'Work',
            'scrollTo': 'work',
            'routerPath': '/dashboard/interview/work',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Pregnancy Information',
            'scrollTo': 'pregnancyInformation',
            'routerPath': '/dashboard/interview/pregnancyInformation',
            'clickable': true,
            'visible': false,
            'status': false
          },
          {
            'navTitle': 'Institution Details',
            'scrollTo': 'interviewInstitute',
            'routerPath': '/dashboard/interview/interviewInstitute',
            'clickable': true,
            'visible': true
          }
        ]
      },
      {
        'id': 'taxInfoStatus',
        'navTitle': 'Tax Information',
        'headerTitle': 'Tax Information',
        'routerPath': '/dashboard/interview/taxInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'financialStatus',
        'navTitle': 'Financial Information',
        'headerTitle': 'Financial Information',
        'routerPath': '/dashboard/interview/interviewAddassetComponent',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'financialInfo',
        'children': [
          {
            'navTitle': 'Assets',
            'scrollTo': 'addAsset',
            'routerPath': '/dashboard/interview/interviewAddassetComponent',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Incomes',
            'scrollTo': 'addEarnedIncome',
            'routerPath': '/dashboard/interview/incomeInformation',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Expenses',
            'scrollTo': 'individualDetails',
            'routerPath': '/dashboard/interview/financialExpenses',
            'clickable': true,
            'visible': true
          },
          // {
          //   'navTitle': 'Unearned Income Applications',
          //   'scrollTo': 'addAppliedUnearnedIncome',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Dependent Care Expenses',
          //   'scrollTo': 'addDependentCareExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Shelter Expenses',
          //   'scrollTo': 'addShelterExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Utility Expense',
          //   'scrollTo': 'addUtilityExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Child Support Expenses',
          //   'scrollTo': 'addSupportExpense',
          //   'clickable': true,
          //   'visible': true
          // },
          // {
          //   'navTitle': 'Medical Expenses',
          //   'scrollTo': 'addMedicalExpense',
          //   'clickable': true,
          //   'visible': true
          // }
        ]
      },
      // {
      //   'id': 'deemedStatus',
      //   'navTitle': 'Deemed Financial Info',
      //   'headerTitle': 'Deemed Financial Info',
      //   'routerPath': '/dashboard/interview/deemedFinancialInformation',
      //   'clickable': true,
      //   'visible': true,
      //   'status': false,
      //   'childrenId': 'deemedFinancialInfo',
      //   'children': [
      //     {
      //       'navTitle': 'Assets',
      //       'scrollTo': 'addAsset',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Earned Income',
      //       'scrollTo': 'addEarnedIncome',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Unearned Income',
      //       'scrollTo': 'addUnearnedIncome',
      //       'clickable': true,
      //       'visible': true
      //     },
      //     {
      //       'navTitle': 'Dependent Care Expenses',
      //       'scrollTo': 'addDependentCareExpense',
      //       'clickable': true,
      //       'visible': true
      //     }
      //   ]
      // },
      {
        'id': 'eaStatus',
        'navTitle': 'EA Information',
        'headerTitle': 'EA Information',
        'routerPath': '/dashboard/interview/eaInformation',
        'clickable': true,
        'visible': false,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_spousal_resource',
        'navTitle': 'Spousal Resource',
        'headerTitle': 'Spousal Resource',
        'routerPath': '/dashboard/interview/spousalResource',
        'clickable': true,
        'visible': false,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_admin_info',
        'navTitle': 'Administrative Information',
        'headerTitle': 'Administrative Information',
        'routerPath': '/dashboard/interview/adminInformation',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'timeClock',
        'navTitle': 'Time Clock',
        'headerTitle': 'Time Clock',
        'routerPath': '/dashboard/interview/timeClock',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_verification_checklist',
        'navTitle': 'Verification Status',
        'headerTitle': 'Verification Status',
        'routerPath': '/dashboard/interview/verificationChecklist',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'intake_errors_summary',
        'navTitle': 'Summary',
        'headerTitle': 'Summary',
        'routerPath': '/dashboard/interview/summary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'history',
        'navTitle': 'Eligibility History',
        'headerTitle': 'Eligibility History',
        'routerPath': '/dashboard/finalize/eligibilityHistory',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'assistance',
        'navTitle': 'Individual Assistance',
        'headerTitle': 'Individual Assistance',
        'routerPath': '/dashboard/finalize/individualAssistance',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'benefitsSummary',
        'navTitle': 'Determine Eligibility',
        'headerTitle': 'Determine Eligibility',
        'routerPath': '/dashboard/finalize/benefitsSummary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'medElig',
        'navTitle': 'Eligibility Details',
        'headerTitle': 'Eligibility Details',
        'routerPath': '/dashboard/finalize/benefitsSummary',
        'clickable': false,
        'visible': true,
        'status': false,
        'childrenId': 'medEligMenu',
        'children': []
      },
      {
        'id': 'bnftIssncsummary',
        'navTitle': 'Benefit Issuance',
        'headerTitle': 'Benefit Issuance Summary',
        'routerPath': '/dashboard/benefitIssue/benefitsIssuanceSummary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'bnftIssncsummaryMenu',
        'children': [

          {
            'navTitle': 'Issuance Summary',
            'scrollTo': 'sortID11issuanceDateDt',
            // 'routerPath': '/dashboard/benefitIssue/benefitsIssuanceSummary',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Issuance Details',
            'scrollTo': 'details',
            // 'routerPath': '/dashboard/interview/fileScan',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Re-Issue',
            // 'routerPath': '/dashboard/benefitIssue/benefitsReissuace',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Issuance Maintainence',
            'routerPath': '/dashboard/benefitIssue/issuanceMaintainence',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Auxiliary Issuance Summary',
            'routerPath': '/dashboard/benefitIssue/auxuliaryIssuanceSummary',
            'clickable': false,
            'visible': true
          },
          {
            'navTitle': 'Auxiliary Issuance Details',
            'routerPath': '/dashboard/benefitIssue/auxuliaryIssuanceDetails',
            'clickable': false,
            'visible': true
          }
        ]
      }

    ],
    'benefitRecovery': [{
      'id': 'benefitrecovery',
      'navTitle': 'Benefit Recovery',
      'headerTitle': 'Benefit Recovery',
      'routerPath': '/dashboard/benefitRecovery/claimSummary',
      'clickable': true,
      'visible': true,
      'status': false,
      'childrenId': '',
      'children': [
        {
          'navTitle': 'Claim Summary',
          'routerPath': '/dashboard/benefitRecovery/claimSummary',
          'clickable': false,
          'visible': true
        },
        {
          'navTitle': 'Payment Plan Details',
          'routerPath': '/dashboard/benefitRecovery/paymentPlanDetials',
          'clickable': false,
          'visible': true
        },
        {
          'navTitle': 'Record Payment',
          'routerPath': '/dashboard/benefitRecovery/recordPayment',
          'clickable': false,
          'visible': true
        }
      ]
    }],
    'finalize': [
      {
        'id': 'intStatus',
        'navTitle': 'Initiate Finalization',
        'headerTitle': 'Initiate Finalization',
        'routerPath': '/dashboard/finalize/initiateFinalization',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'history',
        'navTitle': 'Eligibility History',
        'headerTitle': 'Eligibility History',
        'routerPath': '/dashboard/finalize/history',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'assistance',
        'navTitle': 'Individual Assistance',
        'headerTitle': 'Individual Assistance',
        'routerPath': '/dashboard/finalize/individualAssistance',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'benefitsSummary',
        'navTitle': 'Determine Eligibility',
        'headerTitle': 'Determine Eligibility',
        'routerPath': '/dashboard/finalize/benefitsSummary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'medElig',
        'navTitle': 'Eligibility Details',
        'headerTitle': 'Eligibility Details',
        'routerPath': '',
        'clickable': false,
        'visible': true,
        'status': false,
        'childrenId': 'medEligMenu',
        'children': []
      },
      {
        'id': 'history',
        'navTitle': 'Eligibility History',
        'headerTitle': 'Eligibility History',
        'routerPath': '/dashboard/finalize/history',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'assistance',
        'navTitle': 'Individual Assistance',
        'headerTitle': 'Individual Assistance',
        'routerPath': '/dashboard/finalize/individualAssistance',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
    ],
    'benefitIssue': [
      {
        'id': 'bnftIssncsummary',
        'navTitle': 'Benefit Issuance Summary',
        'headerTitle': 'Benefit Issuance Summary',
        'routerPath': '/dashboard/benefitIssue/benefitsIssuanceSummary',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': 'bnftIssncsummaryMenu',
        'children': [
          {
            'navTitle': 'Summary',
            'scrollTo': 'summary',
            // 'routerPath': '/dashboard/finalize/benefitIssuanceSummaryv2',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Details',
            'scrollTo': 'details',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Re-issue',
            'clickable': true,
            'visible': true
          },
          {
            'navTitle': 'Issuance Maintainence',
            'clickable': true,
            'visible': true
          }
        ]
      }
    ],
    'changeReporting': [
      {
        'id': 'initStatus',
        'navTitle': 'Initiate Change-Report',
        'headerTitle': 'Initiate',
        'routerPath': '/dashboard/changeReporting/initiateChangeReporting',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ],
    'correspondence': [
      {
        'id': 'correspondence',
        'navTitle': 'Correspondence Search',
        'headerTitle': 'Correspondence Search',
        'routerPath': '/dashboard/correspondence',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ],
    'simplifiedReporting': [
      {
        'id': 'simplified-report',
        'navTitle': 'Simplified-Report',
        'headerTitle': 'Simplified-Report',
        'routerPath': '/dashboard/simplified-report',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ],
    'manualNotices': [
      {
        'id': 'manual-notices',
        'navTitle': 'Manual-Notices',
        'headerTitle': 'Manual-Notices',
        'routerPath': '/dashboard/manual-notices',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ],
    'initiateLegacy': [
      {
        'id': 'initiateLegacy',
        'navTitle': 'Initiate Legacy',
        'headerTitle': 'Initiate Legacy',
        'routerPath': '/dashboard/legacyCaseSearch/initiateLegacy',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ],
    'settings': [
      {
        'id': 'initiateVendor',
        'navTitle': 'Vendor Details',
        'headerTitle': 'Vendor Details',
        'routerPath': '/dashboard/settings/vendorDetails',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'holidays',
        'navTitle': 'Holidays',
        'headerTitle': 'Holidays',
        'routerPath': '/dashboard/settings/holidays',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'eligibilityParameter',
        'navTitle': 'Eligibility',
        'headerTitle': 'Eligibility',
        'routerPath': '/dashboard/settings/eligibilityParameter',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'districtOffice',
        'navTitle': 'District Office',
        'headerTitle': 'District Office',
        'routerPath': '/dashboard/settings/districtOffice',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'spanishManagement',
        'navTitle': 'Spanish Management',
        'headerTitle': 'Spanish Management',
        'routerPath': '/dashboard/settings/spanishManagement',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }, {
        'id': 'userAnalytics',
        'navTitle': 'User Analytics',
        'headerTitle': 'User Analytics',
        'routerPath': '/dashboard/settings/userAnalytics',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }, {
        'id': 'coPay',
        'navTitle': 'Copay Workers',
        'headerTitle': 'Copay Workers',
        'routerPath': '/dashboard/settings/coPayWorkers',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      },
      {
        'id': 'supervisor',
        'navTitle': 'Supervisor Action',
        'headerTitle': 'Supervisor Action',
        'routerPath': '/dashboard/settings/SupervisorAction',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }, {
        'id': 'bulletinBoard',
        'navTitle': 'System Bulletin/Postings',
        'headerTitle': 'System Bulletin/Postings',
        'routerPath': '/dashboard/settings/bulletins',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }, {
        'id': 'mdmWorkItems',
        'navTitle': 'MDM Work Items',
        'headerTitle': 'MDM Work Items',
        'routepath': '/dashboard/settings/mdmWorkItems',
        'clickable': true,
        'visible': true,
        'status': false,
        'childrenId': '',
        'children': []
      }
    ]
  };
  // TODO: this is a quick fix, it holds the subtype for the household circ unearned
  unearnedSubtype: any = [];
  DD: Map<string, KeyValue[]>;
  // household circ temp data beg
  authorizedRepresentativeYN = true;
  absentParentYN = true;
  HHCssn = '';
  // if it is -1 it will push a new one
  authRepEdit: number;
  //   if (this.securityObject)
  //     return JSON.parse(JSON.stringify(this.securityObject));
  //   return undefined;
  // }
  workerFullName: any;
  programEdit: number;
  insPolicyEdit: number;
  selectInstitute: any = Vendor;
  authReps: AuthorizedRepresentative[];
  insPolicy: InsurancePolicyDetails[];
  // Student information
  educationalDetailsEdit: number;          // Educational informatio
  // end recommended program
  education: SchoolInformation[];
  assetEdit: number;
  assetsData: Asset[];
  // household circ temp data end

  // AuthorizedRepresentative beg
  // authRepEdit this veriable keeps track of which authorize rep is being modified
  caseAssignedTo: CaseAssignedTo[] = [];
  selectedPrograms = { 'programs': [] };
  lookupData: any;
  lookupDataInterview: any;
  workerData: any;
  lookupDataTrialBudget: any;
  // AuthorizedRepresentative end
  lookupDataFinalize: any;
  lookupValues: any;
  lookupDataInquiry: any;
  localLookupData: LookupContainerModel;
  // Hamburger Menu elements
  hamburgerMenu: any[] = [
    {
      'icon': 'fa-folder',
      'label': 'Application Process',
      'collapseID': 'collapseOne',
      'menu': [
        {
          'label': 'Inquiry',
          'moduleName': 'inquiryModule',
          'url': 'dashboard/inquiry/intiateInquiry',
          'id': 'inquiry_id'
        },
        {
          'label': 'Trial Budget/Eligibility',
          'moduleName': 'trialBudgetModule',
          'url': 'dashboard/trialBudget/trialBudget',
          'id': 'trial_budget_id'
        },
        {
          'label': 'Screening',
          'moduleName': 'screeningModule',
          'url': 'dashboard/screening/initiateScreening',
          'id': 'screen_id'
        },
        {
          'label': 'Eligibility Determination',
          'moduleName': 'interviewModule',
          'url': 'dashboard/interview/initiateInterview',
          'id': 'interview_id'
        },
        {
          'label': 'Finalize',
          'moduleName': 'finalizeModule',
          'url': 'dashboard/finalize/initiateFinalization',
          'id': 'finalize_id'
        },
        {
          'label': 'BenefitIssue',
          'moduleName': 'benefitIssueModule',
          'url': 'dashboard/benefitIssue/benefitsIssuanceSummary',
          'id': 'benefit_issue_id'
        }
      ]
    },
    {
      'icon': 'fa-th-large',
      'label': 'Case Management',
      'collapseID': 'collapseTwo',
      'menu': [

        {
          'label': 'Case Narrative',
          'moduleName': 'caseNarrativeModule',
          'url': 'dashboard/caseNarrative/initiateNarrative'
        },

        {
          'label': 'Change Reporting',
          'moduleName': 'changeReportingModule',
          'url': 'dashboard/changeReporting/initiateChangeReporting'
        },
        {
          'label': 'Redetermination',
          'moduleName': 'redetermination-module',
          'url': 'dashboard/redetermination/initiateRedetermination'
        },
        // {
        //   'label': 'Ex Parte',
        //   'moduleName': 'redetermination-module',
        //   'url': 'dashboard/redetermination/exParte'
        // },
        {
          'label': 'Retro Medicaid',
          'moduleName': '',
          'url': ''
        }, {
          'label': 'Correspondence',
          'moduleName': 'correspondenceModule',
          'url': '/dashboard/correspondence/correspondence'
        },
        {
          'label': 'Benefit Review Form',
          'moduleName': 'simplifiedReportModule',
          'url': '/dashboard/simplifiedReporting/simplifiedReporting'
        },
        {
          'label': 'Manual Notices',
          'moduleName': 'manualNoticesModule',
          'url': '/dashboard/manualNotices/manualNotices'
        },
        {
          'label': 'Legacy Case Search',
          'moduleName': 'legacyCaseSearchModule',
          'url': '/dashboard/legacyCaseSearch/initiateLegacy'
        },
      ]
    },
    {
      'icon': 'fa-th-large',
      'label': 'Worker Management',
      'collapseID': 'collapseFour',
      'menu': [
        {
          'label': 'Inbox',
          'moduleName': '',
          'url': '/dashboard/inbox'
        },
        {
          'label': 'Recent Cases',
          'moduleName': '',
          'url': '/dashboard/myCases'
        }
      ]
    }
  ];
  previousCaseId: string = '';
  scriptsLoaded: Boolean = false;
  caseNarrativeCurrentPage: number = 0;
  customerSearchCurrentPage: number = 0;
  clearanceSearchCurrentPage: number = 0;
  clearanceSearchCISCurrentPage: number = 0;
  countCIS: number = 0;
  isCID: boolean = true;
  hasSearched: boolean = false;
  deleteMember = new BehaviorSubject<boolean>(false);
  private securityObject: any;
  private houseHoldModel: HouseholdMember = new HouseholdMember();
  private lookUps: LookupContainerModel;
  private trailBudgetUrl = 'http://localhost:4000/trailBudget/1';
  private postTrailBudgetUrl = 'http://localhost:4000/trailBudget';
  static sdrquestion: any;

  constructor(private mapToFile: MapToFile, private http: HttpClient, private shareService: DataShareService)
  //private apiManager: ApiManager)
  {
    this.countCIS = 0;
    this.isCID = true;
    this.hasSearched = false;
    this.authRepEdit = -1;
    this.programEdit = -1;
    this.authReps = [];
    this.insPolicyEdit = -1;
    this.insPolicy = [];
    ConstantsService.humanServiceModel = new HumanServices();
    ConstantsService.humanServiceModelWithDOB = new HumanServices();
    ConstantsService.CIDhumanServiceModel = new HumanServices();
    ConstantsService.CIShumanServiceModel = new HumanServices();
    mapToFile.members = new Map<number, FileList>();
    // TODO: delete me
    // ConstantsService.humanServiceModel.benefitsCase.householdCircumstance = {
    //   'earningsAmount': null,
    //   'unearnedAmount': null,
    //   'assetAmount': null,
    //   'shelterAmount': null,
    //   'homelessYN': 'N',
    //   'disabledYN': 'N',
    //   'refugeeYN': 'N',
    //   'blindYN': 'N',
    //   'destituteMigrantYN': 'N',
    //   'shelterIncludesMealsYN': 'N',
    //   'protectiveLivingArrangementYN': 'N',
    //   'communityBasedWaiverYN': 'N',
    //   'medicarePartAEntitlementYN': 'N',
    //   'absentParentYN': 'N',
    //   'absentParent': {
    //     'personName': {
    //       'recordId': 1,
    //       'firstName': 'Sam',
    //       'middleName': 'Elizabeth',
    //       'lastName': 'Smith',
    //       'type': '',
    //       'suffix': 'Jr',
    //       'dirtyStatus': ''
    //     },
    //     'dob': new Date('1/1/2000'),
    //     'ssn': {
    //       'recordId': 1,
    //       'ssnPart1': '111',
    //       'ssnPart2': '22',
    //       'ssnPart3': '3333',
    //       'dirtyStatus': ''
    //     },
    //     'gender': 'M',
    //     'race': {
    //       'primaryRace': '',
    //       'otherRaces': [],
    //       'verificationDocumentCd': '',
    //       'verificationRequestedYN': '',
    //       'docNames': new Map<string, string>(),
    //       'documentDetails': [],
    //       'verificationType': '',
    //       'verifiedYN': '',
    //       'dueDate': undefined,
    //       'automatedVerificationYN': '',
    //       'retryCount': undefined,
    //       'messages': [],
    //       'dirtyStatus': ''
    //     },
    //     'deletedYN': null,
    //     'dirtyStatus': ''
    //   },
    //   'dirtyStatus': ''
    // }
  }

  static getSpecialNeeds() {
    return ConstantsService.specialNeeds;
  }

  setSecurityObject(val: any) {
    if (!this.securityObject || window.location.hash.replace('#', '') == '/dashboard/homeScreen') {
      this.securityObject = val;
    }
    else if (window.location.hash.replace('#', '') == '/Login') {
      this.securityObject = null;
    }
  }

  getDeleteMember() {
    return this.deleteMember.asObservable();
  }

  setDeleteMember(deleteMember: boolean) {
    this.deleteMember.next(deleteMember);
  }

  getAuxiliaryData() {
    return this.auxiliaryData;
  }

  setAuxiliaryData(data) {
    this.auxiliaryData = data;
  }

  setApplicationDate(data) {
    this.applicationDate = data;
  }

  getApplicationDate() {
    if (ConstantsService.humanServiceModel.benefitsCase.caseStatusCode !== 'OP') {
      return this.applicationDate;
    } else return new Date();

  }

  getDefaultDate() {
    if (ConstantsService.humanServiceModel.benefitsCase.caseMode == CaseModeEnum.IN.toString()) {
      return this.applicationDate;
    }
    else {
      var date = new Date();
      var monthStartDay = new Date(date.getFullYear(), date.getMonth(), 1);
      return monthStartDay;
    }
  }

  setFilteredPrograms(data) {
    this.filteredProgramsList = data;
  }

  getFilteredProgramsList() {
    return this.filteredProgramsList;
  }

  getSecurityObject() {
    // let secureObj = this.userData;
    // return secureObj;
    let secureObj: {};
    if (this.userData) {
      secureObj = this.userData;
    }
    else if (this.userData == undefined) {
      secureObj = this.securityUserObj;
    }
    return JSON.parse(JSON.stringify(secureObj));
    // if (securityObj)
    // return JSON.parse(JSON.stringify(securityObj));
    // return JSON.parse(JSON.stringify(securityObj));
  }

  getRoleAccess(): any {
    // if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase
    //   && ConstantsService.humanServiceModel.benefitsCase.caseId == 'C000000003768745') {
    //   return 2;
    // } else {
    //   return 1;
    // }
  }

  addHouseholdMemberWithCaseId(newHouseholdMember: HouseholdMember, caseId: any) {
    /*if (!ConstantsService.caseIdHouseholds[caseId]) {
      ConstantsService.caseIdHouseholds[caseId] = new Set();
    }*/
    if ((ConstantsService.humanServiceModel.benefitsCase.householdMembers.some(x =>
      x.individualId === newHouseholdMember.individualId)) === false) {
      ConstantsService.humanServiceModel.benefitsCase.householdMembers.push(newHouseholdMember);
    }
    else {
      for (const member of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        if (member.individualId === newHouseholdMember.individualId) {
          member.relationshipCd = newHouseholdMember.relationshipCd;
        }
      }
    }
    // if ((ConstantsService.householdMembers3.some(x => x.individualId === newHouseholdMember.individualId)) === false) {
    //   ConstantsService.householdMembers3.push(newHouseholdMember);
    // }
    // ConstantsService.caseIdHouseholds[caseId].add(householdId);
  }

  removeHouseholdIdFromCaseId(householdId: any, caseId: any) {
    // if (ConstantsService.caseIdHouseholds[caseId] && ConstantsService.caseIdHouseholds[caseId].has(householdId)) {
    //   ConstantsService.caseIdHouseholds[caseId].delete(householdId);
    // }
    if (ConstantsService.humanServiceModel &&
      ConstantsService.humanServiceModel.benefitsCase) {
      if (ConstantsService.humanServiceModel.benefitsCase.householdMembers.some(x => x.individualId === householdId)) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers =
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.filter(function (obj) {
            return obj.individualId !== householdId;
          });

        for (let members of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          let index = 0;
          for (let relation of members.relations) {
            if (relation.individualId == householdId) {
              members.relations.splice(index, 1);
            }
            index++;
          }
        }
      }
    }
  }

  getHouseholdWithHouseholdId(localHouseholdMemberId: string): HouseholdMember {
    const newHousehold: HouseholdMember = ConstantsService.humanServiceModel.benefitsCase.householdMembers.find((householdMemberX) =>
      householdMemberX.individualId === localHouseholdMemberId
    );
    return newHousehold;
  }

  getCurrentHousehold(): any[] {
    if (this.shareService.getDataFromMap('currentModule') === this.getText('screeningModule')) {
      if (!ConstantsService.humanServiceModel) {
        ConstantsService.humanServiceModel = new HumanServices();
      }
      if (!ConstantsService.humanServiceModel.benefitsCase) {
        ConstantsService.humanServiceModel.benefitsCase = new BenefitsCase();
      }
      if (!ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers = [];
      }
      {
        let values_2: any = [];
        for (const value of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          if (value && value.relationshipCd === 'SE') {
            values_2.push(value);
          }
        }
        for (const value of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          if (value && value.relationshipCd !== 'SE') {
            values_2.push(value);
          }
        }
        ConstantsService.humanServiceModel.benefitsCase.householdMembers = values_2;
      }
      return ConstantsService.humanServiceModel.benefitsCase.householdMembers;
    }
    if (this.shareService.getDataFromMap('currentModule') === this.getText('interviewModule') ||
      this.shareService.getDataFromMap('currentModule') === this.getText('finalizeModule') ||
      this.shareService.getDataFromMap('currentModule') === this.getText('benefitIssueModule') ||
      this.shareService.getDataFromMap('currentModule') === this.getText('benefitRecoveryModule') ||
      this.shareService.getDataFromMap('currentModule') === this.getText('caseHomeModule') ||
      this.shareService.getDataFromMap('currentModule') === this.getText('changeReportingModule') ||
      this.shareService.getDataFromMap('currentModule') === this.getText('redeterminationModule') ||
      this.shareService.getDataFromMap('currentModule') === this.getText('correspondenceModule')) {
      if (!ConstantsService.humanServiceModel) {
        ConstantsService.humanServiceModel = new HumanServices();
      }
      if (ConstantsService && ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase) {
        if (!ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          ConstantsService.humanServiceModel.benefitsCase.householdMembers = [];
        }
      }
      {
        let values_2: any = [];
        if (ConstantsService && ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase && ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          for (const value of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
            if (value && value.relationshipCd === 'SE') {
              values_2.push(value);
            }
          }
        }
        if (ConstantsService && ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase && ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          for (const value of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
            if (value && value.relationshipCd !== 'SE') {
              values_2.push(value);
            }
          }
        }
        if (ConstantsService && ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase) {
          ConstantsService.humanServiceModel.benefitsCase.householdMembers = values_2;
        }
      }
      return ConstantsService.humanServiceModel.benefitsCase.householdMembers;
    }
    if (this.shareService.getDataFromMap('currentModule') === this.getText('caseNarrativeModule')) {
      return ConstantsService.humanServiceModel.benefitsCase.householdMembers;
    }
  }

  getHohIndividualId() {
    for (let houseHold of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
      if (houseHold.headOfHouseholdYN == 'Y') {
        return houseHold.individualId;
      }
    }
  }

  addAuthRep(authRep: AuthorizedRepresentative) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    if (this.authRepEdit >= 0) {
      authRep.dirtyStatus = 'update';
      ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives[this.authRepEdit] = authRep;
      this.authRepEdit = -1;
    }
    else {
      authRep.dirtyStatus = 'insert';
      ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives.push(authRep);
    }
  }

  removeAuthRep(index: number) {
    ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives.splice(index, 1);
  }

  getProgramSelection(selectedProgram) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    selectedProgram.map(programs => {
      if (programs.programId === 0) {
        programs.dirtyStatus = 'insert';
      }
      else if (programs.programId !== 0 && programs.dirtyStatus !== 'delete') {
        programs.dirtyStatus = '';
      }
    });
    ConstantsService.humanServiceModel.benefitsCase.selectedPrograms = selectedProgram;
  }

  getProgramLength() {

  }

  addInsPolicy(insPolicy: InsurancePolicyDetails) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    for (let i = 0; i < ConstantsService.humanServiceModel.benefitsCase.householdMembers.length; i++) {
      if (this.insPolicyEdit >= 0) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers[i].insurancePolicies[this.insPolicyEdit] = insPolicy;
        this.insPolicyEdit = -1;
      }
      else {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers[i].insurancePolicies.push(insPolicy);
      }
    }
  }

  removeInsPolicy(index: number) {
    ConstantsService.humanServiceModel.benefitsCase.authorizedRepresentatives.splice(index, 1);
  }

  // Financial Assets
  addAsset(assetInformation: Asset) {
    if (!ConstantsService.humanServiceModel) {
      ConstantsService.humanServiceModel = new HumanServices();
    }
    if (this.assetEdit >= 0) {
      let individualId: string;
      for (let i = 0; i < ConstantsService.humanServiceModel.benefitsCase.householdMembers.length; i++) {
        let hhmem = ConstantsService.humanServiceModel.benefitsCase.householdMembers[i];
        if (hhmem.individualId === individualId) {
          return hhmem.assets;
        }
        hhmem.assets[this.assetEdit] = assetInformation;
      }
      this.houseHoldModel.assets[this.assetEdit] = assetInformation;
      this.assetEdit = -1;
    }
    else {
      this.houseHoldModel.assets.push(assetInformation);
    }
  }

  deleteAsset(index: number) {
    this.houseHoldModel.assets.splice(index, 1);
  }

  getText(key: string): string {
    return ConstantsService[key];
  }

  getConstant(key: string): any {
    return ConstantsService[key];
  }

  getDropDownFromLookup(val: string, checkServiceData: boolean = true): any {
    if (checkServiceData) {
      if (this.shareService.getDataFromMap('currentModule') === this.getText('interviewModule') ||
        this.shareService.getDataFromMap('currentModule') === this.getText('benefitIssueModule') ||
        this.shareService.getDataFromMap('currentModule') === this.getText('benefitRecoveryModule')) {
        if (this.lookupDataInterview && this.lookupDataInterview.localizedLookups[val] &&
          this.lookupDataInterview.localizedLookups[val].beans) {
          return this.lookupDataInterview.localizedLookups[val].beans;
        }
      }
      else if (this.shareService.getDataFromMap('currentModule') === this.getText('finalizeModule')) {
        if (this.lookupDataFinalize && this.lookupDataFinalize.localizedLookups[val] &&
          this.lookupDataFinalize.localizedLookups[val].beans) {
          return this.lookupDataFinalize.localizedLookups[val].beans;
        }
      }
      else if (this.shareService.getDataFromMap('currentModule') === this.getText('inquiryModule')) {
        if (this.lookupDataInquiry && this.lookupDataInquiry.localizedLookups[val] &&
          this.lookupDataInquiry.localizedLookups[val].beans) {
          return this.lookupDataInquiry.localizedLookups[val].beans;
        }
      }
      else if (this.shareService.getDataFromMap('currentModule') === this.getText('caseHomeModule')) {
        if (this.lookupDataInterview && this.lookupDataInterview.localizedLookups[val] &&
          this.lookupDataInterview.localizedLookups[val].beans) {
          return this.lookupDataInterview.localizedLookups[val].beans;
        }
      }
      // else if (this.shareService.getDataFromMap('currentModule') === this.getText('caseHomeModule')) {
      //   if (this.lookupDataInterview && this.lookupDataInterview.localizedLookups[val] &&
      //     this.lookupDataInterview.localizedLookups[val].beans) {
      //     return this.lookupDataInterview.localizedLookups[val].beans;
      //   }
      // }
      else if (this.lookupDataInterview && this.lookupDataInterview.localizedLookups[val] &&
        this.lookupDataInterview.localizedLookups[val].beans) {
        return this.lookupDataInterview.localizedLookups[val].beans;
      }
      if (this.lookupData && this.lookupData.localizedLookups[val] && this.lookupData.localizedLookups[val].beans) {
        return this.lookupData.localizedLookups[val].beans;
      }
    }
    if (this.localLookupData && this.localLookupData.localizedLookups[val] && this.localLookupData.localizedLookups[val].beans) {
      return this.localLookupData.localizedLookups[val].beans;
    }
    return [];
  }

  getDropdownValueCodeFromLongLabel(key: string, value: string): string {
    if (key && value) {
      for (let entity of this.getDropDownFromLookup(key)) {
        if (entity.lookupBean.longLabel == value) {
          return entity.lookupBean.code;
        }
      }
    }
    return '';
  }

  getDropdownValue(key: string, value): string {
    /*     for (const entry of this.getDropDownFromLookup(key)) {
        const patt = new RegExp('0*' + entry.lookupBean.code);
        const res = patt.test(value);
          if (res == true) {
            return entry.lookupBean.longLabel;
          } else if (entry.lookupBean.shortLabel == value) {
            return entry.lookupBean.longLabel;
          }
        }
      } */
    if (key && value) {
      if (this.lookupValues && this.lookupValues[key]) {
        let longVal = 'unknown';
        const temp = this.lookupValues[key].find(x => {
          if (x.lookupTypeCd == key && x.lkpDesc == value) return true;
        });
        if (temp && temp['lookupLongVal']) longVal = temp['lookupLongVal'].trim();
        return longVal;
      } else {
        for (const entry of this.getDropDownFromLookup(key)) {
          const patt = new RegExp('0*' + value);
          const res = patt.test(entry.lookupBean.code);
          if (res == true) {
            return entry.lookupBean.longLabel;
          }
          else if (entry.lookupBean.longLabel == value) {
            return entry.lookupBean.longLabel;
          } else if (entry.lookupBean.lkpDesc === value) {
            return entry.lookupBean.lookupLongVal;
          }
        }
      }
      return 'Unknown';
    }

  }

  setDocInfo(docInfo: any) {
    if (docInfo) ConstantsService.docInfo = docInfo;
    else ConstantsService.docInfo = null;
  }

  get docInfo(): any {
    return ConstantsService.docInfo;
  }

  getDropdownValueFromSortOrder(key: string, position: Number): string {
    if (key && position) {
      for (const entry of this.getDropDownFromLookup(key)) {
        if (entry.sortOrder === position) {
          return entry.lookupBean.longLabel;
        }
      }
      return 'Unknown';
    }
  }

  getDropdownValueForMedCoverageGroup(medCoverageCd: string): string {
    if (!medCoverageCd) {
      return medCoverageCd;
    }
    let medCoverageStartWith: string = medCoverageCd.substr(0, 1);
    let key: string = '';
    switch (medCoverageStartWith) {
      case 'L':
      case 'S': {
        key = 'ABorD';
        break;
      }
      case 'E': {
        key = 'FCandSA';
        break;
      }
      case 'F': {
        key = 'Family';
        break;
      }
      case 'G': {
        key = 'Refugees';
        break;
      }
      case 'H': {
        key = 'HCBWaiver';
        break;
      }
      case 'M': {
        key = 'MASO';
        break;
      }
      case 'P': {
        key = 'Pregnant';
        break;
      }
      case 'Q': {
        key = 'MASOLTC';
        break;
      }
      case 'T': {
        key = 'FamilyLTC';
        break;
      }
      case 'X': {
        key = 'MiscellaneousM';
        break;
      }
      default: {
        key = '';
        break;
      }
    }
    if (key) {
      let lookupVal: string = this.getDropdownValue(key, medCoverageCd);
      return lookupVal;
    }
    else {
      return medCoverageCd;
    }

  }

  checkDisabledLookupCd(lookupItem) {
    let conversionUnkonwLookupCd: string = ',ZZ,CO,CV,';
    let conversionUnkonwLookupDesc: string = ',Unknown,Conversion,';
    return conversionUnkonwLookupCd.includes(',' + lookupItem.code + ',')
      && conversionUnkonwLookupDesc.includes(',' + lookupItem.longLabel + ',');

  }

  getClientById(Id: string): HouseholdMember {
    if (Id) {
      if (ConstantsService.humanServiceModel.clientManagement) {
        for (const entry of ConstantsService.humanServiceModel.clientManagement.results) {
          if (entry.individualId == Id && this.shareService.getDataFromMap('memberEdit') != true) {
            return entry;
          }
        }
      }
      if (ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        for (const entry of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
          if (entry.individualId == Id && this.shareService.getDataFromMap('memberEdit') === true) {
            return entry;
          }
        }
      }
    }
    return undefined;
  }

  getInterviewHouseholdById(Id: string): HouseholdMember {
    if (Id !== '') {
      for (const entry of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
        if (entry.individualId === Id) {
          return entry;
        }
      }
    }
    return undefined;
  }

  ariaLabelSuffix(val) {
    if (val == 'I') {
      return 'first';
    } else if (val == 'II') {
      return 'second';
    } else if (val == 'III') {
      return 'third';
    } else if (val == 'IV') {
      return 'fourth';
    } else if (val == 'V') {
      return 'fifth';
    } else if (val == 'Jr') {
      return 'junior';
    } else if (val == 'Sr') {
      return 'senior';
    } else return val;
  }

  getContactInformation(): ContactInformation {
    if (ConstantsService.contactInfo !== NaN) {
      return ConstantsService.contactInfo;
    }
    return undefined;
  }

  getProgramName(): string[] {
    if (ConstantsService.programNameSelect !== null) {
      return ConstantsService.programNameSelect;
    }
  }

  getSignature(): Signature {
    if (ConstantsService.signatureStatus !== NaN) {
      return ConstantsService.humanServiceModel.benefitsCase.interviewSignature;
    }
    return undefined;
  }

  getDepositionSignature(): Signature {
    if (ConstantsService.signatureStatus !== NaN) {
      return ConstantsService.humanServiceModel.benefitsCase.signature;
    }
    return undefined;
  }

  getScheduleInterview(): ScheduleInterview {
    if (ConstantsService.scheduleInterviewStatus !== NaN) {
      return ConstantsService.humanServiceModel.benefitsCase.scheduleInterview;
    }
    return undefined;
  }

  getEmergencyAssistance() {
    return ConstantsService.emergencyAssistance;
  }

  // getStudentInformation(individualId: string): StudentInfo {
  //   for (let i = 0; i < ConstantsService.humanServiceModel.benefitsCase.householdMembers.length; i++) {
  //     let hhmem = ConstantsService.humanServiceModel.benefitsCase.householdMembers[i];
  //     if (hhmem.individualId === individualId) {
  //       return hhmem.studentInformation;
  //     }
  //   } return null;
  // }

  // getCurrentEmployment() {
  //   const employmentUrl = 'http://192.168.0.6:8080/api-web-1.0/application-process/interview/78/household/absent-parent/622/emphistory';
  //   console.log(this.http.get(employmentUrl, { observe: 'response' }));
  //   return this.http.get(employmentUrl, { observe: 'response' });
  // }
  // getCourtOrder() {
  //   const courtOrderUrl = 'http://192.168.0.11:8080/api-web-1.0/application-process/interview/1/household/absent-parent/631/court-order';
  //   return this.http.get(courtOrderUrl, { observe: 'response' });
  // }
  // postCurrentEmployment(currentEmployer) {
  // const employmentUrl = 'http://192.168.0.11:8080/api-web-1.0/application-process/interview/988/household/absent-parent/622/emphistory';
  //   return this.http.post(employmentUrl, currentEmployer, { observe: 'response' });
  // }

  getAdditionalInformationById(Id: string): AdditionalInformation {
    const tempId = Number(Id);
    if (tempId !== NaN) {
      return ConstantsService.additionalInformation;
    }
    return undefined;
  }

  getFormerEmployment() {
    const employmentUrl = 'http://192.168.0.3:8080/api-web-1.0/application-process/interview/988/household/absent-parent/1/emphistory';
    console.log(`this.http.get(employmentUrl, { observe: 'response' })`);
    return this.http.get(employmentUrl, { observe: 'response' });
  }

  generateArray(obj) {
    return Object.keys(obj).map((k) => {
      return { key: k, value: obj[k] }
    });
  }

  // ************* Check Duplicate Values in an Array, return type boolean */
  duplicatesExist(arr) {
    let uniq = arr
      .map((name) => {
        return { count: 1, name: name }
      })
      .reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count
        return a
      }, {})
    const duplicates = Object.keys(uniq).filter((a) =>
      uniq[a] > 1)
    if (duplicates.length) {
      return true;
    }
    else {
      return false;
    }
  }

  subheaderTitle(title: string) {
    let caseMode = this.getCaseMode();
    if (caseMode != '' && this.shareService.getDataFromMap('currentModule') != this.getText('trialBudgetModule')) {
      ConstantsService.headerTitle = caseMode + title;
    }
    else {
      if (this.shareService.getDataFromMap('currentModule') === this.getText('screeningModule')) {
        ConstantsService.headerTitle = 'Application Registration: ' + title;
      }
      if (this.shareService.getDataFromMap('currentModule') === this.getText('interviewModule')) {
        ConstantsService.headerTitle = 'Eligibility Determination: ' + title;
      }
      if (this.shareService.getDataFromMap('currentModule') === this.getText('finalizeModule')) {
        ConstantsService.headerTitle = 'Finalize: ' + title;
      }
      if (this.shareService.getDataFromMap('currentModule') === this.getText('benefitIssueModule')) {
        ConstantsService.headerTitle = 'Benefit Issuance: ' + title;
      }
      if (this.shareService.getDataFromMap('currentModule') === this.getText('benefitRecoveryModule')) {
        ConstantsService.headerTitle = 'Benefit Recovery: ' + title;
      }
      if (this.shareService.getDataFromMap('currentModule') === this.getText('interfaceModule')) {
        ConstantsService.headerTitle = 'Interface: ' + title;
      }
      if (this.shareService.getDataFromMap('currentModule') === this.getText('trialBudgetModule')) {
        ConstantsService.headerTitle = 'Trial Budget';
      }
      if (this.shareService.getDataFromMap('currentModule') === this.getText('caseHomeModule')) {
        ConstantsService.headerTitle = 'Case Home';
      }
    }
  }

  getCaseMode() {
    if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase
      && ConstantsService.humanServiceModel.benefitsCase.caseMode) {
      if (ConstantsService.humanServiceModel.benefitsCase.caseMode === 'IC') {
        return 'Change Reporting: ';
      }
      else if (ConstantsService.humanServiceModel.benefitsCase.caseMode === 'RD') {
        return 'Redetermination: '
      }
      else if (ConstantsService.humanServiceModel.benefitsCase.caseMode === 'IN') {
        return 'Intake: '
      }
    }
    return '';
  }

  // *****************Load Help Text - End********************** */

  getAgName(caseId): Observable<any> {
    let headers = new HttpHeaders();
    // this.apiManager.fetchData('auxiliaryissuance', undefined, ApiManager.GET,
    //          undefined, undefined, undefined, undefined)
    //         .subscribe((res: Response) => {
    //             this.result = JSON.stringify(res);
    //             console.log(this.result);
    //             if (this.result.includes('DHS Login')) {
    //                 this.clientLogout();
    //             } else {
    //                 this.matchDetails();
    //             }
    //         });

    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('uid', 'localUser');
    headers = headers.append('role', 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL');
    headers = headers.append('cookie', 'amlbcookie=01; amlbcookie=01; iPlanetDirectoryPro=AQIC5wM2LY4SfczTrszle3aiyeSLDtbsTb-4NMNFx8CvrIo.*AAJTSQACMDIAAlNLABMxNDE3OTAxNzExODQ0MjE1MzE5AAJTMQACMDE.*; iPlanetDirectoryPro=AQIC5wM2LY4SfczTrszle3aiyeSLDtbsTb-4NMNFx8CvrIo.*AAJTSQACMDIAAlNLABMxNDE3OTAxNz');
    return this.http.get('http://localhost:8080/eeapi/application-process/bi/ag-num/' + caseId, {
      headers,
      observe: 'response'
    }).pipe(
      map((res: HttpResponse<any>) => res.body),
      catchError((error: HttpErrorResponse) => observableThrowError(error.error.error || 'Server error')));
  }

  setPrograms(programs) {
    this.selectedPrograms = programs;
  }

  getPrograms() {
    return this.selectedPrograms.programs;
  }

  getAbsentParentInfo() {
    return ConstantsService.absentParent;
  }

  getAbsentParentContactInfo() {
    return ConstantsService.abContactInfo;
  }

  getInterviewHealthDisabilityInsurance() {
    return ConstantsService.interviewHealthDisabilityInsurance;
  }

  getBenefitCase(caseId = 'C000000000022777') {
    this.http.get('http://10.88.32.139:8080/eeapi/application-process/screening/' + caseId, { observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => res.body))
      .subscribe((res) => {
        ConstantsService.humanServiceModel.benefitsCase.contactInformation = res.benefitsCase.contactInformation;
        ConstantsService.humanServiceModel.benefitsCase.additionalInformation = res.benefitsCase.additionalInformation;
        ConstantsService.humanServiceModel.benefitsCase.householdMembers = res.benefitsCase.householdMembers;
      },
        (err) => console.log(err));
    return ConstantsService.humanServiceModel.benefitsCase;
  }

  getContactInfo() {
    return JSON.parse(JSON.stringify(ConstantsService.humanServiceModel.benefitsCase.contactInformation)) as ContactInformation;
  }

  // }
  getAdditionalInfo() {
    if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase &&
      ConstantsService.humanServiceModel.benefitsCase.additionalInformation) {
      return JSON.parse(JSON.stringify(ConstantsService.humanServiceModel.benefitsCase.additionalInformation)) as AdditionalInformation;
    }
  }

  updateContactInfo() {
    return this.http.post('http://10.88.32.139:8080/eeapi/application-process/screening/C000000000022777/contact',
      ConstantsService.humanServiceModel.benefitsCase, { observe: 'response' }).pipe(map((res: HttpResponse<any>) => res.body));
  }

  // getClientInfo(){
  //    return JSON.parse(JSON.stringify(ConstantsService.humanServiceModel.benefitsCase.trialBudgetClient)) as TrialBudgetClient;

  updateHouseHoldCirc() {
    return this.http.post('http://10.88.32.139:8080/eeapi/application-process/screening/C000000000022777/household-circumstances',
      ConstantsService.humanServiceModel.benefitsCase, { observe: 'response' }).pipe(map((res: HttpResponse<any>) => res.body));
  }

  get memberDeleteStatus(): Observable<boolean> {
    return this.isDeletedMember.asObservable();
  }

  getEligibility() {
    return JSON.parse(JSON.stringify(ConstantsService.humanServiceModel.benefitsCase.eligibility)) as Eligibility;
  }

  showContent(arr: string[]) {
    if (arr) {
      for (let str of arr) {
        if (ConstantsService.deployment_LTC === str) {
          if (ConstantsService.deploymentMode === str) {
            return true;
          }
        }
        if (ConstantsService.deployment_EA === str) {
          if (ConstantsService.deploymentMode === str) {
            return true;
          }
        }
        if (ConstantsService.deployment_Random === str) {
          if (ConstantsService.deploymentMode === str) {
            return true;
          }
        }
      }
    }
    return false;
  }

  identifyAccess(url) {
    if (url == '/dashboard/homeScreen') {
      false;
    }

    let res = this.getSecurityObject();
    if (!res || !res.accessMap || (url != '' && res.accessMap[url] === undefined)) {
      return false;
    }

    if (res.accessMap[url].accessMode == 'r') {
      return true;
    }

    return false;
  }

  getCourOrderPaymentInfo() {
    return ConstantsService.CourtOrderPayment;
  }

  getCoutOrderList() {
    return ConstantsService.courtOrderList;
  }

  getSelectedCourtOrder() {
    return ConstantsService.selectedCourtOrder;
  }

  getSelectedApIndex() {
    return ConstantsService.selectedAbsentParentIndex;
  }

  getCurrentEmployersInfo() {
    return ConstantsService.currentEmployers;
  }

  getFormerEmployersInfo() {
    return ConstantsService.previousEmployers;
  }

  getFormerIndex() {
    return ConstantsService.selectedPreviousIndex;
  }

  getCurrentIndex() {
    return ConstantsService.selectedCurrentIndex;
  }

  getSelectedFormerData() {
    return ConstantsService.selectedPreviousEmployee;
  }

  getSelectedApData() {
    return ConstantsService.selectedAbsentParent;
  }

  getSelectedCurrentData() {
    return ConstantsService.selectedCurrentEmployee;
  }

  getSelectedCourtIndex() {
    return ConstantsService.selectedCourtIndex;
  }

  setStaticPage(pageName) {
    this.staticPage = pageName;
  }

  getStaticPage() {
    return this.staticPage;
  }

  getNavigationObject() {
    return JSON.parse(JSON.stringify(this.navigation));
  }

  getLanguage(): string {
    if (this.shareService.getDataFromMap('language')) {
      this.langSelected = this.shareService.getDataFromMap('language');
    }
    else {
      this.shareService.addDataToMap('language', 'en_us');
      this.langSelected = this.shareService.getDataFromMap('language');
    }
    return this.langSelected;
  }

  setCaseAssignedTo(val: CaseAssignedTo[]) {
    this.caseAssignedTo = val;
  }

  getCaseAssignedTo(): CaseAssignedTo[] {
    return this.caseAssignedTo;
  }

  private extractLookupData(response: HttpResponse<any>): Map<string, KeyValue[]> {
    const body = response.body;
    const data = body.localizedLookups;
    if (data) {
      const keys: string[] = Object.keys(data); // get all the keys
      this.DD = new Map<string, KeyValue[]>();
      if (keys) {
        for (let i = 0; i < keys.length; i++) {
          const beansArray = data[keys[i]].beans;
          const optionsArray: Array<KeyValue> = new Array<KeyValue>();
          for (let j = 0; j < beansArray.length; j++) {
            optionsArray.push(new KeyValue(beansArray[j].lookupBean.code, beansArray[j].lookupBean.longLabel));
          }
          this.DD.set(keys[i], optionsArray)
        }
      }
      return this.DD;
    }
    else {
      return null;
    }
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (!(error instanceof ErrorEvent) && !(error instanceof Error)) {
      const body = error.error || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    }
    else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  private extractHelpTextData(sectionIDs: string[], response: HttpResponse<any>): Map<string, HelpText[]> {
    const body = response.body;
    const data = body.helpTexts.helpTexts;
    if (data) {
      // let keys:string[] = Object.keys(data); //get all the keys//
      const helpTextMap: Map<string, HelpText[]> = new Map<string, HelpText[]>();
      if (sectionIDs) {
        for (let i = 0; i < sectionIDs.length; i++) {
          const sectionHelpTextArray = data[sectionIDs[i]];
          const helptextArray: Array<HelpText> = new Array<HelpText>();
          const keys: string[] = Object.keys(sectionHelpTextArray);

          for (let j = 0; j < sectionHelpTextArray.length; j++) {
            helptextArray.push(new HelpText(sectionHelpTextArray[keys[j]].id,
              sectionHelpTextArray[keys[j]].htmlHelpText,
              sectionHelpTextArray[keys[j]].associatedFieldYN, sectionHelpTextArray[keys[j]].associatedFieldId))
          }
          helpTextMap.set(sectionIDs[i], helptextArray)
        }
      }
      return helpTextMap;
    }
    else {
      return new Map();
    }
  }
}
