import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'app/common/constants.service';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { AppUtilService } from 'app/common/app.util.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(public constantService: ConstantsService,
    public router: Router,
    public appUtil: AppUtilService,
  ) {
    if (environment.production && (environment.envName === 'prod' || environment.envName === 'stg4')) {
    this.setLTCOnly(true);

  }

   }

  ngOnInit() {
    // this.appUtil.showLoader();
  }
  setLTCOnly(val){
     this.constantService.deployment_LTCOnly = val;

      this.router.navigate(['/dashboard/homeScreen']);
    }



  }

