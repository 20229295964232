import { Address } from './address.class';
import { HouseholdMember } from './household-member.class';
import { SearchModel } from './search-model.class';
import { ProgramSelected } from './program-selected.class';

export class ClientContainer extends SearchModel<HouseholdMember> {
    constructor(
        public associateCaseId: any = '',
        public client: HouseholdMember = new HouseholdMember(),
        public address: Address = new Address(),
        public sourceSystem: string = '',
        public selectedPrograms: ProgramSelected [] = []
    ) {
        super();
    }
}
