import { BaseBean } from './base-bean.class';
import {ChangeReportingDetailClass} from "./change-reporting-detail.class";
export class ChangeReporting extends BaseBean {
    constructor(
      public changeReportingDetail : ChangeReportingDetailClass [] = [],
      public dateOfChange: any = null,
      public reportReceivedMethod: string =''
      ) {
        super();
      }

}
