import { BaseBean } from './base-bean.class';
import { ClientVerification } from './client-verification.class';

export class Medicare extends BaseBean {
    constructor(
        public recordId: number = undefined,
        public medicareStartDate: Date = null,
        public medicareTypeA: string = '',
        public medicareTypeB: string = '',
        public medicareTypeC: string = '',
        public medicareTypeD: string = '',
        public medicareTypeAStatus: string = '',
        public medicareTypeBStatus: string = '',
        public medicareTypeCStatus: string = '',
        public medicareTypeDStatus: string = '',
        public medicareVerification: ClientVerification = new ClientVerification(),
        public medicareClaimVerification: ClientVerification = new ClientVerification(),
        public medicareApplicationVerification: ClientVerification = new ClientVerification(),
        public premiumAmount: string = '',
        public claimId: string = '',
        public applicationNum: string = '',
        public appliedInd: string = '',
        public rcvdInd: string = '',
        public solqYN: string = ''
    ) {
        super();
    }
}
