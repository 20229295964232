import { BaseBean } from './base-bean.class';
import { PhoneNumber } from './phone-number.class';
import { Address } from './address.class';
import { Person } from 'app/model/api/person.class';
import { ClientVerification } from './client-verification.class';

export class Employer extends BaseBean{
  constructor(
    public emplrId: number = undefined,
    public companyLegalName: string = '',
    public insurance: string = '',
    public voluntaryQuitYN: string = '',
    public voluntaryQuitGoodCause: string = '',
    public rsnEmplmntQuit:string = '',
    public quitDate: Date = null,
    public wagesPerHours: number = undefined,
    public hoursPerMonth: number = undefined,
    public employerAddress: Address = new Address(),
    public phoneNumber: PhoneNumber = new PhoneNumber(),
    public occupation: string = '',
    public email: string = '',
    public employerEIN: string = '',
    public empContactPerson: Person = new Person(),
    public empContactPersonPhone: string = '',
    public empContactPersonPhoneExt: string = '',
    public empContactPersonEmail: string = '',
    public employerFederalId: string = '',
    public employmentBeginDate: Date = null,
    public employmentEndDate: any = null,
    public employmentVerification: ClientVerification = new ClientVerification(),

  ) {
    super();
  }
}
