import { BaseBean } from './base-bean.class';

export class AbsentParentMVA extends BaseBean {
    constructor(
        public drivingLicenseState: string = '',
        public drivingLicenseNumber: string = '',
        public licensePlateNumber: string = '',
        public licensePlateState: string = '') {
        super();
    }
}