import { BaseBean } from './base-bean.class';
import { Address } from './address.class';
import { PhoneNumber } from './phone-number.class';
import { PhoneNumberDetails } from "./phone-number-details.class";

export class ContactInformation extends BaseBean{
  constructor(
    public homeAddress: Address = new Address(),
    public mailingAddress: Address = new Address(),
    public sameMailingAddressYN: string = '',
    public phoneNumberDetails: PhoneNumberDetails = null,
    public phoneNumber: PhoneNumber = new PhoneNumber(),
    public altPhoneNumber: PhoneNumber = new PhoneNumber(),
    public email: string = '',
    public communicationLanguage: string = '',
    public preferredModeOfCommunication: string = '',
    public preferredContactTime: string = '',
    public previousAddresses: Address[] = [],
    public previousPhoneNumbers: PhoneNumber[] = []
  ) {
    super();
  }
}
