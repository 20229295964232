import { map } from 'rxjs/operators';
import { LookupContainerModel } from './../model/api/lookup-container-model.class';
import { ApiManager } from 'app/common/api-manager.service';
import { Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
// import { FormControl } from '@angular/forms';
// import { HouseholdMember } from './../model/api/household-member.class';
import { Injectable } from '@angular/core';
import { ConstantsService } from '../common/constants.service';
import { DataShareService } from '../common/data-share.service';
import { LocalizedLookupBean } from 'app/model/api/localized-lookup-bean.class';
// import { KeyValue } from './../model/api/key-value.class';
import { Address } from '../model/api/address.class';
import { PersonName } from '../model/api/person-name.class';
// import { HouseholdVerification } from '../model/api/household-verification.class';
import { ClientVerification } from '../model/api/client-verification.class';
import { ApiMessage } from '../model/api/api-message.class';
import { SocialSecurityNumber } from '../model/api/social-security-number.class';
// import { IndividualHouseholdDetails } from '../model/custom/individual-household-details.class';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
// import { validateConfig } from '@angular/router/src/config';
import * as moment from 'moment';
import { EventEmitterService } from './event.emitter.service';

declare var jquery: any;
declare var $: any;

@Injectable()
export class AppUtilService {
  statesDropdown: any[];
  public systemDate = new Date();
  public minDate = new Date()
  public maxDate = new Date();
  caseId: any;
  // Used to maintain Title Strip content
  curModule: any;
  curScreen: any;
  public curId: any;
  prevModule: string;
  public reloadCaseHome: boolean = false;
  private logoutUrl: any = environment.logoutURL;
  public isCasehasDocument: boolean = false;
  public isCasehasSpousalResourceAssessments: boolean = false;
  public enableTSSBtn: boolean = false;
  phoneMask = ['(', /[1-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // preModuleCopy: string;

  static isNil(value: any): value is (null | undefined) {
    return value === null || value === undefined || typeof (value) === 'undefined';
  }

  static isObject(value: any): boolean {
    return value && value.constructor === Object;
  }

  static isBlank(value: any): boolean {
    return this.isNil(value) ||
      (this.isObject(value) && Object.keys(value).length === 0) ||
      value.toString().trim() === '';
  }

  static isDataPresent(value: any): boolean {
    return !this.isBlank(value);
  }

  // ES6
  static removeDuplicates(arr) {
    const set = new Set(arr);
    const values = set.values();
    return Array.from(values);
  }

  logout() {
    this.apiManager.fetchData('logoutApiUrl', undefined, ApiManager.GET, undefined, undefined, undefined, undefined, undefined).subscribe(
      res => {
        console.log('api for logging the logout was called succesfully');
      }
    );
    window.location.href = this.logoutUrl;
  }

  getDifferenceInDays(firstDate, secondDate) {
    const first = new Date(firstDate);
    const second = new Date(secondDate);
    const day = 60 * 60 * 24 * 1000;
    const final = Math.ceil(Math.abs(second.getTime() - first.getTime()) / day);
    if (final > -99999) {
      return final;
    }
    else {
      return null;
    }
  }

  constructor(private constantsService: ConstantsService,
    private shareService: DataShareService,
    private router: Router,
    private apiManager: ApiManager,
    private storage: StorageService,
    public emitter: EventEmitterService) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    // this.storage.getTimeSubject().subscribe(dt => {
    //   this.systemDate = dt;
    //   if (dt)
    //     window["systemDate"] = dt;
    // })
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      if (event.url) {
        // this.setCurrentModule(event.url);
        this.loadLookups(event.url);
      }
    }
  }

  changeDateToTrimTimeFromDate(el): any {
    if (el) {
      const date: any = el.split('T');
      if (date.length > 1) {
        return date[0];
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  // setCurrentModule(url: string) {
  //   const arr = url.split('/');
  //   if (arr && arr[2] && this.preModuleCopy !== arr[2]) {
  //     this.preModuleCopy = arr[2];
  //     if (arr[2] === 'screening') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('screeningModule'));
  //     } else if (arr[2] === 'interview') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('interviewModule'));
  //     } else if (arr[2] === 'finalize') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('finalizeModule'));
  //     } else if (arr[2] === 'inquiry') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('inquiryModule'));
  //     } else if (arr[2] === 'caseNarrative') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('caseNarrativeModule'));
  //     } else if (arr[2] === 'verify-checklist') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('VerifyChecklistModule'));
  //     } else if (arr[2] === 'trialBudget') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('trialBudgetModule'));
  //     } else if (arr[2] === 'changeReporting') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('changeReportingModule'));
  //     } else if (arr[2] === 'redetermination') {
  //       this.shareService.addDataToMap('currentModule', this.constantsService.getText('redeterminationModule'));
  //     } else {
  //       this.shareService.addDataToMap('currentModule', '');
  //       this.preModuleCopy = '';
  //     }
  //   }
  // }

  getEffectiveEndDate(endDate) {
    let effectiveEnd: string;
    if (endDate == "12/31/9999" || endDate == "9999-12-31") {
      effectiveEnd = "Present";
    }
    else {
      effectiveEnd = this.changeDate(endDate);
    }
    return effectiveEnd;
  }

  loadLookups(url: string) {
    const arr = url.split('/');
    if (arr && arr[2] && this.prevModule != arr[2]) {
      this.prevModule = arr[2];
      // if (arr[2] == 'screening') {
      //   this.populateLookup('screening', 'lookupData');
      // }
      // else if (arr[2] == 'interview') {
      //   this.populateLookup('screening', 'lookupData');
      //   this.populateLookup('interview', 'lookupDataInterview');
      // }
    }
  }

  // populateLookup(module, lookupVariable) {
  //   this.showLoader();
  //   this.apiManager.fetchData('lookup', { 'moduleName': module }, ApiManager.GET, undefined).pipe(
  //     map((res: Response) => {
  //       this.apiManager.lookUpModuleName.set(module, true);
  //       this.constantsService[lookupVariable] = res as any as LookupContainerModel;
  //       return this.constantsService[lookupVariable];
  //     }))
  //     .subscribe((res: LookupContainerModel) => {
  //       this.hideLoader();
  //     },
  //       error => {
  //         this.hideLoader();
  //         console.log('look up API call failure: Error');
  //       });
  // }

  setCurrentDate(dt) {
    this.systemDate = dt;
  }

  getCurrentDate() {
    const tDate: Date = this.storage.getTimeTravelDate();
    if (tDate == undefined || tDate == null) {
      return new Date();
    }
    else {
      return tDate;
    }
  }

  checkFutureBeginDate(date) {
    let d = new Date();
    d.setMonth(d.getMonth() + 2);
    let beginFutureDate = d.toISOString();
    let futureBeginDate = this.changeDateToMMDDYYYYFormat(this.changeDateYYYYMMDD(beginFutureDate));
    let eventStartTime = new Date(date);
    let eventFutureTime = new Date(futureBeginDate);
    return eventFutureTime.valueOf() - eventStartTime.valueOf();
  }

  determineHouseholdBackgroundColor(response: boolean) {
    if (response) {
      return 'blueBG';
    }
    return 'redBG';
  }

  getFullName(person: PersonName): string {
    let fullName = '';
    if (!person) {
      return fullName;
    }
    if (person.firstName) {
      fullName += person.firstName[0].toUpperCase() + person.firstName.substr(1).toLowerCase();;
    }
    if (person.middleName) {
      fullName += ' ' + person.middleName[0].toUpperCase() + person.middleName.substr(1).toLowerCase();;
    }
    if (person.lastName) {
      fullName += ' ' + person.lastName[0].toUpperCase() + person.lastName.substr(1).toLowerCase();
    }
    if (person.suffix) {
      fullName += ' ' + person.suffix;
    }
    return fullName;
  }

  getProgramName(program: string) {
    if (program) {
      for (const entry of this.constantsService.lookupData.localizedLookups['ProgramCode'].beans) {
        if (entry && entry.lookupBean && entry.lookupBean.code === program) {
          return entry.lookupBean.longLabel;
        }
      }
    }
  }

  getPersonName(name: string, suffix: string): PersonName {
    let personName: PersonName = new PersonName();
    const arr: any[] = name.split(' ');
    let size: number;
    personName.suffix = suffix;
    size = (arr.length > 3) ? arr.length - 2 :
      (arr.length === 3 && suffix === '') ? arr.length - 1 :
        (arr.length === 3 && suffix !== '') ? arr.length - 2 : arr.length - 1;
    personName.firstName = arr[0];
    if (arr.length > 3 || (arr.length === 3 && suffix === '')) {
      for (let i = 1; i < size; i++) {
        personName.middleName += arr[i];
      }
    }
    if (size > 0) {
      personName.lastName = arr[size];
    }
    if (size !== arr.length - 2) {
      personName.suffix = '';
    }
    return personName;
  }

  addScript(src) {
    const s = document.createElement('script');
    s.setAttribute('src', src);
    s.setAttribute('type', 'text/javascript');
    s.async = true;
    document.body.appendChild(s);
  }

  getFullAddress(address: Address, includeCounty: boolean = true): any {
    let fullAddress = '';
    if (!address) {
      return fullAddress;
    }

    if (address.addressLine1) {
      fullAddress += address.addressLine1;
    }
    if (address.addressLine2) {
      fullAddress += '\n ' + address.addressLine2;
    }
    if (address.city) {
      fullAddress += '\n ' + address.city;
    }
    let stateFlag = false;

    let countyFlag = false;
    if (address.county && includeCounty) {
      if (this.constantsService && this.constantsService.lookupData && this.constantsService.lookupData.localizedLookups && this.constantsService.lookupData.localizedLookups['County']) {
        for (const entry of this.constantsService.lookupData.localizedLookups['County'].beans) {
          if (entry.lookupBean.code == address.county || entry.lookupBean.longLabel == address.county) {
            fullAddress += '\n ' + entry.lookupBean.longLabel;
            countyFlag = true;
          }
        }
      }
      if (!countyFlag) {
        fullAddress += '\n ' + address.county;
      }
    }
    if (address.district) {
      fullAddress += '\n ' + address.district;
    }
    if (address.state) {
      if (this.constantsService && this.constantsService.lookupData && this.constantsService.lookupData.localizedLookups && this.constantsService.lookupData.localizedLookups['State']) {
        this.constantsService.lookupData.localizedLookups['State'].beans.forEach(entry => {
          if (entry.lookupBean.code === address.state || entry.lookupBean.longLabel === address.state) {
            fullAddress += '\n ' + entry.lookupBean.longLabel;
            stateFlag = true;
          }
        })
      }
      if (!stateFlag) {
        fullAddress += '\n ' + address.state;
      }
    }
    if (address.zipcode) {
      fullAddress += '\n ' + address.zipcode;
    }
    if (address.zip4code && address.zipcode) {
      fullAddress += '-' + address.zip4code;
    }
    return fullAddress;
  }

  YNConvert(yesno: string): boolean {
    let bool: boolean;
    if (typeof yesno === 'string') {
      yesno = yesno.trim().toLowerCase();
    }
    bool = yesno === 'y' || yesno === 'yes' || yesno === 'true' ? true : false;
    return bool;
  }

  BoolConvert(bool: any): string {
    return (bool === true) ? 'Y' : 'N';
  }

  BoolObjectConvert(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && key.endsWith('YN')) {
        // this fix is breaking other parts of the code
        // obj[key] = (obj[key] === true || obj[key] === 'Y') ? 'Y' : 'N';
        // if (obj[key] === true || obj[key] === 'Y') {
        //   obj[key] = 'Y';
        // } else if (obj[key] === false || obj[key] === 'N' || obj[key] === '') {
        //   obj[key] = 'N';
        // }
        if (obj[key] === true) {
          obj[key] = 'Y';
        }
        else if (obj[key] === false) {
          obj[key] = 'N';
        }
      }
    }
    return obj;
  }

  BooltoYNObjectConvert(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.BooltoYNObjectConvert(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.BooltoYNObjectConvert(copy[key]);
        }
        else if (/verifiedYN/i.test(key)) {
          if (copy[key] === false || copy[key] === 'N') {
            copy[key] = 'N';
          }
          else if (copy[key] === true || copy[key] === 'Y') {
            copy[key] = 'Y';
          }
        }
        else if (key.endsWith('YN') && (copy[key] === true || copy[key] === 'Y')) {
          copy[key] = 'Y';
        }
        else if (key.endsWith('YN') && (copy[key] === false || copy[key] === 'N' || copy[key] === '')) {
          copy[key] = 'N';
        }
      }
    }
    return copy;
  }

  YNObjectConvert(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key.endsWith('YN')) {
        // obj[key] = obj[key] == 'Y' ? true : false;
        if (obj[key] === 'Y') {
          obj[key] = true;
        }
        else if (obj[key] === 'N') {
          obj[key] = false;
        }
      }
    }
    return obj;
  }

  YNtoBoolObjectConvert(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.YNtoBoolObjectConvert(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.YNtoBoolObjectConvert(copy[key]);
        }
        else if (/verifiedYN/i.test(key)) {
          if (copy[key] === false || copy[key] === 'N') {
            copy[key] = 'N';
          }
          else if (copy[key] === true || copy[key] === 'Y') {
            copy[key] = 'Y';
          }
        }
        else if (key.endsWith('YN') && (copy[key] === true || copy[key] === 'Y')) {
          copy[key] = true;
        }
        else if (key.endsWith('YN') && (copy[key] === false || copy[key] === 'N' || copy[key] === '')) {
          copy[key] = false;
        }
      }
    }
    return copy;
  }

  ConvertDateToNull(dateStr: string) {
    if (dateStr == '') {
      return null;
    }
    else {
      return dateStr;
    }
  }

  prepareDateObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.prepareDateObj(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.prepareDateObj(copy[key]);
        }
        // TODO: monthAndYear will be changed so this can be removed
        // else if (key.endsWith('Date') || key === 'monthAndYear') {
        //   copy[key] = copy[key] == '' ? null : this.changeDateToYYYYMMDDFormat(copy[key]);
        // }
        else if (key.endsWith('Date') || key === 'monthAndYear' || key === 'date' || key === 'effectiveBeginDt' || key === 'effectiveEndDt'
          || key === 'anticipatedReceiptDt' || key === 'dateOfBirth' || key === 'availabilityDt' || key === 'authrzDt'
          || key === 'statusDt' || key === 'payMonth' || key === 'dt') {
          copy[key] = copy[key] == '' ? null : this.changeDateToYYMMDDFormat(copy[key]);
        }
        else if (key.endsWith('issuanceStatusCd') || key.endsWith('issuanceMethodCd') || key.endsWith('programCd') || key.endsWith('programSubType')) {
          copy[key] = copy[key] == '' ? null : copy[key];
        }
      }
    }
    return copy;
  }

  changeMonthYearFormat(el): string {
    if (el !== "null") {
      // const date: any = el.split('.');
      let yearMonth: string[] = [];
      yearMonth[0] = el.slice(4, 6);
      yearMonth[1] = el.slice(0, 4);
      return (yearMonth[0] + '/' + yearMonth[1]);
    }
  }

  changeMonthYearFormatToDate(el): string {
    if (el !== "null") {
      // const date: any = el.split('.');
      let yearMonth: string[] = [];
      yearMonth[0] = el.slice(4, 6);
      yearMonth[1] = el.slice(0, 4);
      return (yearMonth[0] + '/' + '01' + '/' + yearMonth[1]);
    }
  }

  changeDateFormatToMM(el): string {
    if (el !== "null" && el !== undefined) {
      let value = el.toString();
      return (value.slice(4, 6) + '/' + value.slice(0, 4));
    }
  }

  changeToYYYYMM(el) {
    if (el) {
      const date: any = el.split("/");
      if (date.length > 1) {
        return date[1] + date[0];
      }
      else {
        return date[0];
      }
    }
    return el;
  }
  changeToTimeFormat(el) {
    if (el) {
      const date: any = el.split(":");
      if (date.length > 1) {
        return date[0] + ':' + date[1];
      }
    }
    return el;
  }
  changeToAMPM(el) {
    if (el) {
      if (el == '12:00' || el == '12:30' || el == '12:00:00' || el == '12:30:00') {
        return el + ' ' + 'PM'
      } else if (el > '12:00' || el > '12:00:00') {
        const date: any = el.split(":");
        if (date.length > 1) {
          return date[0] - 12 + ':' + date[1] + ' ' + 'PM';
        }
      }
      else return el + ' ' + 'AM';
    }
  }

  dollarAmnt(dat) {
    return '$' + dat;
  }

  displayDollarAmount(el) {
    if (el) {
      return '$' + el + '.00';
    }
    else {
      return "$0.00";
    }
  }

  remDollarAmount(el) {
    if (el || el == 0) {
      const amnt = el.split("$");
      const amount = amnt[1].split(".");
      return amount[0];
    }
  }

  changeDateToYYMMDDFormat(el): any {
    if (el != undefined && el != '') {
      if (el instanceof Date) {
        el = el.toISOString();
      }
      else {
        el = new Date(el).toISOString();
      }

      const index = el.toString().indexOf('T');

      if (index != -1) {
        const dateFull: any = el.split('T');
        return dateFull[0];
      }
      else {
        return el;
      }
    }
  }

  loadDateObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in obj) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.loadDateObj(nestCopy));
        }
        else if (typeof copy[key] == 'object') {
          copy[key] = this.loadDateObj(copy[key]);
        }
        // TODO: monthAndYear will be changed so this can be removed
        else if (key.endsWith('Date') || key === 'monthAndYear') {
          copy[key] = copy[key] == '' ? null : this.changeDateToMMDDYYYYFormat(copy[key]);
        }
      }
    }
    return copy;
  }

  loadNullObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in obj) {
      // if (typeof copy[key] == 'object') {
      copy[key] = copy[key] == '' ? null : copy[key];
      // }
    }
    return copy;
  }

  prepareLoad(obj: any) {
    if (!obj) {
      return null;
    }
    let preDate = this.loadDateObj(obj);
    let preYN = this.YNtoBoolObjectConvert(preDate);
    return preYN;
  }

  prepareSave(obj: any) {
    if (!obj) {
      return null;
    }
    let preDate = this.prepareDateObj(obj);
    let preBool = this.BooltoYNObjectConvert(preDate);
    return preBool;
  }

  getSsn(ssn: SocialSecurityNumber): string {
    let ssnString = '';
    if (!ssn) {
      return '';
    }
    if (ssn.soSecPart1) {
      ssnString += ssn.soSecPart1 + '-';
    }
    if (ssn.soSecPart2) {
      ssnString += ssn.soSecPart2 + '-';
    }
    if (ssn.soSecPart3) {
      ssnString += ssn.soSecPart3;
    }
    if (new RegExp(ConstantsService.ssnPattern).test(ssnString)) {
      return ssnString;
    }
    else {
      return '';
    }
  }

  getMaskedSsn(ssn: SocialSecurityNumber): string {
    let ssnString = '';
    if (!ssn) {
      return '';
    }
    if (ssn.soSecPart1) {
      ssnString += '***' + '-';
    }
    if (ssn.soSecPart2) {
      ssnString += '**' + '-';
    }
    if (ssn.soSecPart3) {
      ssnString += ssn.soSecPart3;
    }
    return ssnString;
  }

  phnMask(val) {
    let x = val.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
    if (x != null && x != '') {
      return '(' + x[1] + ') ' + x[2] + '-' + x[3];
    } else {
      return;
    }
  }

  datemsk(val) {
    let x = val.replace(/\D/g, '').match(/\d{4}\d{2}/);
    if (x != null && x != '') {
      return x[1] + '/ ' + x[2];
    } else {
      return;
    }
  }

  endDate(el): any {
    if (el == '12/31/9999') {
      return el = '';
    }
    else {
      return el;
    }
  }

  getSsnObjectFromString(input: string): SocialSecurityNumber {
    const ssn: SocialSecurityNumber = new SocialSecurityNumber();
    if (new RegExp('^[0-9]{9}$').test(input)) {
      ssn.soSecPart1 = input.substr(0, 3);
      ssn.soSecPart2 = input.substr(3, 2);
      ssn.soSecPart3 = input.substr(5, 4);
    }
    if (new RegExp(ConstantsService.ssnPattern).test(input)) {
      const splittedSsn: string[] = input.split('-');
      ssn.soSecPart1 = splittedSsn[0];
      ssn.soSecPart2 = splittedSsn[1];
      ssn.soSecPart3 = splittedSsn[2];
    }
    return ssn;
  }

  getWorkerId(val) {
    let workerId = val.split('@');
    let workerName = workerId[0].split('.');
    return workerName.firstName + ' ' + workerName.lastName;
  }

  getToFromVo<VO, TO>(input: VO, output: TO) {
    if (input) {
      if (typeof input === 'string' || typeof input === 'number') {
        if (output === undefined || typeof input === typeof output) {
          output = input as any as TO;
        }
      }
      else {
        for (const prop of Object.getOwnPropertyNames(input)) {
          if (output && output.hasOwnProperty(prop) && typeof output[prop] === typeof input[prop]) {
            if (/*Object.getOwnPropertyNames(input[prop]).length > 0 &&*/ typeof input[prop] === 'object') {
              if (Array.isArray(input[prop])) {
                output[prop] = [];
                for (const arrayElement of input[prop]) {
                  // initialize a new object of type output[prop] array's object type
                  let tempObject = this.getnewObject(arrayElement);
                  tempObject = this.getToFromVo(arrayElement, tempObject);
                  output[prop].push(tempObject);
                }
              }
              else {
                output[prop] = this.getToFromVo(input[prop], output[prop]);
              }
            }
            else {
              console.log(typeof input[prop])
              if (input[prop] instanceof Date) {
                const copy = this.systemDate;
                copy.setTime(input[prop].getTime());
                output[prop] = copy;
              }
              else {
                output[prop] = input[prop];
              }
            }
          }
          else {
            if (typeof input[prop] === 'string' || typeof input[prop] === 'number') {
              if (output[prop] === undefined || typeof input[prop] === typeof output[prop]) {
                output[prop] = input[prop] as any as TO;
              }
            }
            // TODO, see how to make new object of output n copy only relevent properties from input to output
          }
        }
      }
    }
    return output;
  }

  getnewObject<T>(input: T): any {
    // TODO , cant get class name, need to get empty object according to class of input object
    const className = input.constructor.name;
    console.log(typeof input);
    if (className === 'PersonName') {
      return new PersonName();
    }
    if (className === 'SocialSecurityNumber') {
      return new SocialSecurityNumber();
    }
    if (className === 'DobVerification') {
      return new ClientVerification();
    }
    if (className === 'ClientVerification') {
      return new ClientVerification();
    }
    if (className === 'ApiMessage') {
      return new ApiMessage();
    }
    // if (className === 'HouseholdVerification') {
    //   return new HouseholdVerification();
    // }
    return undefined;
  }

  padWithZero(value) {
    if (value < 10) {
      return '0' + value.toString();
    }
    return value;
  }

  changeDate(dob: any): any {
    let date: string[];
    let final: string[];
    if (dob) {
      if (dob.toString().indexOf(' ') > -1) {
        dob = this.padWithZero(dob.getMonth() + 1) + '/' + this.padWithZero(dob.getDate() - 1) + '/' + dob.getFullYear() as any;
      }
      else {
        final = dob.toString().split('T');
        date = final[0].split('-');
        if (date[0] && date[1] && date[2]) {
          dob = date[1] + '/' + date[2] + '/' + date[0] as any;
        }
      }
    }
    return dob;
  }

  changeDatewithTime(dob: any): any {
    let date: string[];
    let final: string[];
    if (dob) {
      if (dob.toString().indexOf(' ') > -1) {
        // tslint:disable-next-line:max-line-length
        dob = this.padWithZero(dob.getMonth() + 1) + '/' + this.padWithZero(dob.getDate() - 1) + '/' + dob.getFullYear() + ' ' + dob.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) as any;
      }
      else {
        final = dob.toString().split('T');
        date = final[0].split('-');
        if (date[0] && date[1] && date[2]) {
          dob = date[1] + '/' + date[2] + '/' + date[0] as any;
        }
      }
    }
    return dob;
  }

  changeDateFormat(el): string {
    if (el) {
      const date: any = el.split('-');
      return (date[1] + '/' + date[2] + '/' + date[0]);
    }
    return el;
  }

  changeDateFormatToMMDDYYYY(el): string {
    if (el) {
      const date = el.split('T');
      const dt = date[0].split('-');
      return (dt[1] + '-' + dt[2] + '-' + dt[0]);
    }
    return el;
  }

  changeDateFormatToMMDDYYYYSlash(el): string {
    if (el) {
      const date = el.split('T');
      const dt = date[0].split('-');
      return (dt[1] + '/' + dt[2] + '/' + dt[0]);
    }
    return el;
  }

  changeDateFormatSplitWithSpace(el): string {
    if (el) {
      const date = el.split(' ');
      return date[0];
    }
    return el;
  }

  checkDate(el: any): boolean {
    if (el) {
      let todayDate = this.systemDate;
      let createdDate = new Date(el);
      if (createdDate.getUTCDate() === todayDate.getUTCDate() &&
        createdDate.getUTCMonth() === todayDate.getUTCMonth() &&
        createdDate.getUTCFullYear() === todayDate.getUTCFullYear()) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  changeDateToMMDDYYYYHHMMAMformat(el: any) {
    if (el) {
      const datetime = el.split('T');
      const date: any = this.changeDateToMMDDYYYYFormat(datetime[0]);
      let localDateTime = new Date(el).toLocaleTimeString().split(':');
      if (localDateTime[2].indexOf('AM') === -1 && localDateTime[2].indexOf('PM') === -1) {
        let convertToAmPm;
        if (parseInt(localDateTime[0]) > 12) {
          convertToAmPm = 'pm';
        }
        else {
          convertToAmPm = 'am';
        }
        return date + ' ' + localDateTime[0] + ':' + localDateTime[1] + ' ' + convertToAmPm;
      }
      else {
        let convertToAmPm = localDateTime[2].split(' ');
        return date + ' ' + localDateTime[0] + ':' + localDateTime[1] + ' ' + convertToAmPm[1].toLowerCase();
      }
    }
    return undefined;
  }

  changeDateYYYYMMDD(dob: any): any {
    let final: string[];
    if (dob) {
      final = dob.toString().split('T');
      return final[0];
    }
    return dob;
  }

  changeDateYYYYMMDayOne(dob: any): any {
    let final: string[];

    if (dob) {
      final = dob.toString().split('T');
      final = final[0].toString().split('-');
      return (final[0] + '-' + final[1] + '-' + '01');
    }
    return dob;
  }

  changeDateToLastDay(dob: any): any {
    let final: string[];

    if (dob) {
      final = dob.toString().split('-');
      return (final[0] + '-' + final[1] + '-' + this.lastday(final[0], final[1]));
    }
    return dob;
  }

  lastday(y, m) {
    return new Date(y, m + 1, 0).getDate();
  }

  changeDateToMMDDYYYYFormatPregnancy(el): any {
    if (el) {
      console.log(el);
      const date: any = el.split('/');
      if (date.length > 1) {
        console.log(date[1]);
        const day = date[1].toString().split('T');
        return (date[0] + '/' + day[0] + '/' + date[2]);
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToMMDDYYYYFormat(el): any {
    if (el) {
      const date: any = el.split('-');
      if (date.length > 1) {
        return (date[1] + '/' + date[2] + '/' + date[0]);
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToMMYYYY(el): any {
    if (el) {
      const date: any = el.split('-');
      if (date.length > 1) {
        return (date[1] + '/' + '01' + '/' + date[0]);
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToFormatMMYYYY(el): any {
    if (el) {
      const date: any = el.split('-');
      if (date.length > 1) {
        return (date[0] + date[1]);
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToMMDDYYYYHHMMFormat(el): any {
    if (el) {
      const datetime = el.split('T');
      const date: any = datetime[0].split('-');

      if (datetime.length > 1) {
        const hhmm: any = datetime[1].split(':');
        return (date[1] + '/' + date[2] + '/' + date[0] + ' ' + hhmm[0] + ':' + hhmm[1]);
      }
      else {
        return date[1] + '/' + date[2] + '/' + date[0];
      }
    }
    return el;
  }

  changeDateToMMDDYYYYOnlDateFormat(el): any {
    if (el) {
      const datetime = el.split('T');
      const date: any = datetime[0].split('-');
      return date[1] + '/' + date[2] + '/' + date[0];
    }
    return el;
  }

  changeDateToMMDDYYYYMonth(el): any {
    if (el) {
      const datetime = el.split('T');
      const date: any = datetime[0].split('-');
      return date[0] + date[1];
    }
    return el;
  }

  changeDateToYYYYMMFormat(el): any {
    if (el) {
      const date: any = el.split('/');
      if (date.length > 1) {
        return (date[1] + '-' + date[0] + '-' + '01');
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToYYYYMM01Format(el): any {
    if (el) {
      const date: any = el.split('/');
      if (date.length > 1) {
        return (date[2] + '-' + date[0] + '-' + '01');
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToYYYYMMDDFormat(el): any {
    if (el) {
      const date: any = el.split('/');
      if (date.length >= 1) {
        return (date[2] + '-' + date[0] + '-' + date[1]);
      }
      else {
        return date[0];
      }
    }
    return el;
  }

  changeDateToYYYYMMDDHHMMFormat(el): any {
    if (el) {
      const datetime = el.split(' ');
      const date: any = datetime[0].split('/');
      const hhmm: any = datetime[1].split(':');
      if (datetime.length > 1) {
        return (date[2] + '-' + date[0] + '-' + date[1] + 'T' + hhmm[0] + ':' + hhmm[1] + ':00.000Z');
      }
      else {
        return datetime[0];
      }
    }
    return el;
  }

  removeTilda(el): any {
    if (el) {
      const words: any = el.split('~');
      if (words.length > 1) {
        return (words[1]);
      }
      else {
        return words[0];
      }
    }
    return el;
  }

  changeSpanishText(el): any {
    // if (el) {
    //   const spanText = el.split("'");

    // }
    if (el && el.search("'")) {
      // return el.replace(/^\s+|\s+$/g, "'").replace(/^\s+|\s+$/g, "''");
      return el = el.replace(/'/gi, "''");
    }
    // return el
  }
  // }

  compareDates(firstDate, secondDate) {
    const date: any = firstDate.split('-');
    const date2: any = secondDate.split('-');
    if (date.length > 1 && date2.length > 1) {
      if (date[0] < date2[0]) {
        return -1;
      }
      else if (date[0] == date2[0]) {
        if (date[1] <= date2[1]) {
          if (date[2] <= date2[2]) {
            return -1;
          }
          else {
            return 1;
          }
        }
      }
      else {
        return 1;
      }
    }
    return 0;
  }

  changeDateToLocalFromNewDateFormat(el: any): any {
    if (el) {
      if (el instanceof Date) {
        let dt: any = el.toLocaleDateString();
        if (dt && dt.search('/')) {
          return dt;
        }
      }
      else {
        let cData = el.split('-');
        return cData[1] + '/' + cData[2] + '/' + cData[0];
      }
    }
    return el;
  }

  getCurrentDateToInterview(): any {
    let currentDate: Date = this.systemDate;
    if (currentDate) {
      if (currentDate.toString().indexOf(' ') > -1) {
        currentDate = this.padWithZero(currentDate.getMonth() + 1) + '/' +
          this.padWithZero(currentDate.getDate()) + '/' + currentDate.getFullYear() as any;
      }
    }
    return currentDate;
  }

  changeDateToMMYYYYFormat(el: any): any {
    if (el) {
      const modifiedDate = el.split('-');
      return modifiedDate[1] + '/' + modifiedDate[0];
    }
    return '';
  }

  convertEpochTimeToDate(el: any): any {
    if (el) {
      var date = new Date(el);
      return this.changeDateYYYYMMDD(date.toISOString());
    }
    return '';
  }

  addPlaceHolder(classNme: string, placeHolder: string) {
    if (classNme && placeHolder) {
      let elements = document.getElementsByClassName(classNme);
      if (elements && elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].setAttribute('placeholder', placeHolder);
          // console.log(elements[i].setAttribute('placeholder', placeHolder));
        }
      }
    }
  }

  getRace(raceCds: string[]): string {
    let races = '';
    if (raceCds.length > 0) {
      races += raceCds[0];
      /*let counter: number = 0;
      for (let entry of this.constantsService.getLookups()[0].localizedLookups['Race'].beans) {
          if (entry.lookupBean.code == raceCds[counter]) {
              races += ' ' + entry.lookupBean.shortLabel;
              counter ++;
          }
      }*/
    }
    return races;
  }

  getHeadOfHousehold(): any {
    const currentHouseholdMembers = this.constantsService.getCurrentHousehold();
    if (currentHouseholdMembers && currentHouseholdMembers.length > 0) {
      for (const householdMember of currentHouseholdMembers) {
        if (householdMember.headOfHouseholdYN === 'Y') {
          return householdMember;
        }
      }
    } else {
      let members = ConstantsService.householdDetails;
      if (members && members.length > 0) {
        for (const householdMember of members) {
          if (householdMember.headOfHouseholdYN === 'Y') {
            return householdMember;
          }
        }
      }
    }
  }

  extendSession() {
    if (this.storage.getTimer() && (this.storage.getTimer().min >= environment.timeOut - 2 && this.storage.getTimer().min < environment.timeOut - 1)) {
      this.emitter.broadcast('timer', null);
      this.extendTime();
    }
  }

  extendTime() {
    this.apiManager.fetchData('accessSecurity', undefined,
      ApiManager.GET, undefined, undefined,
      undefined, undefined).subscribe(
        res => {
          this.storage.setModalOpen(false);
        }
      );
  }

  getMinimumDateComparingDOB(ind): any {
    if (ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
      ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(hhm => {
        if (hhm.individualId == ind) {
          if (hhm.dob) {
            this.minDate = new Date(hhm.dob);
          }
        }
      }
      )
    }
  }

  retrieveHeadOfHousehold(): any {
    for (const householdMember of ConstantsService.humanServiceModel.benefitsCase.householdMembers) {
      if (householdMember.headOfHouseholdYN && householdMember.headOfHouseholdYN === 'Y') {
        console.log('householdMember');
        console.log(householdMember);
        return householdMember;
      }
    }
    console.log('123undefined');
    return undefined;
  }

  getCaseId(): String {
    if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase &&
      ConstantsService.humanServiceModel.benefitsCase.caseId !== '' &&
      ConstantsService.humanServiceModel.benefitsCase.caseId !== undefined) {
      return ConstantsService.humanServiceModel.benefitsCase.caseId;
    }
    else {
      if (localStorage.getItem('splCaseID'))
        return localStorage.getItem('splCaseID');
      return '';
    }
  }

  getCaseStatus() {
    if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase && ConstantsService.humanServiceModel.benefitsCase.caseStatusCode) {
      return this.getLonglabelFromLookUp(ConstantsService.humanServiceModel.benefitsCase.caseStatusCode, 'CaseStatusCode');
    }
    else {
      return '';
    }
  }

  getScreen(moduleName) {
    switch (moduleName) {
      case 'INQUIRY_MODULE':
        return 'Inquiry';
      case 'SCREENING_MODULE':
        return 'screening';
      case 'INTERVIEW_MODULE':
        return 'caseInquiry';
      case 'SETTINGS_MODULE':
        return 'settings';
      case 'FINALIZE_MODULE':
        return 'caseInquiry';
      case 'CASE_NARRATIVE_MODULE':
        return 'caseNarrative';
      case 'CHANGE_REPORTING_MODULE':
        return 'changeReporting';
      case 'REDETERMINATION_MODULE':
        return 'redetermination';
      case 'CORRESPONDENCE_MODULE':
        return 'Correspondence';
      case 'CASE_HOME_MODULE':
        return 'caseInquiry';
      case 'BENEFIT_ISSUE_MODULE':
        return 'benefitIssue';
      case 'BENEFIT_RECOVERY_MODULE':
        return 'benefitRecovery';
    }
  }

  changedStatusOnDefault(id: any, childScreen: Boolean = false) {
    const str: any = this.shareService.getDataFromMap('currentModule');
    const caseStatus: any = this.getCaseStatus();
    if (id) {
      console.log('id from apputil, changedStatusOnDefault', id);
      if (!childScreen) { this.curId = id; }
      this.curModule = this.constantsService.headTitle[str][id];
      let cId: any;
      let cMode: any = this.constantsService.getCaseMode();
      if (this.curModule) {
        this.curScreen = this.getScreen(str);
        if (cMode !== '' && str !== 'CASE_NARRATIVE_MODULE' && str !== 'INQUIRY_MODULE' && !this.curModule.includes('Initiate')) {
          ConstantsService.headerTitle = cMode + this.curModule;
        }
        else {
          if (str === 'LINKCP_MODULE') {
            ConstantsService.headerTitle = 'Link and Delink Cases';
          }
          else if (str === 'SCREENING_MODULE') {
            ConstantsService.headerTitle = 'Application Registration: ' + this.curModule;
          }
          else if (str === 'INTERVIEW_MODULE') {
            ConstantsService.headerTitle = 'Eligibility Determination: ' + this.curModule;
          }
          else if (str === 'SETTINGS_MODULE') {
            ConstantsService.headerTitle = 'System Settings: ' + this.curModule;
          }
          else if (str === 'FINALIZE_MODULE') {
            ConstantsService.headerTitle = 'Finalize: ' + this.curModule;
          }
          else if (str === 'BENEFIT_ISSUE_MODULE') {
            ConstantsService.headerTitle = 'Benefit Issuance: ' + this.curModule;
          }
          else if (str === 'BENEFIT_RECOVERY_MODULE') {
            ConstantsService.headerTitle = 'Benefit Recovery: ' + this.curModule;
          }
          else if (str === 'CASE_NARRATIVE_MODULE') {
            ConstantsService.headerTitle = this.curModule;
          }
          else if (str === 'INQUIRY_MODULE') {
            if (this.curModule && !this.curModule.trim()) {
              ConstantsService.headerTitle = 'Inquiry';
            }
            else {
              ConstantsService.headerTitle = 'Inquiry: ' + this.curModule;
            }
          }
          else if (str === 'CHANGE_REPORTING_MODULE') {
            if (this.curModule && !this.curModule.trim()) {
              ConstantsService.headerTitle = 'Change Reporting: ';
            }
            else {
              ConstantsService.headerTitle = 'Change Reporting: ' + this.curModule;
            }
          }
          else if (str === 'REDETERMINATION_MODULE') {
            if (this.curModule && !this.curModule.trim()) {
              ConstantsService.headerTitle = 'Redetermination: ';
            }
            else {
              ConstantsService.headerTitle = 'Redetermination: ' + this.curModule;
            }
          }
          else if (str === 'CORRESPONDENCE_MODULE') {
            if (this.curModule && !this.curModule.trim()) {
              ConstantsService.headerTitle = 'Correspondence: ';
            }
            else {
              ConstantsService.headerTitle = 'Correspondence: ' + this.curModule;
            }
          }
          else if (str === 'CASE_HOME_MODULE') {
            // ConstantsService.headerTitle = 'Case Home';
            ConstantsService.headerTitle = 'Eligibility Determination: ' + this.curModule;
          }
        }
        cId = this.getCaseId();
        if (id === 'initStatus' || id === 'intStatus' || id === 'correspondence' || id === 'initiateVendor'
          || id === 'holidays' || id === 'eligibilityParameter' || id === 'districtOffice' || id === 'districtOfficeChange') {
          ConstantsService.headerClientIdTitle = '';
          ConstantsService.headerClientIdTitle2 = '';
          ConstantsService.headerCaseStatus = '';
        }
        else if (str === 'INQUIRY_MODULE') {
          cId = '';
          ConstantsService.headerClientIdTitle = '';
          ConstantsService.headerClientIdTitle2 = '';
          ConstantsService.headerCaseStatus = '';
        }
        else {
          if (cId !== '' && cId !== undefined && str !== 'LINKCP_MODULE') {
            ConstantsService.headerClientIdTitle = 'Case ID: ' + cId;
          }
          else {
            if (str === 'SCREENING_MODULE' || str === 'CASE_NARRATIVE_MODULE') {
              ConstantsService.headerClientIdTitle = 'Case ID: ' + '<New Case>';
            }
            else {
              ConstantsService.headerClientIdTitle = 'Case ID: ';
            }
          }
          if (ConstantsService.headerTitle.trim() === 'Finalize: Eligibility') {
            ConstantsService.headerTitle = 'Finalize: ' + 'Medical Eligibility';
          }
          if (str !== 'CASE_NARRATIVE_MODULE' && str !== 'INQUIRY_MODULE') {
            if (this.getHeadOfHousehold() === undefined) {
              if (str === 'SCREENING_MODULE' && (id === 'clientStatus' || id === 'customerDetails')) {
                ConstantsService.headerClientIdTitle2 = '';
              }
              else {
                ConstantsService.headerClientIdTitle2 = 'Head Of Household: ';
              }
            }
            else if (this.getHeadOfHousehold() !== undefined && str !== 'SETTINGS_MODULE') {
              ConstantsService.headerClientIdTitle2 = 'Head Of Household: ' +
                this.getFullName(this.getHeadOfHousehold().personName) + ' ' +
                this.getAge(this.getHeadOfHousehold().dob) + ' ' + this.getHeadOfHousehold().gender;
            }
          }
          if (caseStatus !== '' && caseStatus !== undefined) {
            ConstantsService.headerCaseStatus = 'Case Status: ' + caseStatus;
          }
          else {
            ConstantsService.headerCaseStatus = '';
          }
        }
      }
    }
  }

  changedStatus(id: string, bool1: Boolean) {
    if (bool1) {
      this.changedStatusOnDefault(id);
    }
    for (let i of this.constantsService.navigation[this.curScreen]) {
      if (i.id === id) {
        // i.status = true;
        i.status = this.getNavStatus(id);
        break;
      }
    }
  }

  revertStatus() {
    for (let i of this.constantsService.navigation[this.curScreen]) {
      i.status = false;
    }
  }

  formatAddress(address: Address, includeCounty: boolean = true): any {
    let fullAddress: string = undefined;
    if (!address) {
      return '';
    }
    if (address.addressLine1) {
      if (fullAddress) {
        fullAddress += ', ' + address.addressLine1;
      }
      else {
        fullAddress = address.addressLine1;
      }
    }
    if (address.addressLine2) {
      if (fullAddress) {
        fullAddress += ', ' + address.addressLine2;
      }
      else {
        fullAddress = address.addressLine2;
      }
    }
    if (address.city) {
      if (fullAddress) {
        fullAddress += ', ' + address.city;
      }
      else {
        fullAddress = address.city;
      }
    }
    if (address.state) {
      if (address.state.length <= 2) {
        this.statesDropdown = this.constantsService.getDropDownFromLookup(this.constantsService.getText('State'));
        this.statesDropdown.forEach(element => {
          if (element.lookupBean.code === address.state) {
            if (fullAddress) {
              fullAddress += ', ' + element.lookupBean.longLabel;
            }
            else {
              fullAddress = element.lookupBean.longLabel;
            }
          }
        });
      }
      else {
        fullAddress += ', ' + address.state;
      }
    }
    if (address.zipcode) {
      if (fullAddress) {
        fullAddress += ', ' + address.zipcode;
      }
      else {
        fullAddress = address.zipcode;
      }
    }
    if (address.county && includeCounty && fullAddress) {
      let county = this.getLonglabelFromLookUp(address.county, 'County');
      if (county != '' && county != null) {
        if (fullAddress) {
          fullAddress += ', ' + county;
        }
      }
    }
    /* if (address.district) {
        fullAddress += ', ' + address.district;
    } */
    return fullAddress;
  }

  getYN(data: any): string {
    return (data === true || (typeof data === 'string' && (
      data.toLowerCase() === 'true' || data.toLowerCase() === 'y' || data.toLowerCase() === 'yes')
    )
    ) ? 'Y' : 'N';
  }

  getAge(dateString) {
    let today = this.getCurrentDate();
    let birthDate = new Date(!AppUtilService.isNil(dateString) ? moment(dateString).format('YYYY/MM/DD') : '');
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  showLoader() {
    ConstantsService.loaderFlag = true;
  }

  hideLoader() {
    ConstantsService.loaderFlag = false;
  }

  loadDatePicker() {
    $('.date-picker').datepicker(
      {
        dateFormat: 'mm/yy',
        changeMonth: true,
        changeYear: true,
        yearRange: 'c-100:c+100',
        showButtonPanel: true,
        onClose: function (dateText, inst) {
          function isDonePressed() {
            return ($('#ui-datepicker-div').html()
              .indexOf('ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
          }
          if (isDonePressed()) {
            const month = $('#ui-datepicker-div .ui-datepicker-month :selected').val();
            const year = $('#ui-datepicker-div .ui-datepicker-year :selected').val();
            $(this).datepicker('setDate', new Date(year, month, 1)).trigger('change');

            $('.date-picker').focusout()// Added to remove focus from datepicker input box on selecting date
          }
        },
        beforeShow: function (input, inst) {
          inst.dpDiv.addClass('month_year_datepicker');
          let datestr;
          if ((datestr = $(this).val()).length > 0) {
            const year = datestr.substring(datestr.length - 4, datestr.length);
            const month = datestr.substring(0, 2);
            $(this).datepicker('option', 'defaultDate', new Date(year, month - 1, 1));
            $(this).datepicker('setDate', new Date(year, month - 1, 1));
            $('.ui-datepicker-calendar').hide();
          }
        }
      })
  }

  getMenu(screenName, routeName) {
    return this.constantsService.navigation[screenName].filter((v, i) => {
      if (v.routerPath.toString() === routeName.toString()) {
        v.indexNum = i;
        return v;
      }
    });
  }

  showLeftMenuComponent(screenName: any, index: number) {
    let isEAPresent: boolean = false;
    if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase &&
      ConstantsService.humanServiceModel.benefitsCase.eligibility &&
      ConstantsService.humanServiceModel.benefitsCase.eligibility.programs) {
      for (let item of ConstantsService.humanServiceModel.benefitsCase.eligibility.programs) {
        if (item.programName == 'EA') {
          isEAPresent = true;
          break;
        }
      }
    }
    if (isEAPresent == false) {
      this.constantsService.navigation[screenName][index].visible = false;
    }
    else {
      this.constantsService.navigation[screenName][index].visible = true;
    }
  }

  getLonglabelFromLookUp(code: string, lookupKey: string) {
    const lookup: LocalizedLookupBean[] = this.constantsService.getDropDownFromLookup(this.constantsService.getText(lookupKey));
    let longLabel: string = '';
    lookup.forEach(element => {
      if (element.lookupBean.code == code || element.lookupBean.longLabel == code) {
        longLabel = element.lookupBean.longLabel;
      }
    });
    return longLabel;
  }

  getNameBaseOnHhmID(individualId: string) {
    let fullname: string = '';
    if (individualId) {
      ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(hhm => {
        if (hhm.individualId ? hhm.individualId.toString() === individualId.toString() : false) {
          fullname = hhm.personName.firstName + ' ' + hhm.personName.lastName;
        }
      })
    }
    return fullname;
  }
  getDobOBaseOnHhmId(individualId: string) {
    let dob: any = '';
    ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(hhm => {
      if (hhm.individualId === individualId) {
        dob = hhm.dob;
      }
      return dob;
    })
  }

  validateBirthDate(individualId: string) {
    let today = this.systemDate;
    let birthDate: Date;
    ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(hhm => {
      if (hhm.individualId == individualId) {
        birthDate = hhm.dob;
      }
      return birthDate;
    })
    let a = new Date(birthDate);
    let age = today.getFullYear() - a.getFullYear();
    let m = today.getMonth() - a.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < a.getDate())) {
      age--;
    }
    return age;
  }

  changeSsnOnChange(formControl, event: any) {
    if (formControl) {
      formControl.patchValue(event.target.value);
    }
  }

  removeExtraSpaces(str: String): String {
    if (str) {
      return str.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
    }
    return null;
  }

  compareBaseAmount(a, b) {
    const sortA = a.sortOrder;
    const sortB = b.sortOrder;

    let comparison = 0;
    if (sortA - sortB > 0) {
      comparison = 1;
    }
    else if (sortA - sortB < 0) {
      comparison = -1;
    }
    else {
      return 0;
    }
    return comparison;
  }

  calculateAge(dob: Date) {
    if (dob) {
      let date: Date = this.systemDate;
      let birth = new Date(dob);

      let currYear = date.getFullYear();
      let currMonth = date.getMonth();
      let currDate = date.getDate();

      let birthYear = birth.getFullYear();
      let birthMonth = birth.getMonth();
      let birthDate = birth.getDate();

      let age = currYear - birthYear;
      if (birthMonth > currMonth) {
        age--;
      }
      if ((birthMonth == currMonth) && (birthDate > currDate)) {
        age--;
      }
      return age;
    }
  }

  setApplicationSteps() {
    if (ConstantsService.humanServiceModel
      // && ConstantsService.humanServiceModel.benefitsCase.steps
    ) {
      // this.setDocsStatusForCase(); //to pre set  the casedocument status
      // this.setSpouseResourceAssesment(); //to pre set the casedocument status
      if (
        // ConstantsService.humanServiceModel.benefitsCase.steps.screeningSteps &&
        // (ConstantsService.humanServiceModel.benefitsCase.steps.screeningSteps.screeningDispositionComplete)
        ConstantsService.humanServiceModel.benefitsCase &&
        ConstantsService.humanServiceModel.benefitsCase.caseStatusCode &&
        ConstantsService.humanServiceModel.benefitsCase.caseStatusCode != 'IP'
      ) {
        // this.shareService.addDataToMap('currentModule', this.constantsService.getText('interviewModule'));
        this.shareService.addDataToMap('currentModule', 'INTERVIEW_MODULE');
        this.storage.setCurrentModule('interview');
        this.curScreen = 'caseInquiry';
        const caseStatus: any = this.getCaseStatus();
        // this._utilService.changedStatusOnDefault('progStatus');
        if (ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.steps &&
          ConstantsService.humanServiceModel.benefitsCase.steps.interviewSteps) {

          let routingPoint = ConstantsService.humanServiceModel.benefitsCase.steps.interviewSteps;
          // if (routingPoint.summaryComplete) {
          this.changedStatus('caseHome', false);
          this.changedStatus('intakeClientStatus', false);
          this.changedStatus('contactInfoStatus', false);
          this.changedStatus('caseDocuments', false);
          this.changedStatus('programRequestSummary', false);
          this.changedStatus('houseStatus', false);
          this.changedStatus('taxInfoStatus', false);
          this.changedStatus('financialStatus', false);
          this.changedStatus('eaStatus', false);
          this.changedStatus('intake_spousal_resource', false);
          this.changedStatus('intake_admin_info', false);
          this.changedStatus('intake_verification_checklist', false);
          this.changedStatus('intake_errors_summary', false);
          this.setApplicationStepsForEligibility();
          // this.router.navigate(['/dashboard/interview/summary']);
          /*  } else if (routingPoint.administrativeInformationComplete) {
              this.changedStatus('caseHome', false);
              this.changedStatus('intakeClientStatus', false);
              this.changedStatus('contactInfoStatus', false);
              this.changedStatus('caseDocuments', false);
              this.changedStatus('programRequestSummary', false);
              this.changedStatus('houseStatus', false);
              this.changedStatus('taxInfoStatus', false);
              this.changedStatus('financialStatus', false);
              this.changedStatus('eaStatus', false);
              this.changedStatus('intake_spousal_resource', false);
              this.changedStatus('intake_admin_info', false);
              // this.router.navigate(['/dashboard/interview/verificationChecklist']);'
            } else if (routingPoint.emergencyAssitanceInformationComplete) {
              this.changedStatus('caseHome', false);
              this.changedStatus('intakeClientStatus', false);
              this.changedStatus('contactInfoStatus', false);
              this.changedStatus('caseDocuments', false);
              this.changedStatus('programRequestSummary', false);
              this.changedStatus('houseStatus', false);
              this.changedStatus('taxInfoStatus', false);
              this.changedStatus('financialStatus', false);
              this.changedStatus('eaStatus', false);
              // this.router.navigate(['/dashboard/interview/adminInformation']);
            } else if (routingPoint.financialInformationComplete) {
              this.changedStatus('caseHome', false);
              this.changedStatus('intakeClientStatus', false);
              this.changedStatus('contactInfoStatus', false);
              this.changedStatus('caseDocuments', false);
              this.changedStatus('programRequestSummary', false);
              this.changedStatus('houseStatus', false);
              this.changedStatus('taxInfoStatus', false);
              this.changedStatus('financialStatus', false);
              // this.router.navigate(['/dashboard/interview/financialInformation']);
            } else if (routingPoint.householdInformationComplete) {
              this.changedStatus('caseHome', false);
              this.changedStatus('intakeClientStatus', false);
              this.changedStatus('contactInfoStatus', false);
              this.changedStatus('caseDocuments', false);
              this.changedStatus('programRequestSummary', false);
              this.changedStatus('houseStatus', false);
              // this.router.navigate(['/dashboard/interview/financialInformation']);
            } else if (routingPoint.taxInformationComplete) {
              this.changedStatus('caseHome', false);
              this.changedStatus('intakeClientStatus', false);
              this.changedStatus('contactInfoStatus', false);
              this.changedStatus('caseDocuments', false);
              this.changedStatus('programRequestSummary', false);
              this.changedStatus('houseStatus', false);
              this.changedStatus('taxInfoStatus', false);
              // this.router.navigate(['/dashboard/interview/financialInformation']);
            }
            // else if (routingPoint.programRequestComplete) {
            //   this.changedStatus('intStatus', true);
            //   this.changedStatus('progStatus', true);
            //   this.changedStatus('contactInfoStatus', true);
            //   this.changedStatus('programRequestSummary',true);
            //   this.router.navigate(['/dashboard/interview/programRequestSummary']);
            // }
            else if (routingPoint.contactInformationComplete) {
              this.changedStatus('caseHome', false);
              this.changedStatus('intakeClientStatus', false);
              this.changedStatus('contactInfoStatus', false);
              // this.router.navigate(['/dashboard/interview/caseDocuments']);
            } else if (routingPoint.programStatusComplete) {
              this.changedStatus('intStatus', false);
              this.changedStatus('progStatus', false);
              this.changedStatus('programRequestSummary', false);
              // this.router.navigate(['/dashboard/interview/contactInformation']);
            }
            // else if (routingPoint.clientRegistrationComplete) {
            //   this.changedStatus('intStatus', true);
            //   this.changedStatus('progStatus', true);
            //   this.changedStatus('clientStatus',true);
            //   this.router.navigate(['/dashboard/interview/contactInformation']);
            // }
            */
          this.changedStatus('caseHome', true);
          // const caseStatus: any = this.getCaseStatus();
          this.router.navigate(['/dashboard/caseInquiry/caseHome']);
        }
        else {
          this.changedStatus('caseHome', true);
          const caseStatus: any = this.getCaseStatus();
          this.router.navigate(['/dashboard/caseInquiry/caseHome']);
        }
        if (caseStatus !== '' && caseStatus !== undefined) {
          ConstantsService.headerCaseStatus = 'Case Status: ' + caseStatus;
        }
        else {
          ConstantsService.headerCaseStatus = '';
        }
      }
      else if (ConstantsService.humanServiceModel.benefitsCase.caseStatusCode != '') {
        this.shareService.addDataToMap('currentModule', 'SCREENING_MODULE');
        this.storage.setCurrentModule('screening');
        this.curScreen = 'screening';
        if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.steps &&
          ConstantsService.humanServiceModel.benefitsCase.steps.screeningSteps) {
          let routingPoint = ConstantsService.humanServiceModel.benefitsCase.steps.screeningSteps;
          if (routingPoint.screeningDispositionComplete) {
            this.changedStatus('initStatus', false);
            this.changedStatus('programSelection', false);
            this.changedStatus('clientStatus', false);
            this.changedStatus('contactStatus', false);
            this.changedStatus('caseDocuments', false);
            this.changedStatus('screeningStatus', false);
            this.router.navigate(['/dashboard/screening/screeningDisposition']);
          }
          else if (routingPoint.caseDocumentComplete) {
            this.changedStatus('initStatus', false);
            this.changedStatus('programSelection', false);
            this.changedStatus('clientStatus', false);
            this.changedStatus('contactStatus', false);
            this.changedStatus('caseDocuments', false);
            this.router.navigate(['/dashboard/screening/screeningDisposition']);
          }
          // else if(routingPoint.authrepComplete) {
          //   this.changedStatus('initStatus', false);
          //   this.changedStatus('programSelection', false);
          //   this.changedStatus('clientStatus', false);
          //   this.changedStatus('contactStatus', false);
          //   this.changedStatus('caseDocuments', false);
          //   this.changedStatus('authrep',false);
          // }
          else if (routingPoint.contactInformationComplete) {
            this.changedStatus('initStatus', false);
            this.changedStatus('programSelection', false);
            this.changedStatus('clientStatus', false);
            this.changedStatus('contactStatus', false);
            this.router.navigate(['/dashboard/screening/caseDocuments']);
          }
          else if (routingPoint.registrationComplete) {
            this.changedStatus('initStatus', false);
            this.changedStatus('clientStatus', false);
            this.changedStatus('programSelection', false);
            this.router.navigate(['/dashboard/screening/contactInformationV1']);
          }
          else if (routingPoint.householdInformationComplete) {
            this.changedStatus('initStatus', false);
            this.changedStatus('programSelection', false);
            this.router.navigate(['/dashboard/screening/customerRegisterationAddHouseholdMembersComponent']);
          }
          else {
            this.changedStatus('initStatus', false);
            this.router.navigate(['/dashboard/screening/programSelection']);
          }
        }
        else {
          this.changedStatus('initStatus', false);
          this.router.navigate(['/dashboard/screening/programSelection']);
        }
      }
    }
    else {
      for (let navs of this.constantsService.navigation.interview) {
        navs.status = false;
      }
      for (let i of this.constantsService.navigation.caseInquiry) {
        i.status = false;
      }
      this.changedStatus('caseHome', true);
    }
  }

  getNavStatus(navId: string) {
    let navStatus: boolean = false;

    switch (navId) {
      case 'caseHome':
        navStatus = true;
        break;
      case 'clientStatus':
      case 'intakeClientStatus':
        navStatus = true;
        break;
      case 'contactInfoStatus':
        //ConstantsService.humanServiceModel.benefitsCase.contactInformation
        navStatus = true;
        break;
      case 'caseDocuments':
        navStatus = this.setDocsStatusForCase();
        break;
      case 'programRequestSummary':
        if (ConstantsService.humanServiceModel &&
          ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.selectedPrograms) {
          navStatus = true;
        }
        break;
      case 'houseStatus':
        if (ConstantsService.humanServiceModel &&
          ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.length > 0) {
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(ele => {
            if (ele.headOfHouseholdYN == 'Y') {
              if ((ele.individualDetails && ele.individualDetails.livingArrangements)
                && (ele.citizenshipImmigration && ele.citizenshipImmigration.usCitizenshipStatus)
                // || (ele.studentInformation && ele.studentInformation.studentStatuses  )
                // || (ele.disabilities)
              ) {
                navStatus = true;
              }
            }
          });
        }
        break;
      case 'taxInfoStatus':
        if (ConstantsService.humanServiceModel &&
          ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.length > 0) {
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(ele => {
            if (ele.headOfHouseholdYN == 'Y' && ele.taxFilingStatuses) {
              navStatus = true;
            }
          });
        }
        break;
      case 'financialStatus':
        if (ConstantsService.humanServiceModel &&
          ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.length > 0) {
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(ele => {
            if (ele.headOfHouseholdYN == 'Y') {
              if ((ele.assets && ele.assets.length > 0)
                || (ele.earnedIncomes && ele.earnedIncomes.length > 0)
                || (ele.unearnedIncomes && ele.unearnedIncomes.length > 0)
                || (ele.appliedUnearnedIncomes && ele.appliedUnearnedIncomes.length > 0)
                || (ele.shelterExpenses && ele.shelterExpenses.length > 0)
                || (ele.utilityExpenses && ele.utilityExpenses.length > 0)
                || (ele.medicalExpenses && ele.medicalExpenses.length > 0)) {
                navStatus = true;
              }
            }
          });
        }
        break;
      case 'eaStatus':
        if (ConstantsService.humanServiceModel &&
          ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.emergencyAssistances) {
          navStatus = true;
        }
        break;
      case 'intake_spousal_resource':
        // if(this.setSpouseResourceAssesment()){
        //   navStatus = true;
        // }
        navStatus = this.setSpouseResourceAssesment();
        break;
      case 'intake_admin_info':
        if (ConstantsService.humanServiceModel &&
          ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.programAdministrativeInformation) {
          navStatus = true;
        }
        break;
      case 'intake_verification_checklist':
        if (ConstantsService.humanServiceModel.benefitsCase
          && ConstantsService.humanServiceModel.benefitsCase['verificationOuts']
          && ConstantsService.humanServiceModel.benefitsCase['verificationOuts'].length == 0) {
          navStatus = true;
        }
        else if (ConstantsService.humanServiceModel &&
          ConstantsService.humanServiceModel.benefitsCase &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers &&
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.length > 0) {
          ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(ele => {
            if (ele.headOfHouseholdYN == 'Y'
              && (ele.soSec && ele.soSec.ssnVerification)
              && (ele.dobVerification)
              && (ele.individualDetails && ele.individualDetails.livingArrangements)
              && (ele.citizenshipImmigration && ele.citizenshipImmigration.lawfulPresenceVerification)
            ) {
              navStatus = true;
            }
          })
        }
        break;
      case 'intake_errors_summary':
        navStatus = true;
        break;
      default:
        navStatus = true;
        break;
    }
    return navStatus;
  }

  setApplicationStepsForEligibility() {
    let determineEligibilityStatus = false;
    if (ConstantsService.humanServiceModel.benefitsCase.eligibility &&
      ConstantsService.humanServiceModel.benefitsCase.eligibility.programs &&
      ConstantsService.humanServiceModel.benefitsCase.eligibility.programs.length > 0) {
      for (let programToBeAdded of ConstantsService.humanServiceModel.benefitsCase.eligibility.programs) {
        if (!programToBeAdded.scrEligibilityRunTimestamp) {
          determineEligibilityStatus = true;
        }
      }
    }

    if (determineEligibilityStatus) {
      this.changedStatus('history', true);
      this.changedStatus('benefitsSummary', true);
      this.changedStatus('medElig', true);
    }
  }

  setSpouseResourceAssesment() {

    // const queryParams: string[] = [];
    // queryParams.push(ConstantsService.humanServiceModel.benefitsCase.caseId);
    // this.apiManager.fetchData('getSRAssessment', undefined, ApiManager.GET,
    //   undefined, undefined, undefined, queryParams)
    //   .subscribe(res => {
    //     if(res.spousalResourceAssessments){
    //       this.isCasehasSpousalResourceAssessments = true;
    //       // this.changedStatus('intake_spousal_resource', true);
    //     }
    //   },
    //     error => {
    //       console.log('getSpousalAssessment API call failure: ' + error);
    //     });
    if (ConstantsService.humanServiceModel && ConstantsService.humanServiceModel.benefitsCase &&
      ConstantsService.humanServiceModel.benefitsCase.steps && ConstantsService.humanServiceModel.benefitsCase.steps.interviewSteps &&
      !this.isCasehasSpousalResourceAssessments) {
      this.isCasehasSpousalResourceAssessments = ConstantsService.humanServiceModel.benefitsCase.steps.interviewSteps.spousalResourceComplete;
    }

    return this.isCasehasSpousalResourceAssessments;
  }

  setDocsStatusForCase() {

    // const queryParams: string[] = [];
    // queryParams.push(ConstantsService.humanServiceModel.benefitsCase.caseId);
    // this.apiManager.fetchData(
    //     'documentsForACaseUrl',
    //     undefined,
    //     ApiManager.GET,
    //     undefined,
    //     undefined,
    //     undefined,
    //     queryParams).subscribe(res => {
    //       if(res.length>0){
    //         // this.changedStatus('caseDocuments', true);
    //         this.isCasehasDocument=true;
    //         return true;
    //       }

    //     });

    //   return false;

    if (ConstantsService.humanServiceModel &&
      ConstantsService.humanServiceModel.benefitsCase &&
      ConstantsService.humanServiceModel.benefitsCase.steps &&
      ConstantsService.humanServiceModel.benefitsCase.steps.screeningSteps &&
      !this.isCasehasDocument) {
      this.isCasehasDocument = ConstantsService.humanServiceModel.benefitsCase.steps.screeningSteps.caseDocumentComplete;
    }
    return this.isCasehasDocument;
  }

  formatStartEndDate(date: any) {
    let payMonth: String = JSON.stringify(date);
    let displayDate = '';
    displayDate = payMonth.substring(0, 4) + "-" + payMonth.substring(4, date.length);
    const dateToShow: any = displayDate.split('-');
    if (displayDate.length > 1) {
      return (dateToShow[1] + '-' + dateToShow[0]);
    }
    return displayDate;
  }

  formatMonthDate(date: string) {
    return date.substring(0, 2) + "-" + date.substring(2, date.length);
  }

  ssnMaskingCustom(ssn) {
    if (ssn != null) {
      return ssn.substr(0, 3) + '-' + ssn.substr(3, 2) + '-' + ssn.substr(5, 4);
    }
  }

  getCaseMode(val) {
    if (val === 'IC') {
      return 'Change Reporting: ';
    }
    else if (val === 'RD') {
      return 'Redetermination: ';
    }
    else if (val === 'IN') {
      return 'Intake: ';
    }
  }

  changeDateToMaterialLanguage(val) {
    if (val) {
      val = this.changeDateToYYYYMMDDFormat(val);
      val = val.toString().split('-');
      const valMonth = parseInt(val[1]);
      const valDate = parseInt(val[2]);
      const valYear = parseInt(val[0]);
      return new Date(valYear.toString() + '-' + valMonth.toString() + '-' + valDate.toString());
    }
  }

  prepareDateObjCp(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        if (Array.isArray(copy[key])) {
          copy[key] = copy[key].map(nestCopy => this.prepareDateObj(nestCopy));
        } else if (typeof copy[key] == 'object') {
          copy[key] = this.prepareDateObj(copy[key]);
        } else if (key.endsWith('Date') || key === 'monthAndYear' || key === 'date' || key === 'effectiveBeginDt' || key === 'effectiveEndDt' || key === 'anticipatedReceiptDt' || key === 'dateOfBirth' || key === 'beginDt' || key === 'endDt') {
          copy[key] = copy[key] == '' ? null : this.changeDateToYYYYMMDDFormat(copy[key]);
        }
      }
    }
    return copy;
  }

  prepareObj(obj: any) {
    let copy = JSON.parse(JSON.stringify(obj));
    for (let key in copy) {
      if (copy.hasOwnProperty(key)) {
        copy[key] = copy[key] == null ? '' : copy[key];
      }
    }
    return copy;
  }
  public dateOnly(event: any) {
    // return event.keyCode >= 47 && event.keyCode <= 57;
    let val = event.target.value;
    const charCode = event.keyCode;      // Allow only backspace and delete
    if (charCode === 46 || charCode === 8 || charCode === 37 || charCode === 45) {
      // let it happen, don't do anything
    } else {   // Ensure that it is a number and stop the keypress
      if (!this.numberOnly(event)) {
        event.preventDefault();
      } else {
        let formatFlag = false;
        if (/^\d{2}$/.test(val)) {
          val = val.substring(0, 2) + '/';
          formatFlag = true;
        } else if (/^\d{2}\/\d{2}$/.test(val)) {
          val = val.substring(0, 5) + '/';
          formatFlag = true;
        } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(val)) {
          formatFlag = true;
        }
        if (formatFlag) {
          event.target.value = val;
        }
      }
    }
  }
  public numberOnly(event: any) {
    return (event.keyCode >= 48 && event.keyCode <= 57);
  }

  public alpaNumberOnly(event: any) {
    return (event.keyCode == 13 || (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode == 13 || event.keyCode === 32 || (event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122));
  }

  public validateAlphaNumeric(event) {
    if (!this.alpaNumberOnly(event)) {
      event.preventDefault();
    }
  }

  public validateText(event) {
    if (!this.textOnly(event)) {
      event.preventDefault();
    }
  }

  textOnly(event: any) {
    return (event.keyCode === 32 || event.keyCode >= 65 && event.keyCode <= 90 || event.keyCode >= 97 && event.keyCode <= 122);
  }

  public validateNumberOnly(event: any) {
    if (!this.numberOnly(event)) {
      event.preventDefault();
    }
  }

}
