import {AbsentParentDetails} from "./absent-parent-details.class";
import {ABContactInfo} from "./ab-contact-info.class";
import {AbsentParentCourtOrderDetails} from "./ab-court-order-details.class";
import {BaseBean} from "./base-bean.class";
import {AbsentParentEmploymentDetails} from "./absent-parent-employment-details";

export class AbsentParent extends BaseBean{
  constructor (
    public absentParentId:number = undefined,
    public absentParentDetails = new AbsentParentDetails(),
    public absentParentContact = new ABContactInfo(),
    public absentParentCourt = new AbsentParentCourtOrderDetails(),
    public absentParentEmploymentDetails =  new AbsentParentEmploymentDetails()
  ) {
    super();
  }
}
