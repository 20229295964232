import { IndividualTaxStatus } from './individual-tax-status.class';
import { ClientVerification } from './client-verification.class';
import { Relationship } from './relationship.class';
import { InstitutionalInformation } from './institutional-information.class';
import { Program } from './program.class';
import { PersonName } from './person-name.class';
import { SocialSecurityNumber } from './social-security-number.class';
import { OverrideEligibility } from './override-eligibility.class';
// import { HouseholdVerification } from './household-verification.class';
import { Person } from './person.class';
import { IndividualDetails } from './individual-details.class';
import { CitizenshipImmigration } from './citizenship-immigration.class';
import { StudentInfo } from './student-info.class';
import { Disability } from './disability.class';
import { Institution } from './institution.class';
import { Asset } from './asset.class';
import { EarnedIncome } from './earned-income.class';
import { UnearnedIncome } from './unearned-income.class';
import { AppliedUnearnedIncome } from './applied-unearned-income.class';
import { DependentCareExpenses } from './dependent-care-expenses.class';
import { ShelterExpense } from './shelter-expense.class';
import { ActualUtilityExpenseClaimed } from './actual-utility-expense-claimed.class';
import { SpecialNeeds } from './special-needs.class';
import { MedicalExpenses } from './medical-expenses.class';
import { EmergencyAssistance } from './emergency-assistance.class';
import { Eligibility } from './eligibility.class';
import { InsurancePolicyDetails } from './insurance-policy-details.class';
import { ProgramRequest } from './program-requested.class';
import { Medicare } from './medicare.class';
import { VerificationChecklist } from './verification-checklist.class';
import { IndividualTaxFiling } from 'app/model/api/indiviual-tax-filing.class';
import { MaritalStatus } from 'app/model/api/marital-status.class'
import { InsuranceInformation } from 'app/model/api/insurance-information.class';
import { Pregnancy } from './pregnancy.class';
import { IndividualBoarder } from './individual-boarder-details.class';
import { RelationshipDetails } from "./relationship-details.class";
import { ChildSpousalExpense } from './child-spousal-expenses.class';
import { ShelterUtilityExpenses } from './shelter-utility-expenses.class';
import { PotentialAssets } from './potential-asset.class';
import { AboutMember } from "./about-member.class";

export class HouseholdMember extends Person {
    constructor(
        public matchPercentage: number = undefined,
        public householdMemberId: number = undefined,
        public boarderStatusYN: any = '',
        public individualId: string = '',
        public headOfHouseholdYN: any = '',
        public relationshipCd: string = '',
        public relations: RelationshipDetails[] = [],
        public altNames: PersonName[] = [],
        public additionalSsns: SocialSecurityNumber[] = [],
        public pregnantYN: string = '',
        public aliasClientIds: number[] = [],
        public memberClearanceId: number = undefined,
        public memberClearedYN: string = '',
        public memberEditableYN: string = '',
        public cases: string[] = [],
        public overrideEligibility: OverrideEligibility[] = [],
        // public verificationCheckList: VerificationChecklistHousehold = new VerificationChecklistHousehold(),
        // public verification: HouseholdVerification = new HouseholdVerification(),
        public dobVerification: ClientVerification = new ClientVerification(),
        public hasSsnYN: string = '',
        public soSecReferral: string = '',
        public eligibility: Eligibility = new Eligibility(),
        public approvedEligibility: Eligibility = new Eligibility(),
        public maritalStatus: MaritalStatus = new MaritalStatus(),
        public ethnicity: string = '',
        public financialResposibility: string = '',
        public applicablePrograms: Program[] = [],
        public ssnReferralDate: Date = null,
        public individualDetails: IndividualDetails = new IndividualDetails(),
        public medicare: Medicare = new Medicare(),
        public citizenshipImmigration: CitizenshipImmigration = new CitizenshipImmigration(),
        public priorCitizenshipImmigration: CitizenshipImmigration[] = [],
        public studentInfo: StudentInfo[] = [],
        public disabilities: Disability[] = [],
        public insuranceInformation: InsuranceInformation = new InsuranceInformation(),
        public institutionalInformation: InstitutionalInformation = new InstitutionalInformation(),
        public assets: Asset[] = [],
        public earnedIncomes: EarnedIncome[] = [],
        public unearnedIncomes: UnearnedIncome[] = [],
        public appliedUnearnedIncomes: AppliedUnearnedIncome[] = [],
        public potentialAssets: PotentialAssets[] = [],
        public unearnedIncomeApplications: AppliedUnearnedIncome[] = [],
        public dependentCareExpenses: DependentCareExpenses[] = [],
        public shelterExpenses: ShelterExpense[] = [],
        public shelterUtilityExpenses: ShelterUtilityExpenses[] = [],
        public utilityExpenseType: string = '',
        public utilityExpenses: ActualUtilityExpenseClaimed[] = [],
        public specialNeeds: SpecialNeeds[] = [],
        public ccTriggerInsert: VerificationChecklist[] = [],
        public medicalExpenses: MedicalExpenses[] = [],
        public childSpousalExpenses: ChildSpousalExpense[] = [],
        public disabledYN: string = '',
        public refugeeYN: string = '',
        public blindYN: string = '',
        public destituteMigrantYN: string = '',
        public shelterIncludesMealsYN: string = '',
        public protectiveLivingArrangementYN: string = '',
        public communityBasedWaiverYN: string = '',
        public medicarePartAEntitlementYN: string = '',
        public absentParentYN: string = '',
        public programRequest: ProgramRequest[] = [],
        public insurancePolicies: InsurancePolicyDetails[] = [],
        public outStandingVerifications: ClientVerification[] = [],
        public individualTaxStatus: IndividualTaxStatus = new IndividualTaxStatus(),
        public irn: number = null,
        public verificationStatus: Map<String, String> = undefined,
        public taxFilingStatuses: IndividualTaxFiling[] = [],
        public createMdmId: boolean = true,
        public mdmId: number = null,
        public errInd: string = '',
        public maId: string = '',
        public maIdSuffix: string = '',
        public individualBoarder: IndividualBoarder[] = [],
        public caseStatus: string = '',
        public pregnancy: Pregnancy[] = [],
        public aliasNames: PersonName[] =[],
        public delteMemberIndicator: string = '',
        public institution: Institution = null,
        public institutionName = '',
        public aboutMember: AboutMember = new AboutMember(),
        public hohVerifiedYN: string = ''
        //  public pregnancy: any
    ) {
        super();
    }
}
