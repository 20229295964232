import { BaseBean } from "./base-bean.class";
export class AbsentParentGoodCause extends BaseBean {
  constructor(
    public goodCauseClaimInd: string = '',
    public goodCauseReason: string = '',
    public goodCauseStatus: string = '',
    public goodCauseEffectiveDate: Date = null,
    public ivdCoopIndEffectiveDate: Date =null,
    public ivdCoopInd: string = ''
  ) {
    super();
  }
}
