import { ScheduleInterview } from 'app/model/api/schedule-interview.class';
import { ProgramFinancial } from './program-financial.class';
import { BaseBean } from './base-bean.class';
import { ClientVerification } from './client-verification.class';
import { Reason } from './reason.class';
import { Penalty } from './penalty.class';
import { ProgramAdministrativeInformation } from './program-administrative-information.class';

export class Program extends BaseBean {
  constructor(
    public programId: number = undefined,
    public programName: string = '',
    public programType: string = '',
    public medCoverageGroup: string = '',
    // public availableAuId: string = '',
    // public ppiGroup: string = '',
    public selectedYN: string = '', // non display suggested program
    public eligibilityStatus: string = '',
    public eligibilityStatusCd: string = '',
    public requestedYN: string = '',  // non display additional program
    public finalizedYN: string = '',
    public recommendedYN: string = '',
    public capYN: string = '',
    public programStatusCd: string = null,
    public programBeginDate: Date = null,
    public programEndDate: Date = null,
    public assistanceGroupLinkingId: number = undefined,
    // interview Model
    public issuanceMethod: string = '',
    public conversionDate: Date = null,
    public statusDate: Date = null,
    public paidThroughDate: Date = null,
    public statusReasons: Reason[] = [],
    public eligibilityReasons: Reason[] = [],
    public penalty: Penalty = new Penalty(),
    public dhrControlNumber: string = '',
    public trialEligibilityCounter: string = '',
    public lumpSumAmount: number = undefined,
    public presumptiveEligibility: string = '',
    public redetCompletionStatusYN: string = '',
    public redetMethod: string = '',
    public payMonth: number = undefined,
    public aidCd: string = "",
    // public redetBeginDate: string = '',
    // public redetEndDate: string = '',
    public benefitsAmount: number = undefined,
    public administrativeInformation: ProgramAdministrativeInformation = new ProgramAdministrativeInformation(),
    public financialStatus: string = '',
    public nonFinancialStatus: string = '',
    public resourceTest: string = '',
    public spendDownBeginDate: Date = null,
	  public spendDownEndDate: Date = null,
	  public redeterminationDueDate: Date = null,
	  public redeterminationStatusCd: string = '',
    public participationStatus: string = '',
    // public programRequestDate: Date = null,
    public applicationDate: Date = null,
    public outstandingVerifications: ClientVerification[] = [],
    public programIndividualId: number = null,
    public programIndividualEligibilityId: number = null,
    public programEligibilityId: number = null,
    public scrEligibilityRunTimestamp: string = '',
    public reviewStatus: string = '',
    public budgetingMethod: string = '',
    public waveAdvisoryActionPeriodYN: string = '',
    public programFinancial: ProgramFinancial = new ProgramFinancial(),
    public confirmBenefitYN: string = '',
    public eligibilityStartDate: Date = null,
    public eligibilityEndDate: Date = null,
    public oldProgramId: number = undefined,
    public scheduleInterview: ScheduleInterview = new ScheduleInterview(),
    public penalties: Penalty[]=[],
    public firstName: string = '',
    public lastName: string = '',
    public recoupment: number = undefined,
    public netBenefitAmount: number = undefined,
    public discrepancyAmount: number = undefined,
    public discrepancyErrorType: string = '',
    //public discrepancySource: string = '',
    //public discoveryDate:  Date = null,
    public claimAmount: number = undefined,
    public offsetAmount: number =undefined,
    public targetIndividual: number = null,
    public eligibilityBeginDate: Date = null,
    public targetFirstName: string = '',
    public tdapCounter: number = undefined,
    public counterClkBgnDt: Date = null,
    public counterClkEndDt: Date = null,
    public targetLastName: string = '',
    public targetSuffix: string = '',
    public hideIndicator: string = ''
    ) {
    super();
  }

}
