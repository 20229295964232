import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DataShareService } from './../common/data-share.service';
import { ConstantsService } from './../common/constants.service';
import { AuthService } from './../common/auth.service';
import { Router } from '@angular/router';
import { ApiManager } from './../common/api-manager.service';
import { Login } from './../model/api/login.class';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})

export class LoginComponent {
	public loginPagemessage: string;
	public result: string;
	public loginObj: any;
	public loginForm: FormGroup;
	public loginStatus: boolean;
	public data: any = {};
	constructor(public _sharedService: DataShareService, public constantsService: ConstantsService,
		public router: Router, public authService: AuthService, public formBuilder: FormBuilder, public apiManager: ApiManager) {
		this.loginPagemessage = this._sharedService.getDataFromMapAndClearData('loginPageMessage');
		this.constantsService.setSecurityObject(undefined);
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required]],
			password: ['', [Validators.required, Validators.minLength(6)]],
		});
	}

	save(model: Login, isValid: boolean) {
		// if (isValid) {
		this.authService.matchLoginDetails(model, this.router);
		//this.constantsService.getLookupData();
		// }
	}

	get() {
		/*this.apiManager.fetchData("lookup", { "lookupType": "YesNo" }, ApiManager.GET,undefined, () => { console.log("success") }, () => { console.log("failure") })
			.subscribe((res: Response) => {
				console.log("API Call Success: Subscribe");
				this.data = res;
				console.log("fetched object: " + JSON.stringify(res));
			},
			error => {
				console.log("API call failure: Error");
			});*/
	}
}
