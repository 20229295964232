import { FooterComponent } from './footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from "../angular-material/angular-material.module";

@NgModule({
declarations: [FooterComponent],
exports: [FooterComponent],
imports: [CommonModule, AngularMaterialModule]
})

export class FooterModule {}