import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-component',
  templateUrl: './error-component.component.html',
  styleUrls: ['./error-component.component.css']
})

export class ErrorComponentComponent implements OnInit {

  constructor(private _location: Location,
    private router: Router
  ) { }

  ngOnInit() {
  }

  back() {
    this._location.back();
  }

  home() {
    this.router.navigate(['/dashboard/homeScreen']);
  }
}