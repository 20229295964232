import { BaseBean } from './base-bean.class';
//import { EIDDetail } from '../program-request-eid-hardship.class';

export class ProgramAdministrativeInformation extends BaseBean {
    constructor(
        public recordId: number = 0,
        public dirtyStatus: string = '',
        public mrQrReceivedDate: Date = null,
        public applicationDate: Date = null,
        public mrQrCompletionStatus: string = '',
        public mrQrGoodCauseYN: string = '',
        public autoRedetOverrideYN: string = '',
        public suspectedOfLivingAboveMeansYN: string = '',
        public qmbOverrideYN: string = '',
        public qmbOverrideDate: Date = null,
        public workerName: string = '',
        public delayReason: string = '',
        public daysOverdue: string = '',
        public qualityControlYN: string = '',
        public srYN: string = '',
        public arYN: string = '',
        public cvYN: string = '',
        public telephonicInterviewYN: string = '',
        public interviewDate: Date = null,
        public programId: number = undefined,
        public programCd: string = '',
        //public eidDetails : EIDDetail[]=[]

    ) {
        super();
    }
}
