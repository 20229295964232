import { BaseBean } from './base-bean.class';

export class Vendor extends BaseBean{
    constructor(
        public caseId: string ='',
        public vendorId: number = undefined,
        public vendorName: string = '',
        public vendorCommonName: string = '',
        public vendorFirstName: string='',
        public vendorMiddleName: string='',
        public vendorLastName: string='',
        public vendorSfxName: string='',
        public  effectiveBeginDt: Date = null,
        public  effectiveEndDt: Date = null,
        public corpYN: string = '',
        public mmisVendorId: string = '',
        public mmisVendorSpecialityCd: string = '',
        public vendorTypeCd: string = '',
        public federalTaxNumber: string = '',
        public workerId: string = '',
        public ldssId: string = '',
        public location: string = '',
        public vendorAddressId:number = undefined,
        public vendorAddressBeginDt:Date = null,
        public vendorAreacode: number = undefined,
        public vendorCity: string = '',
        public vendorLine1Address: string = '',
        public vendorState: string = '',
        public vendorStreetAddress: string = '',
        public vendorTelNum: number = undefined,
        public vendorZipcode: number = undefined,
        public vendorRateId:number = undefined,
        public vendorRateBeginDt: Date = null,
        public rateAmount: number = undefined,
        public rateCd:string = '',
        public vendorStatusId:number = undefined,
        public vendorStatusBeginDt:Date = null,
        public vendorStatusEndDt:Date = null,
        public vendorCommonefctvBgnDt:Date = null,
        public statusCode:string = '',
        public statusReasonCode:string='',
        public sourceSystem:string=''

    ){
        super();
    }
}
