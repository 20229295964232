import { BaseBean } from './base-bean.class';
export class AdditionalInformation extends BaseBean {
    constructor(
        public preferredLanguage: string = '',
        public otherLanguage: string = '',
        public interpreterNeededYN: string = '',
        public visuallyImpairedYN: string = '',
        public hearingImpairedYN: string = '',
        public hearingImpairedType: string = '',
        public physicallyImpairedYN: string = ''
    ) {
        super();
    }
}
