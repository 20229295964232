import { BaseBean } from './base-bean.class';
export class PhoneNumber extends BaseBean{
  constructor(
    public phoneNumberId: number = undefined,
    public phoneNumber: string = '',  // number
    public phoneNumberType: string = '',
    public phoneNumberExt: string = '', // number
    public phoneNumberStartDate: Date = null,
    public phoneNumberEndDate: Date = null
  ) {
    super();
  }
}
