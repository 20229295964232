import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NavClass } from "../model/api/nav.class";
import * as _ from 'underscore';
import { EventEmitterService } from './event.emitter.service';
// import {InterimChangeService} from './interim-change.service';
import { Router } from "@angular/router";
import { ProgramSelected } from "../model/api/program-selected.class";
import { Observable } from "rxjs";
import { ApiManager } from "./api-manager.service";
import { DataShareService } from 'app/common/data-share.service';
import { ConstantsService } from 'app/common/constants.service';
import { AnyKindOfDictionary } from 'lodash';

@Injectable()
export class NavigationService {
  private navItems: NavClass[] = [];
  private navHead: NavClass[] = [];
  private navChildren: NavClass[];
  private currentNav: NavClass[];
  public nav: NavClass[] = [];
  public selectedPgms: string[] = [];
  private showSubmit: boolean = false;
  public isWorker: boolean = false;
  constructor(private storage: LocalStorageService,
    private session: SessionStorageService,
    private emitter: EventEmitterService,
    private router: Router,
    private apiManager: ApiManager,
    private shareService: DataShareService,
    private constantsService: ConstantsService) {
  }

  private extractData(res) {
    let body = res;
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  setNavItems(items) {
    this.navItems = items;
    this.storage.store('allNavItems', items);
  }

  getNavItems() {
    return this.checkForWorker();
  }

  setNavHead(items) {
    this.navHead = items;
    this.storage.store('navHead', items);
  }

  getShowSubmit(): boolean {
    return this.showSubmit;
  }

  getNavHead() {
    return this.storage.retrieve('navHead');
  }

  setSelectedNavHead(id) {
    this.storage.store('navHeadSelected', id);
  }

  getSelectedNavHead() {
    return this.storage.retrieve('navHeadSelected');
  }

  navigatePage(url, dir: string): Observable<any> | Promise<any> | any {
    this.currentNav = this.getCurrentNav(url);
    if (this.currentNav && this.currentNav[0] && this.currentNav[0].prnt_desc === 'parentAdditionalInfo') {
      // this.getPrograms().subscribe(v => {
      //   this.router.navigate([this.getPage(url, dir)]);
      // });
      this.navDBCall().then(val => {
        this.setNavHead(val.filter(v => { return v.is_parent == true; }));
        this.setNavItems(val);
        this.router.navigate([this.getPage(url, dir)]);
      });
    }
    else {
      this.router && this.router.navigate([this.getPage(url, dir)]);
    }
  }

  getPage(url, dir: string) {
    this.currentNav = this.getCurrentNav(url);
    if (this.currentNav && this.currentNav.length > 0
      && this.currentNav[0].prnt_desc === 'parentEligibilityDetermination'
      && this.currentNav[0].disabled === 'Y') {
      // this.currentNav[0].prnt_desc = 'parentEligibilityDetermination';
      this.saveOptionalCurrentNavs(ConstantsService.humanServiceModel.benefitsCase.caseId, this.currentNav[0].nvgtn_cd).toPromise().then(
        this.getPrograms().toPromise().then()
      );
    }
    if (this.currentNav && this.currentNav[0]) {
      this.navChildren = this.giveChildren(this.currentNav[0].prnt_desc);
    }
    let minMax = this.givMinMax(this.navChildren);
    let parentMinMax;
    let currentParentNum;
    let aParentCd;
    let sendUrl;
    let currPage: NavClass[];
    let navHead = this.getNavHead();
    let navItems = this.getNavItems();
    if (dir == 'next') {
      if (this.currentNav && this.currentNav[0] && this.currentNav[0].sort_order_nbr < minMax.max) {
        let nav;
        for (let u = this.currentNav[0].sort_order_nbr + 1; u <= minMax.max; u++) {
          nav = this.getCurrentNavSO(this.currentNav[0].prnt_desc, u);
          if (nav && nav.length == 1) {
            this.currentNav = nav;
            sendUrl = this.currentNav[0].screen_defn;
            break;
          }
        }
      }
      else {
        parentMinMax = this.givMinMax(navHead);
        let beforSort = navHead.filter(v => {
          if (this.currentNav && this.currentNav[0] && v.prnt_desc == this.currentNav[0].prnt_desc) {
            return v;
          }
        });
        if (beforSort && beforSort.length > 0) {
          currentParentNum = beforSort[0].sort_order_nbr;
          if (currentParentNum < parentMinMax.max) {
            aParentCd = navHead.filter(v => {
              if (v.sort_order_nbr == currentParentNum + 1) {
                return v;
              }
            })[0].prnt_desc;
            this.navChildren = this.giveChildren(aParentCd);
            minMax = this.givMinMax(this.navChildren);
            currPage = this.getCurrentNavSO(aParentCd, minMax.min);
            this.currentNav = currPage;
            if (this.currentNav[0].sort_order_nbr == minMax.max && currentParentNum + 1 == parentMinMax.max) {
              this.showSubmit = true;
            }
            sendUrl = currPage[0].screen_defn;
          }
        }
      }
    }
    else if (dir == 'back') {
      if (this.currentNav[0].sort_order_nbr > minMax.min) {
        let nav;
        for (let u = this.currentNav[0].sort_order_nbr - 1; u >= minMax.min; u--) {
          nav = this.getCurrentNavSO(this.currentNav[0].prnt_desc, u);
          if (nav && nav.length == 1) {
            this.currentNav = nav;
            sendUrl = this.currentNav[0].screen_defn;
            break;
          }
        }
        this.showSubmit = false;
        sendUrl = this.currentNav[0].screen_defn;
      }
      else {
        parentMinMax = this.givMinMax(navHead);
        currentParentNum = navHead.filter(v => {
          if (v.prnt_desc == this.currentNav[0].prnt_desc) {
            return v;
          }
        })[0].sort_order_nbr;
        if (currentParentNum > parentMinMax.min) {
          aParentCd = navHead.filter(v => {
            if (v.sort_order_nbr == currentParentNum - 1) {
              return v;
            }
          })[0].prnt_desc;
          this.navChildren = this.giveChildren(aParentCd);
          minMax = this.givMinMax(this.navChildren);
          currPage = this.getCurrentNavSO(aParentCd, minMax.max);
          this.currentNav = currPage;
          this.showSubmit = false;
          sendUrl = currPage[0].screen_defn;
        }
      }
    }
    return sendUrl;
  }

  getCurrentNavSO(parentCd, num) {
    return this.getNavItems().filter(v => {
      if (v.prnt_desc == parentCd && v.sort_order_nbr == num && v.is_parent == false) {
        return v;
      }
    });
  }

  getParentCode(url): string {
    let code: string = '';
    this.getNavItems().forEach(v => {
      if (v.screen_defn == url) {
        code = v.prnt_desc;
      }
    });
    return code;
  }

  checkForWorker() {
    const ref = this.constantsService.userData;
    let allNavItems = this.storage.retrieve('allNavItems');
    let isWorker: boolean;
    let isIAR: boolean;
    if (ref.user.roles.find((x: any) => x == 'EE_SUPUSR') && ref.user.department == 'FIA')
      isWorker = true;
    if (ref.user.group == 'IAR' || ref.user.group == 'FISCAL')
      isIAR = true;
    if (!isWorker) {
      let index = allNavItems.indexOf(allNavItems.find((x: any) => x.tag_txt == 'Notice 60 Address Request'));
      if (index > -1)
        allNavItems.splice(index, 1);
      let indexEBT = allNavItems.indexOf(allNavItems.find((x: any) => x.tag_txt == 'EBT Issuance Selection/Schedule'));
      if (indexEBT > -1)
        allNavItems.splice(indexEBT, 1);
    }
    if (!isIAR) {
      let index = allNavItems.indexOf(allNavItems.find((x: any) => x.tag_txt == 'IAR Summary'));
      if (index > -1)
        allNavItems.splice(index, 1);
    }
    return allNavItems;
  }

  checkForSubmit(url) {
    let navHead = this.getNavHead();
    let parentCd = this.getParentCode(url);
    this.navHead.forEach(v => {
      if (v.prnt_desc == parentCd) {
        this.showSubmit = (v.sort_order_nbr == navHead.length);
      }
    })
  }

  getCurrentNav(url): NavClass[] {
    return this.getNavItems().filter(v => {
      if (v.screen_defn === url) {
        return v;
      }
    });
  }

  giveChildren(parent_cd: string): NavClass[] {
    let sendChild: NavClass[];
    sendChild = _.filter(this.getNavItems(), (val) => {
      return (val.is_parent === false && val.prnt_desc === parent_cd);
    });
    return sendChild;
  }

  givMinMax(obj: NavClass[]) {
    let returnObj = {
      min: 1,
      max: 1
    };
    if (obj && obj.length == 1) {
      returnObj.min = obj[0].sort_order_nbr;
      returnObj.max = obj[0].sort_order_nbr;
    }
    else if (obj && obj[0] && obj[1] && obj[0].sort_order_nbr > obj[1].sort_order_nbr) {
      returnObj.min = obj[1].sort_order_nbr;
      returnObj.max = obj[0].sort_order_nbr;
    }
    else if (obj && obj[0] && obj[1] && obj[0].sort_order_nbr < obj[1].sort_order_nbr) {
      returnObj.min = obj[0].sort_order_nbr;
      returnObj.max = obj[1].sort_order_nbr;
    }
    if (obj) {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].sort_order_nbr > returnObj.max) {
          returnObj.max = obj[i].sort_order_nbr;
        }
        if (returnObj.min > obj[i].sort_order_nbr) {
          returnObj.min = obj[i].sort_order_nbr;
        }
      }
    }
    return returnObj;
  }

  getScreenId(currentUrl?): number {
    this.nav = this.getNavItems();
    let screenId: number = 0;
    const url: string = currentUrl ? currentUrl : this.router.url;
    this.nav.forEach(v => {
      if (v.screen_defn == url) {
        screenId = v.screen_id;
      }
    });
    return screenId;
  }

  getPrograms(appCd?): Observable<any> | Promise<any> | any {
    let id: string[] = [];
    if (appCd == 'AR') {
      this.setSelectedNavHead('parentAppRegistraion');
    }
    else {
      this.setSelectedNavHead('parentEligibilityDetermination');
    }
    if (ConstantsService.humanServiceModel
      && ConstantsService.humanServiceModel.benefitsCase
      && ConstantsService.humanServiceModel.benefitsCase.caseId) {
      id.push(ConstantsService.humanServiceModel.benefitsCase.caseId);

      return this.apiManager.fetchData('getSelectedPrograms', undefined, ApiManager.GET,
        undefined, undefined, undefined, id)
        .map(res => {
          if (res && res.selectedPrograms && res.selectedPrograms[0] && res.selectedPrograms[0].applicationDate) {
            this.constantsService.setApplicationDate(res.selectedPrograms[0].applicationDate);
            if (ConstantsService.humanServiceModel
              && ConstantsService.humanServiceModel.benefitsCase
              && !ConstantsService.humanServiceModel.benefitsCase.selectedPrograms
            ) {
              ConstantsService.humanServiceModel.benefitsCase.selectedPrograms = res.selectedPrograms;
            }

            this.setPrograms(res.selectedPrograms, appCd).toPromise().then(val => {
              this.setNavHead(val.filter(v => { return v.is_parent == true; }));
              this.setNavItems(val);
              // this.navigationService.enableHOH();
              // this.getMembers();
            })
              .catch(err => {
                console.log(err);
              });
          }
        })
    }
    else {
      return this.navDBCall(appCd).then(val => {
        this.setNavHead(val.filter(v => { return v.is_parent == true; }));
        this.setNavItems(val);
      }).catch(err => {
        console.log(err);
      })
    }
  }

  setPrograms(pgms: ProgramSelected[], appCd?): Observable<any> {
    this.selectedPgms = [];
    if (pgms && pgms.length > 0) {
      pgms.forEach(v => {
        this.selectedPgms.push(v.programName)
      });
    }
    return this.getNavs(appCd);
  }

  getNavs(appCd?): Observable<any> {
    return Observable.create(observer => {
      this.navDBCall(appCd).then(val => {
        this.navItems = val;
        this.navHead = _.filter(this.navItems, (val) => {
          return val.is_parent == true;
        });
        this.setNavHead(this.navHead);
        this.setNavItems(this.navItems);
        observer.next(val);
        observer.complete()
      }).catch(err => {
        observer.next(err);
        observer.complete();
      })
    });
  }

  enableHOH() {
    let nav = this.getNavItems();
    this.setNavItems([]);
    this.setNavHead([]);
    nav.forEach((v, i) => {
      if (v.nvgtn_cd == 'nav-initiate' || v.nvgtn_cd == 'nav-register' || v.nvgtn_cd == 'nav-in-assistance-fspQuestions' || v.nvgtn_cd == 'nav-in-assistance-fspQualification') {
        nav[i].disabled = 'N';
      }
    });
    this.setNavItems(nav);
    this.navHead = _.filter(this.getNavItems(), (val) => {
      return val.is_parent == true;
    });
    this.setNavHead(this.navHead)
  }

  enableAll() {
    let nav = this.getNavItems();
    this.setNavItems([]);
    this.setNavHead([]);
    nav.forEach((v, i) => {
      nav[i].disabled = 'N';
    });
    this.setNavItems(nav);
    this.navHead = _.filter(this.getNavItems(), (val) => {
      return val.is_parent == true;
    });
    this.setNavHead(this.navHead)
  }

  navDBCall(appCd?) {
    const queryParams: any[] = [];
    queryParams.push(ConstantsService.humanServiceModel.benefitsCase.caseId ?
      ConstantsService.humanServiceModel.benefitsCase.caseId : 0);
    const sendObj = {
      localeCode: 'en_us',
      appType: appCd || 'ED',
      pgmType: this.selectedPgms && this.selectedPgms.length > 0 ? this.selectedPgms : ['FS']
    };
    // return this.apiManager.fetchData('workerNav', undefined, ApiManager.POST, sendObj)
    //   .toPromise().then(this.extractData).catch(this.handleError);
    return this.apiManager.fetchData('workerGetCaseNavs', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams)
      .toPromise().then(this.extractData).catch(this.handleError);
  }

  saveOptionalNavs(caseID, selNavs: any): Observable<any> | Promise<any> | any {
    const queryParams: any[] = [];
    queryParams.push(caseID);
    const sendObj = {
      appType: 'ED',
      selectedNavs: selNavs
    };
    return this.apiManager.fetchData('workerSaveNavs', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams);
    // .toPromise().then(this.extractData).catch(this.handleError);
  }

  saveOptionalCurrentNavs(caseID, selNavs: String): Observable<any> | Promise<any> | any {
    const queryParams: any[] = [];
    queryParams.push(caseID);
    queryParams.push(selNavs);

    return this.apiManager.fetchData('workerSaveNavs', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams);
    // .toPromise().then(this.extractData).catch(this.handleError);
  }


  getCaseNavs(caseID): Observable<any> | Promise<any> | any {
    const queryParams: any[] = [];
    queryParams.push(caseID);
    const sendObj = {
      localeCode: 'en_us',
      appType: 'ED',
      pgmType: this.selectedPgms && this.selectedPgms.length > 0 ? this.selectedPgms : ['FS']
    };
    return this.apiManager.fetchData('workerGetCaseNavs', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams);
    // return ;
    // .subscribe(res => {
    //   return res;
    // });
  }
}