import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class DataShareObservableService {
  data: string;

  // Observable string sources
  private dataUpdated = new Subject<string>();

  // Observable string streams
  dataUpdated$ = this.dataUpdated.asObservable();

  // Service message commands
  dataUpdatedPropogate(data: string) {
    this.data = data;
    this.dataUpdated.next(data);
  }
}