import { BaseBean } from './base-bean.class';
export class Address extends BaseBean {
    constructor(
        public caseAddressId: number = undefined,
        public addressId: number = undefined,
        public addressCareOf: string = '',
        public addressLine1: string = '',
        public addressLine2: string = '',
        public city: string = '',
        public state: string = '',
        public county: string = '',
        public district: string = '',
        public zipcode: string = '',
        public zip4code: string = '',
        public addressVerifiedYN: string = '',
        public institutionName: string = '',
        public vendorId: number = undefined,
        public institutionYN: string = '',
        public homeAddressYN: string = '',
        public addressStartDate: Date = null,
        public addressEndDate: Date = null,
        public ownedByMemberYN: string = '',
        public localDistrictOfficeYN: string = ''

    ) {
        super();
    }
}
