import { BaseBean } from "./base-bean.class";
import { Employer } from "./employer.class";
import { OtherIncome } from "./other-income.class";
import { ApiMessage } from "./api-message.class";

export class AbsentParentEmploymentDetails extends BaseBean {
  constructor(public employers: Employer[] = [],
              public otherIncome: OtherIncome = new OtherIncome(),
              public userMessages: ApiMessage = new ApiMessage()
  ) {
    super();
  }
}
