import { BaseBean } from "./base-bean.class";
import { ABChild } from './ab-child.class';

export class AbsentParentCourtOrderDetails extends BaseBean {
  constructor(
    public absntPrntCrtOrdrId: number = undefined,
    public absentParentId: string = '',
    public payingSprtInd: string = '',
    public payee: string = '',
    public lastPaidDt: Date = null,
    public pymtAmt: number = undefined,
    public childrenCovered: ABChild[] = [],
    public dcktNum: number = undefined,
    public pymtFreqCd: string = '',
    public sprtOblgtnAmt: string = '',
    public effectiveBeginDt: Date = null,
    public effectiveEndDt: Date = null,
    public agencyName: string = '',
    public absntPrntCrtOrdrPayeeId: number = null,
    public courtOrderSequenceNum: number = null,
    public pyeSeqNum: number = null
  ) {
    super();
  }
}
