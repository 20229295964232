import { BaseBean } from './base-bean.class';

export class InsuranceInformation extends BaseBean {
    constructor(
        public recordId : number = 0,
        public healthInsurance: string = '',
        public hmo: string = '',
        public managedCareYN: string = '',
        public insuranceDroppedYN: string = '',
        public willingToPayYN: string = '',
        public buyingYN: string = '',
        public tplYN: string = '',
        public liabilityCD: string = '',
        public purchaseForChildYN: string = ''
    ) {
        super();
    }
}
