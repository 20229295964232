import { BaseBean } from './base-bean.class';
import { EarnedIncome } from './earned-income.class';
import { UnearnedIncome } from './unearned-income.class';

export class FamilyAllowance extends BaseBean {
    constructor(
        public allowanceAmt: number = undefined,
        public basicMaintShelterAmt: number = undefined,
        public totalGrossEarnedIncome: number = undefined,
        public totalGrossIncome: number = undefined,
        public totalGrossUnearnedIncome: number = undefined,
        public earnedIncomes: EarnedIncome[] = [],
        public unearnedIncomes: UnearnedIncome[] = []
    ) {
        super()
    }
}