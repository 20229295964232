import {BaseBean} from "./base-bean.class";

export class AbsentParentMaritalStatus  extends BaseBean{
  constructor (
    public maritalStatus: string = '',
    public marriageState: string = '',
    public marriageCountry: string = '',
    public maritalDate:Date = null,
    public marriageCity: string = ''
  ) {  
    super();
  }
}
