import { InterviewSteps } from './interview-steps.class';
import { BaseBean } from './base-bean.class';
import { ScreeningSteps } from './screening-steps.class';
export class ApplicationProcessSteps extends BaseBean{
    constructor(
        public screeningSteps: ScreeningSteps = new ScreeningSteps(),
        public interviewSteps: InterviewSteps = new InterviewSteps()
    ) {
        super();
    }
}
