import { ClientVerification } from './client-verification.class';
export class DeathDetails extends ClientVerification{
    constructor(
        public dateOfDeath: Date = null,
        public state: string = '',
        public deathCertificateNumber: string = '',
        public deathVerification: ClientVerification = new ClientVerification(),
        public secondaryVerification: ClientVerification = new ClientVerification(),
        public recordId: number = 0
    ) { super(); }
}
