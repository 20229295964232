import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { share } from "rxjs/operators"
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { environment } from './../../environments/environment';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { ConstantsService } from './../common/constants.service';
import { AlertService } from 'app/common/alert.service';
import { StorageService } from "app/common/storage.service";
import { EventEmitterService } from "./event.emitter.service";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { LoaderService } from "./loader.service";
import { NgxSpinnerService } from 'ngx-spinner';

let wso2Version = '';
let sdrWso2Version = '';

@Injectable()

export class ApiManager {
    private localLookUps: any = {};
    /* tslint:disable */
    public static readonly getBeaconRequest = 'application-process/interface' + wso2Version + '/beaconRequest';
    public static readonly saveActionStatus = 'application-process' + wso2Version + '/case-change-report/~~';
    public static readonly initaiteCase = 'application-process' + wso2Version + '/case-status/~~';
    public static readonly getCaseId = 'application-process' + wso2Version + '/caseId/~~';
    public static readonly getUsedCase = 'application-process' + wso2Version + '/get-lstFive-Worked';
    public static readonly getAidCd = 'application-process' + wso2Version + '/getAidCodes/~~';
    public static readonly caseSummary = 'case-management' + wso2Version + '/cp_case_creation_dataview/~~';
    public static readonly getIntake = 'public' + wso2Version + '/consumer/jsonRetrieve';
    public static readonly lookupSearchPost = 'public' + wso2Version + '/consumer/lookup-search';
    static readonly earnedIncomeHistory: string = 'public' + wso2Version + '/earnedIncomeHistory/~~';
    static readonly unearnedIncomeHistory: string = 'public' + wso2Version + '/~~/unearnedIncomeHistory/~~';
    static readonly assetHistory: string = 'public' + wso2Version + '/~~/assetHistory/~~';
    static readonly customLogin: string = '/access-management' + wso2Version + '/dummy-login';
    static readonly accessSecurity: string = 'access-management' + wso2Version + '/lookup';
    static readonly programRestrict: string = 'access-management' + wso2Version + '/programRestrict/~~';
    static readonly programRestrictByUser: string = 'access-management' + wso2Version + '/programRestrictByUser';
    static readonly userStatus: string = 'access-management' + wso2Version + '/userStatus/~~';
    static readonly lookup: string = 'public' + wso2Version + '/lookup';
    static readonly lookupType: string = 'public' + wso2Version + '/lookup?lookupType=DistrictOffice';
    static readonly applicationProcess = 'application-process' + wso2Version + '/screening/~~';
    static readonly activateFeatures = 'public' + wso2Version + '/activate-features';
    // static readonly baseJsonUrlDev: string = './assets/mock-data';
    static readonly localLookupUrl: string = '/lookupsApi.json';
    static readonly householdMember3Url: string = ConstantsService.deploymentMode + '/householdmember3.json';
    static readonly interviewServiceUrl: string = 'application-process' + wso2Version + '/retrieveCases';
    static readonly retrieveCaseNarrativeByCaseId = 'application-process' + wso2Version + '/~~/narrative';
    static readonly getDocumentsForScreen = 'application-process' + wso2Version + '/documents';
    static readonly finalizeServiceUrl: string = ConstantsService.deploymentMode + '/finalizeService.json';
    static readonly customerParticipationUrl: string = 'application-process' + wso2Version + '/inquiry/cdbp-search';
    static readonly cisUrl: string = 'application-process' + wso2Version + '/retrieveCases?moduleType=screening&moduleType=interview';
    static readonly authorizedRepresentativeUrl: string = ConstantsService.deploymentMode + '/authorizedRepresentative.json';
    static readonly addressUSUrl: string = ConstantsService.deploymentMode + '/addressUS.json';
    static readonly elegibilityServiceUrl: string = ConstantsService.deploymentMode + '/elegibilityService.json';
    static readonly scrapHeader = 'application-process' + wso2Version + '/scrap-header';
    static readonly interimChange = 'case-management' + wso2Version + '/cp_icredet_dataview/~~';
    // static readonly deleteissuanceInfo = 'application-process/bi/deleteissuanceInfo/~~';
    public static readonly POST = 1;
    public static readonly GET = 2;
    public static readonly PUT = 3;
    public static readonly DELETE = 10;
    public static readonly DELETE_BODY = 12;
    public static readonly JSONFILE = 4;
    public static readonly FILEUPLOAD = 5;
    public static readonly FILEDOWNLOAD = 6;
    public static readonly FILEBULKDOWNLOAD = 9;
    public static readonly FILEDELETE = 7;
    public static readonly CUSTOMFILEDOWNLOAD = 8;
    public static readonly FILEDOWNLOADGET = 11;
    public static readonly contactInfoUrl = 'application-process' + wso2Version + '/screening/~~/contact';
    public static readonly documentsForACaseUrl = 'application-process' + wso2Version + '/screening/documents/~~';
    public static readonly ldssAddressUrl = 'public' + wso2Version + '/ldss-assistance/~~';
    public static readonly checkForHoh = 'application-process' + wso2Version + '/vaild-hoh/~~';
    public static readonly getWorkItem = 'work-item' + wso2Version + '/assign-work-item?~~';
    public static readonly getWorkItemIdToClose = 'work-item' + wso2Version + '/getWorkItemId/~~';
    public static readonly contactInterview = 'application-process' + wso2Version + '/interview/~~/contact';
    public static readonly HOUSEHOLDCIRCUMSTANCE = '/household-circumstances';
    public static readonly PROGRAM = '/program-selection';
    public static readonly editRedetDueDate = 'case-management' + wso2Version + '/~~/editRedetDueDate/~~';
    public static readonly getRedetHistory = 'application-process' + wso2Version + '/redetHistoryData/~~';
    public static readonly submitRedetDueDate = 'case-management' + wso2Version + '/~~';
    public static readonly SCREENING = 'application-process' + wso2Version + '/screening';
    public static readonly correspondenceGetUrl = 'application-process' + wso2Version + '/cc/search/~~?fromDate=~~&currentDate=~~&canShowManSup=~~';
    public static readonly getArchiveNoticesByIrn: string = 'application-process' + wso2Version + '/cc/search/getArchiveNoticesByIrn';
    public static readonly searchArchiveNotices: string = 'application-process' + wso2Version + '/archival-notices/search';
    public static readonly generateArchiveNotices: string = 'application-process' + wso2Version + '/archival-notices/generate';
    public static readonly generateArchiveNoticesBulk: string = 'application-process' + wso2Version + '/archival-notices/generate-notices';
    public static readonly downloadArchiveNotices: string = 'application-process' + wso2Version + '/archival-notices/download-pdf/~~';
    public static readonly correspondanceInsert = 'application-process' + wso2Version + '/cc/insert/~~';
    public static readonly simplifiedReportGetUrl = 'application-process' + wso2Version + '/inquiry/smpl-rpt-search';
    public static readonly putSimplifiedReportInformationUrl = 'application-process' + wso2Version + '/inquiry/edit-smpl-rpt';
    public static readonly getSrExtDueData = 'application-process' + wso2Version + '/sr-ext-due-dt/~~';
    public static readonly ccidInsert = 'application-process' + wso2Version + '/retrieveEcmDocs';
    public static readonly pdfMerge = 'application-process' + wso2Version + '/cc/pdfMerge/~~';
    public static readonly ccidDelete = 'application-process' + wso2Version + '/cc/delete/~~';
    public static readonly retroMonthsGET = 'application-process' + wso2Version + '/retroMonths/~~/~~';
    public static readonly updateRetroMonth = 'application-process' + wso2Version + '/updateRetroMonths/~~';
    public static readonly getDistrictOffice = 'application-process' + wso2Version + '/screening/DOWorkerInfo';
    static readonly saveClient: string = 'client-management' + wso2Version + '/new';
    static readonly saveClientForCP: string = 'client-management' + wso2Version + '/new/~~';
    static readonly openClosedCase: string = 'public' + wso2Version + '/consumer/renew/~~/~~';
    static readonly checkForIndividualAssistanceCP: string = 'client-management' + wso2Version + '/~~/individual-assistance';
    public static readonly contactInformationDetails = 'application-process' + wso2Version + '/screening/~~/contact-information';
    public static readonly getInterviewSignature = 'application-process' + wso2Version + '/screening/schedule-interview/~~';
    public static readonly taxFilingStatus = 'application-process' + wso2Version + '/interview/~~/tax-information/~~/tax-filing-status';
    public static readonly getDomesticViolenceInfo = 'application-process' + wso2Version + '/~~/household/get-domestic-voilence-details/~~';
    public static readonly individualAddres = 'application-process' + wso2Version + '/interview/~~/household/~~/additional-address';
    public static readonly saveDomesticViolenceInfo = 'application-process' + wso2Version + '/~~/household/save-domestic-voilence-details/~~';
    public static readonly saveVeteranStatusInfo = 'application-process' + wso2Version + '/interview/~~/household/~~/veteran-information';
    public static readonly getFinancialInfo = 'application-process' + wso2Version + '/interview/~~/financial-information';
    public static readonly getFinancialIncome = 'application-process' + wso2Version + '/interview/~~/financial-information/incomes';
    public static readonly getUnearnedIncomeHistory = 'application-process' + wso2Version + '/interview/financial-information/unearned-incomes/~~/income-history';
    public static readonly getUnearnedAmountHistory = 'application-process' + wso2Version + '/interview/financial-information/unearned-incomes/~~/income-amount-history';
    public static readonly getUnearnedExpenseHistory = 'application-process' + wso2Version + '/interview/financial-information/unearned-incomes/~~/income-expense-history';
    public static readonly getContactHistory = 'application-process' + wso2Version + '/screening/~~/contact-information-history';
    public static readonly getFinancialAsset = 'application-process' + wso2Version + '/interview/~~/financial-information/assets';
    public static readonly getCsaPayment = 'application-process/cses' + wso2Version + '/fetch-csa-payment-custoidan-parent/~~';
    public static readonly appealGetUrl: string = 'application-process' + wso2Version + '/finalize/~~/appeal';
    public static readonly appealBenefitAmount: string = 'application-process' + wso2Version + '/finalize/~~/~~/appealBenefitAmount';
    public static readonly appealPostUrl: string = 'application-process' + wso2Version + '/finalize/~~/~~/appealData';
    public static readonly individualProgramsGetUrl: string = 'application-process' + wso2Version + '/interview/household/~~/eid-ics-details';
    public static readonly individualProgramsTCAGetUrl: string = 'application-process' + wso2Version + '/interview/household/~~/~~/tca-jrb';
    public static readonly eidIcsPostUrl: string = 'application-process' + wso2Version + '/interview/household/~~/save-eid-ics-details';
    public static readonly getFinancialExpenses = 'application-process' + wso2Version + '/interview/~~/financial-information/expenses';
    public static readonly genericGetCall = 'application-process' + wso2Version + '/casedetails/~~';
    public static readonly getCounty = 'application-process' + wso2Version + '/screening/get-countyDoWorker-Info';
    public static readonly abawdGetUrl: string = 'application-process' + wso2Version + '/interview/~~/get-abawd-questionaire';
    public static readonly abawdPostUrl: string = 'application-process' + wso2Version + '/interview/~~/save-abawd-questionaire';
    public static readonly householdIndividuals = 'application-process' + wso2Version + '/interview/~~/household/individual';
    public static readonly getListOfHousehold = 'application-process' + wso2Version + '/get-household-members/~~';
    public static readonly getMemberBeginDate = 'application-process' + wso2Version + '/interview/~~/memberBeginDateDetails';
    public static readonly saveLADetails = 'application-process' + wso2Version + '/interview/~~/household/~~/add-living-arrangement';
    public static readonly saveSBDetails = 'application-process' + wso2Version + '/interview/~~/household/~~/add-state-benefits';
    public static readonly savef99Details = 'application-process' + wso2Version + '/interview/~~/household/~~/add-f99-details';
    public static readonly saveIncarnationDetails = 'application-process' + wso2Version + '/interview/~~/household/~~/add-incarceration';
    public static readonly saveX02Details = 'application-process' + wso2Version + '/interview/~~/household/~~/add-x02-details';
    public static readonly getStudent = 'application-process' + wso2Version + '/interview/~~/household/~~/student';
    public static readonly getStudentHistory = 'application-process' + wso2Version + '/interview/household/~~/studentInfo-history';
    public static readonly getSelectedPrograms = 'application-process' + wso2Version + '/screening/~~/selected-program';
    public static readonly getTSS = 'application-process' + wso2Version + '/interview/application/~~/~~';
    public static readonly saveTSS = 'application-process' + wso2Version + '/interview/saveApplication';
    public static readonly getDSNAPIndicator = 'application-process' + wso2Version + '/screening/getDSNAPIndicator/~~';
    public static readonly livingArrangementshistory = 'application-process' + wso2Version + '/interview/household/~~/livingArrangementshistory';
    public static readonly stateBenefitshistory = 'application-process' + wso2Version + '/interview/household/~~/stateBenefitshistory';
    public static readonly domesticviolencehistory = 'application-process' + wso2Version + '/household/~~/get-domestic-voilence-details-history';
    public static readonly strikerstatushistory = 'application-process' + wso2Version + '/household/~~/strikersHistory';
    public static readonly veteranstatushistory = 'application-process' + wso2Version + '/household/~~/veteranstatushistory';
    public static readonly ppihistory = 'application-process' + wso2Version + '/interview/household/~~/primarypreventionhistory';
    public static readonly incarcerationhistory = 'application-process' + wso2Version + '/interview/household/~~/incarcerationhistory';
    public static readonly f99History = 'application-process' + wso2Version + '/interview/household/~~/f99detail-history';
    public static readonly x02History = 'application-process' + wso2Version + '/interview/household/~~/x02detail-history';
    public static readonly getHouseholdDeclaration = 'application-process' + wso2Version + '/interview/household/household-declaration/~~';
    public static readonly postHouseholdDeclaration = 'application-process' + wso2Version + '/interview/household/~~/household-declaration';
    public static readonly ppiPostCall = 'application-process' + wso2Version + '/interview/~~/household/~~/primary-prevention';
    public static readonly consumerNav = 'public' + wso2Version + '/consumer/navigation';
    public static readonly workerNav = 'public' + wso2Version + '/worker/navigation';
    public static readonly workerGetCaseNavs = 'public' + wso2Version + '/worker/~~/getoptionalnavs';
    public static readonly workerSaveNavs = 'public' + wso2Version + '/worker/~~/saveoptionalnavs/~~';
    public static readonly getInstituteInfo = 'application-process' + wso2Version + '/waiver-facility/get-details/~~/~~';
    public static readonly caseHomeDetailsUrl: string = 'application-process' + wso2Version + '/case-home-complete/~~';
    public static readonly caseAssignedToDetails: string = 'application-process' + wso2Version + '/case-workers/~~';
    public static readonly alertListUrl: string = 'application-process' + wso2Version + '/alert/list/~~/get-alertList';
    public static readonly alertListCountUrl: string = 'application-process' + wso2Version + '/alert/count/~~/get-count';
    public static readonly getHealthDisabilityInfo: string = 'application-process' + wso2Version + '/interview/~~/household/~~/get-health-disability';
    public static readonly disabilityHistory: string = 'application-process' + wso2Version + '/interview/household/~~/get-health-disability-history';
    public static readonly wbcchpHistory: string = 'application-process' + wso2Version + '/interview/household/~~/get-wbcchp-history';
    static readonly clearanceSearchUrl: string = 'client-management' + wso2Version + '/clearance';
    static readonly customerDetails: string = 'client-management' + wso2Version + '/~~';
    static readonly individualDetails: string = 'client-management' + wso2Version + '/individual/~~';
    static readonly saveStrikerStatusInfo: string = 'application-process' + wso2Version + '/interview/~~/household/~~/striker-information';
    static readonly retrieveDO: string = 'client-management' + wso2Version + '/retrieve-DO/';
    static readonly transferDO: string = 'client-management' + wso2Version + '/transferDO';
    static readonly getDO: string = 'client-management' + wso2Version + '/getDO/~~';
    static readonly getAllDO: string = 'client-management' + wso2Version + '/allDO';
    public static readonly userAnalytics = 'application-process' + wso2Version + '/user-analytics';
    public static readonly getUserAnalytics = 'application-process' + wso2Version + '/get-user-analytics';
    static readonly saveHouseholdUrl: string = 'application-process' + wso2Version + '/screening/~~/household';
    static readonly scheduleInterview: string = 'application-process' + wso2Version + '/screening/~~/schedule-interview';
    public static readonly getCounties = 'application-process' + wso2Version + '/screening/get-counties';
    public static readonly getCountyOffices = 'application-process' + wso2Version + '/screening/get-county-offices';
    public static readonly getAddress = 'application-process' + wso2Version + '/user-settings/get-address/~~';
    public static readonly saveUserSettings = 'application-process' + wso2Version + '/user-settings/create-user';
    public static readonly resetUserPassword = 'application-process' + wso2Version + '/user-settings/reset-password';
    public static readonly getOfficeWorkers = 'application-process' + wso2Version + '/screening/get-office-workers';
    static readonly withdrawApplication: string = 'application-process' + wso2Version + '/screening/~~/withdraw-application';
    static readonly screeningGeneratePDF: string = 'application-process' + wso2Version + '/screening/~~/summarypdf';
    static readonly interviewsummarypdf: string = 'application-process' + wso2Version + '/interview/~~/interviewsummarypdf';
    static readonly generateMDM = 'application-process' + wso2Version + '/interview/clearance/~~/mdm-id-generation';
    static readonly generateIRN = 'application-process' + wso2Version + '/interview/clearance/~~/getIrn-ma-id';
    static readonly addressVerify: string = 'verification' + wso2Version + '/~~/address';
    static readonly eaAsset: string = 'application-process' + wso2Version + '/interview/~~/emergency-assistance-asset';
    static readonly eaIncome: string = 'application-process' + wso2Version + '/interview/~~/emergency-assistance-income';
    static readonly eaCommunityResource: string = 'application-process' + wso2Version + '/interview/~~/emergency-assistance-community-resource';
    static readonly eaExpense: string = 'application-process' + wso2Version + '/interview/~~/emergency-assistance-expense';
    static readonly eaThirdParty: string = 'application-process' + wso2Version + '/interview/~~/emergency-assistance-thirdParty-payment';
    static readonly emergencyAssistance: string = 'application-process' + wso2Version + '/interview/~~/emergency-assistance';
    static readonly householdVerify: string = 'verification' + wso2Version + '/~~/household';
    static readonly fileUpload: string = 'document' + wso2Version + '/~~/upload';
    static readonly fileDocType: string = 'document' + wso2Version + '/~~/docType';
    static readonly fileUploadCitizenship: string = 'document' + wso2Version + '/~~/uploadCitizenship';
    static readonly fileDownload: string = 'document' + wso2Version + '/~~/download';
    static readonly viewFile: string = 'document' + wso2Version + '/~~/view';
    static readonly fileBulkDownload: string = 'document' + wso2Version + '/bulkDownload';
    static readonly fileDownloadCitizenship: string = 'document' + wso2Version + '/~~/downloadCitizenship'
    static readonly fileDelete: string = 'document' + wso2Version + '/~~/delete';
    static readonly fileDeleteCitizenship: string = 'document' + wso2Version + '/~~/deleteCitizenship';
    static readonly newCase: string = 'application-process' + wso2Version + '/new';
    static readonly saveExistingMember = 'application-process' + wso2Version + '/~~/add-member';
    static readonly applyProgram = 'application-process' + wso2Version + '/screening/~~/apply-program';
    static readonly interviewStudent = 'application-process' + wso2Version + '/interview/~~/household/~~/student';
    static readonly intervHealthDisab = 'application-process' + wso2Version + '/interview/~~/household/~~/health-disability';
    static readonly addMedicare = 'application-process' + wso2Version + '/interview/~~/household/~~/add-medicare';
    static readonly editMedicare = 'application-process' + wso2Version + '/interview/~~/household/~~/edit-medicare';
    static readonly insuranceInformation = 'application-process' + wso2Version + '/interview/~~/household/~~/insurance-info';
    static readonly editExistingMember = 'application-process' + wso2Version + '/~~/edit-member';
    static readonly deleteExistingMember = 'application-process' + wso2Version + '/~~/delete-member';
    static readonly intervInstitution = 'application-process' + wso2Version + '/waiver-facility/save-details/~~/~~';
    static readonly saveInstitute = 'application-process' + wso2Version + '/waiver-facility/save-institute-details/~~/~~';
    static readonly saveWaiver = 'application-process' + wso2Version + '/waiver-facility/save-waiver-details/~~/~~';
    static readonly saveAddInfo = 'application-process' + wso2Version + '/waiver-facility/save-additional-info-details/~~/~~';
    static readonly intervEduDetail = 'application-process' + wso2Version + '/interview/~~/household/~~/student';
    static readonly earnedIncomeHistoryDetails = 'application-process' + wso2Version + '/interview/financial-information/earned-incomes/~~/income-history';
    static readonly earnedIncomeHistoryAmount = 'application-process' + wso2Version + '/interview/financial-information/earned-incomes/~~/income-amount-history';
    static readonly earnedIncomeHistoryExpense = 'application-process' + wso2Version + '/interview/financial-information/earned-incomes/~~/income-expense-history';
    static readonly intervAsset = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/asset';
    static readonly intervInsurance = 'application-process' + wso2Version + '/interview/~~/~~/health-insurance';
    static readonly override = 'application-process' + wso2Version + '/household/~~/program/~~/override/~~';
    static readonly trialBudget = 'application-process' + wso2Version + '/trial-budget/new/eligibility';
    static readonly trialBudgetEligibility = 'application-process' + wso2Version + '/trial-budget/~~/eligibility';
    static readonly county = '/lookupsApi.json';
    static readonly getEligibilityForCase = 'application-process' + wso2Version + '/appdate/~~';
    static readonly getEligibilityDetailsWithFilters = 'application-process' + wso2Version + '/eligibilityResults/~~';
    public static readonly ccTriggerInInterview = 'work-item' + wso2Version + '/~~/create-cc-trigger/~~';
    public static readonly triggerConfirmation = 'application-process' + wso2Version + '/trigger-confirmation/~~';
    static readonly csRequestVerificationInsert = 'application-process' + wso2Version + '/finalize/~~/csRequestVerification';
    static readonly ccTriggerVerificationChecklistInsert = 'application-process' + wso2Version + '/finalize/~~/ccTriggerVerificationChecklistInsert';
    static readonly interviewHouseholdCitizenship = 'application-process' + wso2Version + '/interview/~~/household/~~/citizenship-immigration';
    static readonly interviewHouseholdCitizenshipHistory = 'application-process' + wso2Version + '/interview/household/~~/citizenshiphistory';
    static readonly getCitizenSponsorship = 'application-process' + wso2Version + '/interview/~~/household/~~/get-citizen-sponsorship';
    static readonly saveCitizenSponsorship = 'application-process' + wso2Version + '/interview/~~/household/~~/save-citizen-sponsorship';
    static readonly unearnedIncomeUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/unearned-income';
    static readonly assetsUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/asset-income/new';
    static readonly shelterUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/shelter-expenses';
    static readonly saveshelterUrl = 'application-process' + wso2Version + '/interview/financial-information/save-shelter-utility-expenses/~~';
    static readonly getShelterUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/shelter-utility-expenses';
    static readonly getShelterUtilityHistoryUrl = 'application-process' + wso2Version + '/interview/financial-information/~~/shelter-utility-expenses-history';
    static readonly getShelterHistoryUrl = 'application-process' + wso2Version + '/interview/financial-information/~~/shelter-expense-type-history';
    static readonly getShelterUtilityTypeHistoryUrl = 'application-process' + wso2Version + '/interview/financial-information/~~/shelter-utility-type-history';
    static readonly utilityUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/utility-expense';
    static readonly requestedProgramUrl = 'application-process' + wso2Version + '/interview/~~/requested-program'
    static readonly interviewMedical = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/medical-expenses';
    static readonly interviewMedicalHistory = 'application-process' + wso2Version + '/interview/~~/financial-information/medical-expenses-history';
    static readonly interviewChildSpousal = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/child-spousal-expenses';
    static readonly interviewChildSpousalHistory = 'application-process' + wso2Version + '/interview/~~/financial-information/child-spouse-expenses-history';
    static readonly dependCareUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/dependent-care-expenses';
    static readonly dependCareHistoryUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/dependent-care-expenses-history';
    static readonly earnedIncomeUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/earned-income';
    static readonly earnedIncomeUpdateUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/earned-income';
    public static readonly changeReportingUrl = 'case-management' + wso2Version + '/~~/change-reporting';
    public static readonly taxInformation = 'application-process' + wso2Version + '/interview/~~/tax-filingInformation';
    public static readonly finalizeEligibilityReadOnly = 'application-process' + wso2Version + '/elig-result/~~';
    public static readonly narrativeSearch = 'application-process' + wso2Version + '/narrative/search';
    public static readonly saveNarrative = 'application-process' + wso2Version + '/~~/narrative'
    public static readonly eaInformation = 'application-process' + wso2Version + '/interview/~~/ea-information';
    public static readonly absentParentIdentification = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/identification';
    public static readonly absentParentSdr = 'application-process' + wso2Version + '/interview/household/absent-parent/sdr/~~';
    public static readonly getAbsentParentContactInfo = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/contact';
    public static readonly saveAbsentParentContactInfo = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/contact';
    public static readonly absentParentContact = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/contact';
    public static readonly absentParentEmployment = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/employment';
    public static readonly absentParentOtherIncome = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/~~/employment';
    public static readonly saveAbsentParentEmployer = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/new/~~/employment'
    public static readonly absentParentCourtOrder = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/new/court-order/~~';
    public static readonly getCourtOrder = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/court-order/~~';
    public static readonly editCourtOrder = 'application-process' + wso2Version + '/interview/household/absent-parent/~~/court-order/~~';
    public static readonly insertPayee = 'application-process' + wso2Version + '/interview/~~/household/absent-parent/new/court-order-payee/~~';
    public static readonly updatePayee = 'application-process' + wso2Version + '/interview/household/absent-parent/~~/court-order-payee/~~';
    public static readonly programParticipation = 'application-process' + wso2Version + '/inquiry/program-enterprise-detail-search/~~';
    public static readonly customerSearchUrl = 'application-process' + wso2Version + '/inquiry/customer-search';
    public static readonly enterpriseCustomerSearchUrl = 'application-process' + wso2Version + '/inquiry/customer-enterprise-search';
    public static readonly vendorHistory = 'application-process' + wso2Version + '/inquiry/edit-vendor/~~/~~';
    public static readonly vendorChow = 'application-process' + wso2Version + '/inquiry/chow/~~/~~';
    public static readonly vendorPostChow = 'application-process' + wso2Version + '/inquiry/update-chow';
    public static readonly instituteSearchUrl = 'application-process' + wso2Version + '/inquiry/vendor-search';
    public static readonly editVendorUrl = 'application-process' + wso2Version + '/inquiry/edit-vendor/~~/~~';
    public static readonly updateVendorInfoStatus = 'application-process' + wso2Version + '/inquiry/~~/update-vendor-status/~~';
    public static readonly updateVendorAddress = 'application-process' + wso2Version + '/inquiry/~~/update-vendor-address/~~';
    public static readonly updateVendorRate = 'application-process' + wso2Version + '/inquiry/~~/update-vendor-rate/~~';
    public static readonly updateVendorCommonName = 'application-process' + wso2Version + '/inquiry/~~/update-vendor-commonname/~~';
    public static readonly felonyInformationGet = 'application-process' + wso2Version + '/interview/~~/household/individual/~~/felony';
    public static readonly felonyQuestionGet = 'application-process' + wso2Version + '/interview/household/individual/felony/~~';
    public static readonly felonyInformationPost = 'application-process' + wso2Version + '/interview/~~/household/~~/felony';
    public static readonly felonyInformationHistory = 'application-process' + wso2Version + '/interview/household/~~/household-declaration-history';
    public static readonly felonyQuestionPost = 'application-process' + wso2Version + '/interview/household/~~/felony';
    public static readonly clockTypeGet = 'application-process' + wso2Version + '/inquiry/time-clock/~~/clk-type/~~/caseid/~~';
    public static readonly clockTypeGetHistory = 'application-process' + wso2Version + '/interview/household/~~/time-clock-history';
    public static readonly clockTypePost = 'application-process' + wso2Version + '/inquiry/add-time-clock';
    public static readonly addBoarderInfo = 'application-process' + wso2Version + '/~~/household/new-boarder/~~';
    public static readonly updateBoarderInfo = 'application-process' + wso2Version + '/~~/household/update-boarder/~~';
    public static readonly getBoarderInfo = 'application-process' + wso2Version + '/~~/household/boarder/~~';
    public static readonly getBoarderHistory = 'application-process' + wso2Version + '/interview/household/~~/individualBoarder-history';
    public static readonly getBoarderStatus = 'application-process' + wso2Version + '/household/isBoarder';
    public static readonly getAuthrepdtls = 'application-process' + wso2Version + '/fetch-authrep-dtls/~~';
    public static readonly getAuthrep = 'application-process' + wso2Version + '/~~/auth-rep';
    public static readonly getAuthrepNew = 'application-process' + wso2Version + '/~~/auth-rep/new';
    public static readonly getSchoolRep = 'application-process' + wso2Version + '/~~/auth-rep/waiverDistrict';
    public static readonly saveAuthrep = 'application-process' + wso2Version + '/~~/auth-rep';
    public static readonly sdrUrl = 'EnterpriseSearch/ee-wp-sdr-search';
    public static readonly setCaseActionCode = 'application-process' + wso2Version + '/case-action-cd/~~';
    //public static readonly sdrUrl = 'api/EnterpriseSearch/v1' + wso2Version + '/wordSearch';
    public static readonly unearnedIncomeUpdateUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/unearned-income';
    // public static readonly saveTaxInformationUrl = 'application-process' + wso2Version + '/interview/~~/tax-information';
    public static readonly getPregnancyInformationUrl = 'application-process' + wso2Version + '/interview/pregnancy/~~';
    public static readonly postPregnancyInformationUrl = 'application-process' + wso2Version + '/interview/~~/add-pregnancy/~~';
    public static readonly putPregnancyInformationUrl = 'application-process' + wso2Version + '/interview/~~/edit-pregnancy/~~';
    public static readonly saveHouseholdInfoUrl = 'application-process' + wso2Version + '/interview/~~/household';
    public static readonly caseSearchUrl = 'application-process' + wso2Version + '/inquiry/case-search';
    public static readonly saveFinancialInfoUrl = 'application-process' + wso2Version + '/interview/~~/financial-information';
    public static readonly retrieveOrCreateCase = 'application-process' + wso2Version + '/inquiry/retrieveCustomerCase/~~';
    public static readonly getAppliedUnearnedIncomeUrl = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/unearned-income-applications';
    public static readonly getSchoolAttendance = 'application-process' + wso2Version + '/cc/noticeInformation/~~/~~';
    public static readonly getSchoolAttendanceNew = 'application-process' + wso2Version + '/cc/noticeInformation';
    public static readonly saveManualNotice = 'application-process' + wso2Version + '/cc/manualTemplateInsert';
    public static readonly appliedUnearnedIncomeUrl = 'application-process' + wso2Version + '/interview/financial-information/unearned-income-applications';
    public static readonly editPotentialAssets = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/potentialAsset';
    public static readonly deletePotentialAssets = 'application-process' + wso2Version + '/interview/~~/financial-information/~~/delete-potentialAsset';
    public static readonly interviewMemberIdentificationUrl = 'application-process' + wso2Version + '/interview/~~/household/~~/individual';
    public static readonly administrativeInformationUrl = 'application-process' + wso2Version + '/interview/~~/administrative-information';
    public static readonly getAdminInfoHistory = 'application-process' + wso2Version + '/interview/~~/~~/administrative-information';
    public static readonly conditionalCaseRetrieveUrl = 'application-process' + wso2Version + '/conditionalCaseRetrieve/~~';
    public static readonly initiateFinalizeUrl = 'application-process' + wso2Version + '/finalize/~~';
    public static readonly programStatus = 'application-process' + wso2Version + '/interview/~~/program-status';
    public static readonly getManualNotices = 'application-process' + wso2Version + '/cc/manualNotice/startup/~~';
    public static readonly generatePDF = 'application-process' + wso2Version + '/cc/noticeGeneration/~~';
    public static readonly mabsInterface = 'application-process' + '/verification' + wso2Version + '/MABS/~~';
    public static readonly avsInterface = 'application-process' + '/verification' + wso2Version + '/AVS/~~';
    public static readonly adhocAVSRequest = 'verification' + wso2Version + '/avs/createentry/~~';
    public static readonly mabsHistory = 'application-process' + '/verification' + wso2Version + '/MABSHistory';
    public static readonly eligibilityHistory = 'application-process' + wso2Version + '/elg-prm-histry/~~/~~/~~';
    public static readonly finalizeEligibility = 'application-process' + wso2Version + '/finalize/~~/eligibility';
    public static readonly finalizeEligibilityRunMonths = 'application-process' + wso2Version + '/finalize/~~/run-months';
    public static readonly historySummary = 'application-process' + wso2Version + '/finalize/~~/finalizeEligibilityHistory';
    public static readonly benefitHistorySummary = 'application-process' + wso2Version + '/finalize/~~/~~/finalizeEligibilityHistory';
    public static readonly interviewSummary = 'application-process' + wso2Version + '/interview/~~/summary';
    public static readonly aplhaAssignment = 'application-process' + wso2Version + '/interview/~~/alphaAssignement';
    public static readonly interviewSummarySignature = 'application-process' + wso2Version + '/interview/~~/signature';
    public static readonly programFinalization = 'application-process' + wso2Version + '/finalize/~~/confirm';
    public static readonly getAuthorizedProgramForAUser = 'application-process' + wso2Version + '/finalize/programConfirmation/~~';
    public static readonly waiverStatus = 'application-process' + wso2Version + '/eligibility-case-home/~~';
    public static readonly getAssetHistory = 'application-process' + wso2Version + '/interview/financial-information/history/~~/~~';
    public static readonly getAssetAmountHistory = 'application-process' + wso2Version + '/interview/financial-information/history/amount/~~/~~';
    public static readonly assigncasewrkr = 'application-process' + wso2Version + '/supervisor-review/case/~~/assign-casewrkr';
    public static readonly assignSupervisor = 'application-process' + wso2Version + '/supervisor-review/case/~~/assign-sprvsr';
    public static readonly benefitIssuanceSummary = 'application-process' + wso2Version + '/bi/daily-summary';
    public static readonly benefitIssuanceDetail = 'application-process' + wso2Version + '/bi/daily-summary/details/~~/~~';
    public static readonly benefitIssuanceHistory = 'application-process' + wso2Version + '/bi/daily-summary/history/~~/~~/~~';
    // public static readonly ldssCode = 'application-process' + wso2Version + '/bi/check_inventory/ldss_code';
    public static readonly checkInvtryLdssCode = 'application-process' + wso2Version + '/bi/check_inventory/ldss_code/~~';
    static readonly citizenshipVerify: string = 'verification' + wso2Version + '/~~/citizenship';
    public static readonly prevEligibility: string = 'application-process' + wso2Version + '/screening/~~/prev-eligibility';
    static readonly helpText: string = 'public' + wso2Version + '/help';
    public static readonly reinstantiate: string = 'application-process' + wso2Version + '/rescind/reinstantiate/~~';
    public static readonly reopen: string = 'application-process' + wso2Version + '/rescind/reopening/~~';
    public static readonly ccTriggerChecklist: string = 'application-process' + wso2Version + '/finalize/~~/ccTriggerChecklist';
    public static readonly redetermination = 'case-management' + wso2Version + '/~~/redetermination';
    public static readonly editexparte = 'exparte/update/~~';
    public static readonly addexparte = 'exparte/update/';
    public static readonly redeterminationDetails = 'case-management' + wso2Version + '/~~/redetermination-details';
    public static readonly exparteDetails = 'exparte/~~';
    public static readonly verificationChecklist = 'application-process' + wso2Version + '/interview/verifications/~~';
    public static readonly verifications = 'application-process' + wso2Version + '/interview/~~/verifications';
    public static readonly transfertrgrDetails='application-process/screening/~~/transferTrgrDetails';
    public static readonly transferoutboundDetails='application-process/screening/~~/transferOutboundDetails/~~';
    public static readonly inbox = 'worker-case-management' + wso2Version + '/inbox';
    public static readonly myCase = 'worker-case-management' + wso2Version + '/my-cases';
    public static readonly workItem = 'work-item' + wso2Version + '/assigned-work-items/~~';
    public static readonly assignWorkItem = 'work-item' + wso2Version + '/assign-work-item-Id';
    public static readonly bulletinBoard = 'public' + wso2Version + '/bulletinboard';
    public static readonly workItemSearch = 'work-item' + wso2Version + '/search';
    public static readonly checkCaseId = 'work-item' + wso2Version + '/checkCase/~~';
    public static readonly nextWorkItem = 'work-item' + wso2Version + '/getNext';
    public static readonly caseWorkers = 'work-item' + wso2Version + '/workers';
    public static readonly offboardedWorkers = 'work-item/offboarded-workers';
    public static readonly transferCases = 'cases/client-management/transferDO';
    public static readonly closeWorkItem = 'work-item' + wso2Version + '/close';
    public static readonly workItemCounts = 'work-item' + wso2Version + '/getWorkItemCount';
    public static readonly closeConsumerControlWorkItems = 'work-item' + wso2Version + '/icredet/close';
    public static readonly createWorkItem = 'work-item' + wso2Version + '/create-work-item/~~';
    /** new workitem apis */
    public static readonly createWorkItemNew = 'work-item' + wso2Version + '/create';
    public static readonly generateNewAlert = 'application-process' + wso2Version + '/add-work-item';
    public static readonly getAllWorkersFromDO = 'application-process' + wso2Version + '/get-all-wrkrs/~~';
    public static readonly getAllActiveWorkers = 'application-process' + wso2Version + '/get-all-active-wrkrs';
    public static readonly closeWorkItemNew = 'work-item' + wso2Version + '/close-new';
    public static readonly getWorkItemIdNew = 'work-item' + wso2Version + '/ids/~~';
    public static readonly nextWorkItemNew = 'work-item' + wso2Version + '/get-next';
    public static readonly workItemSearchNew = 'work-item' + wso2Version + '/search-new';
    public static readonly CaseProgramSearchNew = 'work-item' + wso2Version + '/search-program';
    public static readonly getDashboardInfo = 'application-process' + wso2Version + '/dashboard/~~/~~';
    public static readonly getDashboardInfoNew = 'application-process' + wso2Version + '/dashboard-new/~~/~~';
    public static readonly getAlertCount = 'application-process' + wso2Version + '/dashboard/get-alert-counts/~~';

    public static readonly WorkExemption = 'application-process' + wso2Version + '/interview/~~/works-exemption/~~';
    public static readonly CheckWorkExemption = 'application-process' + wso2Version + '/interview/~~/works-exemption';
    public static readonly acceptReview = 'application-process' + wso2Version + '/supervisor-review/case/~~/initiate/~~';
    public static readonly getCoPayWorkers = 'application-process' + wso2Version + '/copay/get-copay-workers';
    public static readonly nursingHomeList = 'public' + wso2Version + '/consumer/Application/nursingHomeList/~~';
    public static readonly crudCoPayWorkers = 'application-process' + wso2Version + '/copay/crud-copay-workers';
    public static readonly reviewApplication = 'application-process' + wso2Version + '/supervisor-review/case/~~/~~/getqstns';
    public static readonly submitReview = 'application-process' + wso2Version + '/supervisor-review/case/saveandsubmit';
    public static readonly identifyReview = 'application-process' + wso2Version + '/supervisor-review/case/~~/identification/~~';
    public static readonly getSupervisorList = 'application-process' + wso2Version + '/supervisor-list/~~';
    public static readonly getUserManagement = 'application-process' + wso2Version + '/user-management/get-users/~~/~~';
    public static readonly getSupervisor = 'application-process' + wso2Version + '/user-management/get-supervisors/~~';
    public static readonly saveUserManagement = 'application-process' + wso2Version + '/user-management/update-pgm-access';

    public static readonly getSupervisorChange = 'application-process' + wso2Version + '/user-management/get-supervisor/~~';
    public static readonly getLdssCode = 'application-process' + wso2Version + '/user-management/get-ldssdocd/~~';
    public static readonly saveSupervisorChange = 'application-process' + wso2Version + '/user-management/change-supervisor';
    public static readonly userSettings = 'application-process' + wso2Version + '/user-settings/retrieve-users';
    public static readonly deleteUserSettings = 'application-process' + wso2Version + '/user-settings/delete-user';
    public static readonly getWorkerRolesList = 'application-process' + wso2Version + '/get-worker-roles/~~';
    public static readonly getDistrictOfficeList = 'application-process' + wso2Version + '/get-district-offices';
    public static readonly getSupervisorTableList = 'application-process' + wso2Version + '/~~/get-supervisor';
    public static readonly saveSupervisorList = 'application-process' + wso2Version + '/save-supervisor';
    public static readonly editSupervisorList = 'application-process' + wso2Version + '/edit-supervisor';
    public static readonly paris = 'verification' + wso2Version + '/paris/fed/~~';
    public static readonly parisHistory = 'verification' + wso2Version + '/paris/fed/history/~~';
    public static readonly parisVet = 'verification' + wso2Version + '/paris/vet/~~';
    public static readonly parisVetHistory = 'verification' + wso2Version + '/paris/vet/history/~~';
    public static readonly parisState = 'verification' + wso2Version + '/paris/state/~~';
    public static readonly parisStateHistory = 'verification' + wso2Version + '/paris/state/history/~~';
    public static readonly searchConsumerARApps = 'public' + wso2Version + '/consumer/Application/arhistory';
    public static readonly legacySearch = 'worker-management' + wso2Version + '/case_legacy_converter/legacy/~~';
    public static readonly legacyCaseSearch = 'worker-management' + wso2Version + '/case_legacy_converter/case/~~';
    public static readonly getHolidays = 'case-management' + wso2Version + '/holidays';
    public static readonly getHolidayDesc = '/case-management' + wso2Version + '/holidaysList';
    public static readonly insertHolidays = 'case-management' + wso2Version + '/insertHoliday';
    public static readonly editHolidays = 'case-management' + wso2Version + '/editHoliday';
    public static readonly getDistOffice = 'client-management' + wso2Version + '/retrieve-DO';
    // public static readonly consumerNav = 'public' + wso2Version + '/consumer/navigation';
    public static readonly spanishManagement = 'public' + wso2Version + '/SUTagManagement';
    public static readonly spanishManagementEdit = 'public' + wso2Version + '/SUTagManagement/edit';
    public static readonly avsRequests = 'verification' + wso2Version + '/avs/request/~~';
    public static readonly avsResponses = 'verification' + wso2Version + '/avs/response/~~';
    public static readonly avsInstitutionNames = 'verification' + wso2Version + '/avs/request/InstitutionNames/~~';
    public static readonly avsInstitutionDetails = 'verification' + wso2Version + '/avs/request/InstitutionDetails/~~';
    public static readonly caseAssignedTo = 'work-item' + wso2Version + '/case-assigned-to/~~';
    public static readonly emailUpdateUrl = 'application-process' + wso2Version + '/interview/account-management-wp/updateEmail';
    public static readonly pwdUpdateUrl = 'application-process' + wso2Version + '/interview/account-management-wp/updatePassword';
    public static readonly addVendor = 'application-process' + wso2Version + '/inquiry/add-vendor';
    public static readonly getRequestedProgram = 'application-process' + wso2Version + '/interview/~~/selected-program';
    public static readonly getCitizensip = 'application-process' + wso2Version + '/interview/~~/household/~~/citizenship-immigration';
    public static readonly getCitizenshipList = 'application-process' + wso2Version + '/interview/~~/household/citizenship-immigration';
    public static readonly editVendor = 'application-process' + wso2Version + '/inquiry/edit-vendor';
    public static readonly getSpousalAssessment = 'application-process' + wso2Version + '/interview/~~/~~/spousal-resource-assessment';
    public static readonly addSRAssessment = 'application-process' + wso2Version + '/interview/~~/spousal-resource-assessment/~~/add';
    public static readonly editSRAssessment = 'application-process' + wso2Version + '/interview/~~/spousal-resource-assessment/~~/edit';
    public static readonly calculateSRAssessment = 'application-process' + wso2Version + '/interview/~~/~~/spousal-resource-assessment/preserved-amount';
    public static readonly getRelations = 'application-process' + wso2Version + '/screening/~~/household-relationship/~~';
    public static readonly saveRelations = 'application-process' + wso2Version + '/screening/~~/household/~~/relationship';
    public static readonly getAbsentParentDetails = 'application-process' + wso2Version + '/interview/household/absent-parent/~~';
    public static readonly eligParam = 'application-process' + wso2Version + '/elg-prm-desc/~~';
    public static readonly editEligParam = 'application-process' + wso2Version + '/eligibility-parameter';
    public static readonly cdcCalendar = 'application-process' + wso2Version + '/cc/cdcCalendar';
    public static readonly logoutApiUrl = 'application-process' + wso2Version + '/loggedout';
    public static readonly editDO = 'client-management' + wso2Version + '/updateDO';
    public static readonly medicaidCard = 'medicaid-card' + wso2Version + '/~~';
    public static readonly caseLockCheck = 'application-process' + wso2Version + '/case-lock';
    public static readonly contactSupport = 'public' + wso2Version + '/customer-support';
    public static readonly contactSupportUpload = 'public' + wso2Version + '/upload-doc/~~/~~';
    public static readonly doName = 'public' + wso2Version + '/get-doName/~~';
    public static readonly dispositionSubmit = 'application-process' + wso2Version + '/verification/paris/audit';
    public static readonly getParisAudit = 'verification' + wso2Version + '/paris/audit/~~/~~';
    public static readonly searchConsumerApps = 'public' + wso2Version + '/consumer/Application/search';
    public static readonly searchLink = 'application-process' + wso2Version + '/inquiry/cp-user-search';
    public static readonly consumerSearch = 'case-management' + wso2Version + '/cp_redet/consumer-control-search';
    public static readonly caseLink = 'application-process' + wso2Version + '/inquiry/cp-case-link';
    public static readonly caseDeLink = 'application-process' + wso2Version + '/inquiry/cp-case-delink';
    public static readonly icredetDownload = 'case-management' + wso2Version + '/cp_ic_download/~~';
    //public static readonly getBenefitSummaryDetails = 'api_web_war_exploded' +wso2Version + '/daily-summary/details/~~/~~';
    public static readonly benefitReissuance = 'application-process' + wso2Version + '/bi/re-issuance/~~';
    public static readonly noticeViewPdf = 'application-process' + wso2Version + '/cc/viewPdf/~~';
    public static readonly issuanceMaintenance = 'application-process' + wso2Version + '/interview/issuance-maintenance'
    public static readonly auxiliaryissuance = 'application-process' + wso2Version + '/bi/ag-num/~~';
    public static readonly getAuxiliaryissuance = 'application-process' + wso2Version + '/bi/axlry-issuance/~~';
    public static readonly getPendAuxiliaryissuance = 'application-process' + wso2Version + '/bi/axlry-issuance/pend/~~';
    public static readonly saveAuxiliaryissuance = 'application-process' + wso2Version + '/bi/axlry-issuance';
    public static readonly auxiliaryissuanceDetails = 'application-process' + wso2Version + '/bi/axlry-issuance';
    public static readonly liableAPI = 'application-process' + wso2Version + '/bv/irsaddress/~~';
    public static readonly claimSummary = 'application-process' + wso2Version + '/bv/claimsummary';
    public static readonly getClaimWorkFlowDetails = 'application-process' + wso2Version + '/bv/get-claim-workflow-details';
    public static readonly searchClaimMaintenance = 'application-process' + wso2Version + '/bv/claim-maintenance/search';
    public static readonly paymentPlanGet = 'application-process' + wso2Version + '/bv/caseprograms/~~';
    public static readonly paymentPlanSave = 'application-process' + wso2Version + '/bv/savepaymentplan';
    public static readonly paymentPlan = 'application-process' + wso2Version + '/bv/paymentplan/~~';
    public static readonly getPaymentPlan = 'application-process' + wso2Version + '/bv/get-payment-plan-details';
    public static readonly fetchPaymentPlanHistory = 'application-process' + wso2Version + '/bv/fetch-payment-plan-history/~~';
    public static readonly fetchClaims = 'application-process' + wso2Version + '/bv/fetch-claims?~~';
    public static readonly recordPaymentPost = 'application-process' + wso2Version + '/bv/addrecordpayment';
    public static readonly recordPaymentReceipt = 'application-process' + wso2Version + '/bv/recordpaymentreceipt';
    public static readonly getMemberDetails = 'application-process' + wso2Version + '/interview/~~/memberDetails';
    public static readonly addDeleteMember = 'application-process' + wso2Version + '/interview/addDeleteMember/~~/~~';
    public static readonly viewMemberHistory = 'application-process' + wso2Version + '/screening/~~/~~/member-details-history';
    public static readonly saveMemberDetails = 'application-process' + wso2Version + '/interview/~~/memberDetails';
    public static readonly getAdditionalSosecList = 'application-process' + wso2Version + '/interview/~~/additionalSoSec';
    public static readonly saveW01 = 'application-process' + wso2Version + '/interview/~~/household/~~/wo1-details';
    public static readonly getIndivdualAssistane = 'application-process' + wso2Version + '/interview/~~/individual-assistance';
    // public static readonly manualNoticesGetUrl = 'application-process' + wso2Version + '/cc/searchManualTrigger/~~?lookupCategoryType=~~&PrgmTxt=~~';
    public static readonly manualNoticesGetUrl = 'application-process' + wso2Version + '/cc/searchManualTrigger/~~';
    public static readonly deleteManualNotice = 'application-process' + wso2Version + '/cc/deleteManualTrigger';
    public static readonly getSocialSecurityBenefits = 'application-process' + wso2Version + '/interview/~~/social-security-benefits/get-ssb-info/~~';
    public static readonly saveSocialSecurityBenefits = 'application-process' + wso2Version + '/interview/~~/social-security-benefits/save-ssb-info/~~';
    public static readonly discrepancy = 'application-process' + wso2Version + '/eligibility/discrepancy/~~';
    public static readonly issuanceInfoGET = 'application-process' + wso2Version + '/bi/~~/issuanceInfo';
    public static readonly issuanceInfoPost = 'application-process' + wso2Version + '/bi/issuanceInfo';
    public static readonly manualNoticeDownload = 'application-process' + wso2Version + '/manualnotice/~~';
    public static readonly recallInitiate = 'application-process' + wso2Version + '/archival/initiate-recall';
    public static readonly getRecallDetails = 'application-process' + wso2Version + '/archival/individualcasedetails/~~';
    public static readonly archiveRecalldata = 'application-process' + wso2Version + '/archival/search';
    public static readonly recallSearchdata = 'application-process' + wso2Version + '/archival/recallSearch';
    // interface URls
    public static readonly searchAPI = 'interfaces' + wso2Version + '/search/all';
    public static readonly svesRequest = 'application-process/interface' + wso2Version + '/svesRequest';
    public static readonly solQiRequest = 'application-process/interface' + wso2Version + '/solqiRequest';
    public static readonly BendexAPI = 'interfaces' + wso2Version + '/bendex/~~/~~';
    public static readonly SDXAPI = 'interfaces' + wso2Version + '/sdx/~~/~~';
    public static readonly prisonerMatchAPI = 'interfaces' + wso2Version + '/prisonerMatch/~~/~~';
    public static readonly fortyQuartersAPI = 'interfaces' + wso2Version + '/fourtyQuarters/~~/~~';
    public static readonly ssnVerificationAPI = 'interfaces' + wso2Version + '/ssnVerification/~~/~~';
    public static readonly getSSNWithoutMask = 'application-process' + wso2Version + '/client-management/get-ssn/~~/~~';
    public static readonly titleIIAPI = 'interfaces' + wso2Version + '/title2/~~/~~';
    public static readonly titleXVIAPI = 'interfaces' + wso2Version + '/title16/~~/~~';
    public static readonly edrsPost = 'interfaces' + wso2Version + '/eDrs/webService';
    public static readonly edrsPostNew = 'application-process/edrs' + wso2Version + '/verify/disqualifications';
    public static readonly fetchDisqualificationDetails = 'application-process/edrs' + wso2Version + '/fetch/disqualificationDetails/~~/~~';
    public static readonly sdxAD = 'interfaces' + wso2Version + '/controlDetails/Sdx';
    public static readonly interfaceAdt = 'interfaces' + wso2Version + 'reference/~~/~~';
    public static readonly searchIntrfcAPI = 'interfaces' + wso2Version + '/edrs/~~/~~';
    public static readonly detaiklsIntrfcAPI = 'interfaces' + wso2Version + '/details';
    public static readonly auditIntrfcAPI = 'interfaces' + wso2Version + '/search';
    public static readonly ssaIntrfcSearchAPI = 'interfaces' + wso2Version + '/ssa/search';
    public static readonly ssaDetailsIntrfcAPI = 'interfaces' + wso2Version + '/ssa/details';
    public static readonly ssaintrfcAdtAPI = 'interfaces' + wso2Version + '/reference/audit';

    //federal Reporting
    public static readonly getWomisClients = 'interfaces' + wso2Version + '/fetchFedRepWomisClients/~~';
    public static readonly getWomisClientsLatest = 'interfaces' + wso2Version + '/getWomisInfo/~~';
    public static readonly getFedRepCaseDetails = 'interfaces' + wso2Version + '/fetchFedRepCaseData/~~/~~';
    public static readonly getSampleCases = 'interfaces' + wso2Version + '/fetchSampleCaseData/~~';
    public static readonly postSampleCases = 'interfaces' + wso2Version + '/updateFedRptCaseDetails';
    public static readonly getWomisClientData = 'interfaces' + wso2Version + '/fetchFedRepWomisClientData/~~/~~';
    public static readonly getSection3And4Details = 'interfaces' + wso2Version + '/fetchSection3And4Details/~~';
    public static readonly getQuarterlyFileDetails = 'interfaces' + wso2Version + '/fetchQuarterlyFileDetails/~~/~~';
    public static readonly getSystemParmsDetails = 'interfaces' + wso2Version + '/fetchFedRepParms/~~';
    public static readonly saveWomisClientDetails = 'interfaces' + wso2Version + '/updateWomisClientDetails';
    public static readonly saveFedRepCaseDetails = 'interfaces' + wso2Version + '/updateFedRptCaseDetails';
    public static readonly saveQuarterlyFileDetails = 'interfaces' + wso2Version + '/updateFedRptQuarterlyFile';
    public static readonly saveSystemParmsDetails = 'interfaces' + wso2Version + '/updateFedRptParmsDetails';
    public static readonly saveSection3And4Details = 'interfaces' + wso2Version + '/updateFedRptSection3And4Details';

    //state reporting
    public static readonly getTwoParentDetails = 'interfaces' + wso2Version + '/two-parent-details/~~~~';
    public static readonly getTwoParentSummary = 'interfaces' + wso2Version + '/two-parent-summary/~~~~';
    public static readonly getDeepCaseSummary = 'interfaces' + wso2Version + '/deep-case-summary/~~~~';
    public static readonly getDeepCaseDetails = 'interfaces' + wso2Version + '/deep-case-details/~~~~';
    public static readonly getSspsDetails = 'interfaces' + wso2Version + '/ssps-details/~~~~';
    public static readonly getSspsSummary = 'interfaces' + wso2Version + '/ssps-summary/~~~~';
    public static readonly getWorkParticipationDetails = 'interfaces' + wso2Version + '/work-participation-details/~~~~';
    public static readonly getWorkParticipationSummary = 'interfaces' + wso2Version + '/work-participation-summary/~~~~';
    public static readonly getStateReportingDetails = 'interfaces' + wso2Version + '/state-reporting-details/~~/~~/~~';


    //mmis Details
    public static readonly getUserMmisFetch = 'application-process' + wso2Version + '/userMmisDetails/~~';
    public static readonly getUser206cFetch = 'application-process' + wso2Version + '/user206cDetails/~~';
    public static readonly screen8Mmis = 'application-process' + wso2Version + '/screen8-8001MMIS';
    public static readonly screen8206c = 'application-process' + wso2Version + '/screen8-206c';
    public static readonly screen8SplPrgm = 'application-process' + wso2Version + '/screen8-spclpgm';
    public static readonly getMmisCtad = 'application-process' + wso2Version + '/mmisCtad';

    public static readonly availableTimeSlots = 'public' + wso2Version + '/schedule-interview/get-time-slots-availabile';
    //SDNH and NDNH
    public static readonly getSdnhDetails = 'interfaces' + wso2Version + '/sdnh/master/~~';
    public static readonly getNdnhDetails = 'interfaces' + wso2Version + '/ndnh/master/~~';
    public static readonly getWagesMabsDetails = 'interfaces' + wso2Version + '/search';

    public static readonly getAccretionDeletion = 'interfaces' + wso2Version + '/controlDetails/Sdx';

    public static readonly getLdssCapacity = 'case-management' + wso2Version + '/ldss-capacity/~~';
    public static readonly saveLdssCapacity = 'case-management' + wso2Version + '/ldss-capacity/~~';
    public static readonly getNoOfHours = 'case-management' + wso2Version + '/no-of-hours/~~';
    public static readonly assetInstitution = 'verification' + wso2Version + '/avs/search/institution-search';
    public static readonly getAvailableTimeSlots = 'application-process' + wso2Version + '/get-available-slots/~~';
    public static readonly getScheduledTime = 'application-process' + wso2Version + '/get-appointment/~~';
    public static readonly saveTimeSlot = 'application-process' + wso2Version + '/save-time-slots/~~';
    public static readonly getWorkPrograms = 'application-process' + wso2Version + '/interview/~~/get-work-program-details/~~';
    public static readonly getWorkPlans = 'application-process' + wso2Version + '/interview/~~/get-work-plan-details/~~';
    public static readonly getWorksDetails = 'application-process' + wso2Version + '/interview/~~/get-works-details/~~/~~';
    public static readonly saveWorksDetails = 'application-process' + wso2Version + '/interview/~~/save-works-details/~~';
    public static readonly saveWorkPlanDetails = 'application-process' + wso2Version + '/interview/~~/save-work-plan-details';
    public static readonly saveAssessmentData = 'application-process' + wso2Version + '/finalize/~~/~~/assesmentData';
    public static readonly getAssessmentData = 'application-process' + wso2Version + '/finalize/~~/~~/assesment';
    public static readonly getWorksVendorList = 'application-process' + wso2Version + '/interview/~~/get-works-vendor-info';
    public static readonly getSanctions = 'application-process' + wso2Version + '/get-sanctions/~~/~~';
    public static readonly getDisqualifications = 'application-process' + wso2Version + '/interview/~~/~~/get-disqualification';
    public static readonly getPenalty = 'application-process' + wso2Version + '/interview/~~/get-penalty';
    public static readonly editPenalty = 'application-process' + wso2Version + '/interview/~~/edit-penalty';
    public static readonly edrsDetailsApi = 'interfaces' + wso2Version + '/fetchEdrs/~~/~~';
    public static readonly saveSanctions = 'application-process' + wso2Version + '/save-sanctions/~~/~~';
    public static readonly saveDisqualifications = 'application-process' + wso2Version + '/interview/~~/~~/disqualification';
    public static readonly getNonCompliance = 'application-process' + wso2Version + '/interview/~~/get-non-compliance-details/~~';
    public static readonly getNonComplianceHistory = 'application-process' + wso2Version + '/interview/~~/get-non-compliance-details-history/';
    public static readonly saveNonCompliance = 'application-process' + wso2Version + '/interview/~~/save-non-compliance-details/~~';
    public static readonly recoverySummary = 'application-process' + wso2Version + '/bv/recoverysummary';
    public static readonly fetchClaimsForReview = 'application-process' + wso2Version + '/bv/fetch-claims-for-review';
    public static readonly bvClaimReason = 'application-process' + wso2Version + '/bv/clmReason';
    public static readonly bvSubmitClaimsToSupervisor = 'application-process' + wso2Version + '/bv/submit-claims-to-supervisor';
    public static readonly claimWorkItem = 'application-process' + wso2Version + '/bv/claimWorkItem';
    public static readonly viewMonthlyClm = 'application-process' + wso2Version + '/bv/viewMonthlyClaim/~~';
    public static readonly fetchAgedSubsequentClaims = 'application-process' + wso2Version + '/bv/fetchAgedSubsequentClaims/~~';
    public static readonly clmMaintSave = 'application-process' + wso2Version + '/bv/saveClaimDetails/';
    public static readonly fetchIssnDtls = 'application-process' + wso2Version + '/bv/fetch-issuance-dtls';
    public static readonly saveManualClm = 'application-process' + wso2Version + '/bv/savemanualclaim/';
    public static readonly liableParties = 'application-process' + wso2Version + '/bv/liableparties';
    public static readonly compromisedClaim = 'application-process' + wso2Version + '/claim/compromised';
    public static readonly irsAddress = 'application-process' + wso2Version + '/bv/irsaddress';
    public static readonly irsAddressSave = 'application-process' + wso2Version + '/bv/irsaddress/save';
    public static readonly adjustmentClaim = 'application-process' + wso2Version + '/claim/adjusted';
    public static readonly transferHist = 'application-process' + wso2Version + '/claim/transfer/~~';
    public static readonly miscellaneousHist = 'application-process' + wso2Version + '/claim/miscellaneous/~~';
    public static readonly issuanceInfo = 'application-process' + wso2Version + '/bi/issuanceInfo/~~/~~';
    public static readonly issuanceInfoBibv = 'application-process' + wso2Version + '/bi/issuanceInfo/~~';
    public static readonly saveIssuanceInfo = 'application-process' + wso2Version + '/bi/saveissuanceInfo/~~';
    public static readonly getIssuanceQueue = 'application-process' + wso2Version + '/bi/axlry-issuance/queuesearch';
    public static readonly saveIssuanceQueue = 'application-process' + wso2Version + '/bi/axlry-issuance/issuancequeue';

    public static readonly eaIssnSearch = 'application-process' + wso2Version + '/bi/ea-issuance/queuesearch';
    public static readonly eaIssnSave = 'application-process' + wso2Version + '/bi/ea-issuance/issuancequeue';

    public static readonly fetchIssuanceInfo = 'application-process' + wso2Version + '/bi/fetch-issuance-mthd-info';
    public static readonly saveFutureIssuance = 'application-process' + wso2Version + '/bi/save-future-issuance-mthd';
    public static readonly issuanceMthd = 'application-process' + wso2Version + '/bi/issuance-mthd/~~/~~';
    public static readonly saveIssuanceMthd = 'application-process' + wso2Version + '/bi/save-issuance-mthd/~~';
    public static readonly auditTrailSearch = 'application-process' + wso2Version + '/audit-trail/search';

    // reinstantiation-reopen screens
    public static readonly validateCase = 'application-process' + wso2Version + '/reinstate-reopen-programs/~~'
    public static readonly validateReinstantiation = 'application-process' + wso2Version + '/validate-reinstantiation/~~';
    public static readonly validateReopen = 'application-process' + wso2Version + '/validate-reopen/~~';
    public static readonly reinstateProgram = 'application-process' + wso2Version + '/rescind/reinstantiate/~~';
    public static readonly reopenProgram = 'application-process' + wso2Version + '/rescind/reopening/~~';

    //extrnal mdm
    public static readonly getMDMAllWorkItem = 'external' + wso2Version + '/mdm/individual/workitem';
    public static readonly getFilteredMDMWorkItems = 'external' + wso2Version + '/mdm/individual/workitems';
    public static readonly getMDMGoldenData = 'application-process' + wso2Version + '/mdm/individual/goldendata/~~';
    public static readonly postMdmMergeData = 'application-process' + wso2Version + '/mdm/individual/mdmmergedata';

    //wagg screen
    public static readonly saveWag = 'application-process' + wso2Version + '/interview/wag/~~/save-wag/~~';
    public static readonly getWag = 'application-process' + wso2Version + '/interview/wag/~~/get-wag/~~';
    public static readonly getAgHead = 'application-process' + wso2Version + '/interview/aghead/~~';
    // eligibility
    public static readonly programrunmonthclaims = 'application-process' + wso2Version + '/programrunmonthclaims';
    public static readonly transferClaim = 'application-process' + wso2Version + '/claim/transfer';
    public static readonly saveMiscellaneousClaim = 'application-process' + wso2Version + '/claim/saveMiscellaneousClaim';
    public static readonly saveClaimTransfer = 'application-process' + wso2Version + '/bv/save-claim-transfer';

    //Voter Registration
    public static readonly saveVoterRegDetails = 'public' + wso2Version + '/consumer/saveVoterRegistrationApplicationWP';
    public static readonly downloadVoterApp = 'public' + wso2Version + '/consumer/downloadVoterRegWP/~~/~~';
    public static readonly getVoterDetails = 'public' + wso2Version + '/consumer/retrieveVoterRegistrationWP/~~';
    static readonly citizenshipVoterRegistration = 'application-process' + wso2Version + '/interview/~~/household/~~/voterRegistration';

    //Check Issuance
    public static readonly searchCheckInssuance = 'application-process' + wso2Version + '/bi/checkissuancesearch/~~';
    public static readonly createCheckInssuance = 'application-process' + wso2Version + '/bi/newcheckissuance';
    public static readonly editCheckInssuance = 'application-process' + wso2Version + '/bi/editcheckissuance';
    public static readonly deletedCheckInssuance = 'application-process' + wso2Version + '/bi/deletecheckissuance';
    public static readonly checkNumIss = 'application-process' + wso2Version + '/bi/checknumisexist ';

    //individualPrograms-dsnap
    public static readonly getDsnap = 'application-process' + wso2Version + '/interview/household/~~/eid-ics-details';
    public static readonly postDsnap = 'application-process' + wso2Version + '/interview/~~/household/~~/save-dsnap-details';

    //appointmentScheduling Dashboard
    public static readonly saveException = 'public' + wso2Version + '/schedule-interview/save-availability-exception';
    public static readonly getSupervisorDashboard = 'public' + wso2Version + '/schedule-interview/get-supervisor-dashboard';
    public static readonly saveInterviewSchedule = 'public' + wso2Version + '/schedule-interview/save-interview-schedule';
    public static readonly getSupervisorUnassigned = 'public' + wso2Version + '/schedule-interview/get-supervisor-dashboard-unassigned';
    public static readonly getSupervisorAssigned = 'public' + wso2Version + '/schedule-interview/get-supervisor-dashboard-assigned';
    public static readonly getCaseWorkerDashboard = 'public' + wso2Version + '/schedule-interview/get-caseworker-dashboard';
    public static readonly assignCaseWorker = 'public' + wso2Version + '/schedule-interview/assign-caseworker';
    public static readonly getSupervisorHistory = 'public' + wso2Version + '/schedule-interview/get-supervisor-dashboard-history';
    /* tslint:enable */
    public static readonly searchIssuanceMain = 'application-process' + wso2Version + '/interview/issuance-maintenance/get-issuance';
    public static readonly saveIssuanceMain = 'application-process' + wso2Version + '/interview/issuance-maintenance/save-reissuance';
    public static readonly getSpvsrIssuanceMain = 'application-process' + wso2Version + '/interview/issuance-maintenance/get-pending-issnc-requests';
    public static readonly getTimeslotsForMember = 'public' + wso2Version + '/schedule-interview/get-time-slots-availabile';

    /** bibv > refund */
    public static readonly getRefundInfo = 'application-process' + wso2Version + '/bi/excesspaymentsearch';
    // public static readonly getRefundInfo = 'application-process' + wso2Version + '/bi/refundinfo/~~/~~';
    public static readonly excessOverpaidDetails = 'application-process' + wso2Version + '/bi/excessoverpaidaddressdetails';
    public static readonly saveRefundInfo = 'application-process' + wso2Version + '/bi/saverefundinfo';
    public static readonly saverefund = 'application-process' + wso2Version + '/bi/saverefund';
    public static readonly refundHistory = 'application-process' + wso2Version + '/bi/refundhistory';
    public static readonly searchManualIssuance = 'application-process' + wso2Version + '/bi/manual-issuance/search';
    public static readonly saveManualIssuance = 'application-process' + wso2Version + '/bi/manual-issuance/~~';
    public static readonly calRefund = 'application-process' + wso2Version + '/bi/calculaterefundamt';
    public static readonly getWaiverWorkers = 'application-process' + wso2Version + '/interview/get-waiver-workers';
    public static readonly submitRefund = 'application-process' + wso2Version + '/bi/submitrefund';
    public static readonly retrieveDocuments = 'application-process' + wso2Version + '/bv/retrieveDocuments/~~/~~';

    // IAR SCREENS
    public static readonly iarSummary = 'application-process' + wso2Version + '/bv/iarSummary';
    public static readonly saveIarSummary = 'application-process' + wso2Version + '/bv/saveIarSummaryDetails';

    // BIBV > EBT Issuance Selection
    public static readonly getEbtAvailability = 'application-process' + wso2Version + '/bi/get-ebt-availability';
    public static readonly fetchEbtAvailability = 'application-process' + wso2Version + '/bi/fetch-ebt-availability/~~';
    public static readonly insertbtAvailability = 'application-process' + wso2Version + '/bi/insert-new-ebt-availability';

    //Assigning work item
    public static readonly getUnassignedWorkItem = 'application-process' + wso2Version + '/get-unassigned-cases/~~/~~';
    public static readonly caseMassAssignment = 'application-process' + wso2Version + '/case-mass-assignment/~~';
    public static readonly caseReAssignment = 'application-process' + wso2Version + '/re-assign-cases/~~';
    public static readonly getAssignedWorkItem = 'application-process' + wso2Version + '/get-assigned-cases/~~/~~';
    public static readonly alertDisposition = 'work-item' + wso2Version + '/close-new';
    public static readonly getOverDueItem = 'work-item' + wso2Version + '/overdue-alerts/~~/~~';
    public static readonly getSpecifiedLookupList = 'public' + wso2Version + '/consumer/lookup-search';
    public static readonly sendEmailNotification = 'public' + wso2Version + '/schedule-interview/email/~~';

    // recoupment
    public static readonly getRecoupment = 'application-process' + wso2Version + '/bv/recoupment/get-details/~~/~~/~~';
    public static readonly getRecoupmentDetails = 'application-process' + wso2Version + '/bv/recoupment/get-recovery-details/~~';
    public static readonly getRecoupmentHistorySummary = 'application-process' + wso2Version + '/bv/recoupment/fetch-recoupment-history-details/~~/~~/~~/~~';

    // mass issuance
    public static readonly getMassIssuance = 'application-process' + wso2Version + '/bi/get-mass-issuances';
    public static readonly saveMassIssuance = 'application-process' + wso2Version + '/bi/save-mass-issuances';

    //CP manual import calls
    public static readonly saveNewMemberInCP = 'public' + wso2Version + '/consumer/member';
    public static readonly editMemberInCP = 'public' + wso2Version + '/consumer/member-edit/~~';
    public static readonly updateConsumerApps = 'public' + wso2Version + '/consumer/Application';
    public static readonly cpImportJsonRetrieve = 'public' + wso2Version + '/consumer/jsonRetrieve';
    public static readonly cpImportJsonSave = 'public' + wso2Version + '/consumer/jsonSave/~~/~~';
    public static readonly cpScreenIdLookup = 'public' + wso2Version + '/consumer/screenId-lookup';
    public static readonly cpImportDataJsonRetrieve = 'case-management' + wso2Version + '/cp_case_creation_dataview/~~';
    public static readonly cpImportCaseSave = 'case-management' + wso2Version + '/~~/cp_import_case/~~';
    public static readonly download9701Form = 'case-management' + wso2Version + '/cp_9701_download/~~';
    public static readonly triggerCPBatch = 'import' + wso2Version + '/members-cleared/~~/~~';
    public static readonly checkCaseIdFromCP = 'application-process' + wso2Version + '/checkCaseSourceSystem/~~';
    public static readonly generateNotificationInCP = 'application-process' + wso2Version + '/insertNotification';
    public static readonly confirmCaseImport = 'public' + wso2Version + '/consumer/dataInsertAfterClearance/~~';
    public static readonly getProgramRequestHistory = 'application-process' + wso2Version + '/interview/~~/~~/programRequestHistory';
    public static readonly getCountyForContactsupport = 'public' + wso2Version + '/get-doNameAndCounty/~~';
    public static readonly invalidCase = 'public' + wso2Version + '/consumer/update/~~';
    public static readonly downloadCPDoc = 'document/~~/download';

    //cp intake case home
    public static readonly cpWpOpenEndDateRecords = 'case-management' + wso2Version + '/casedataview/~~';
    public static readonly cpIntakeCaseHome = 'case-management' + wso2Version + '/wp/consumer-control-search';
    public static readonly checkForIndividualInArcheive = 'wp' + wso2Version + '/recall/individual';
    public static readonly getInvalidDetials = 'public' + wso2Version + '/consumer/getInvalidDetails/~~';
    public static readonly getDocumentsFromCP = 'document/~~/cp_view_document';

    // Help Doc
    public static readonly uploadDoc = 'document' + wso2Version + '/cp-public-docs/upload';
    public static readonly getDoc = 'document' + wso2Version + '/cp-public-docs/view-all/~~';
    // public static readonly downloadDoc = 'document' + wso2Version + '/cp-public-docs/view/~~/~~';

    public static readonly downloadDoc = 'unauthenticated' + wso2Version + '/document/cp-public-docs/view/~~/~~';
    // cses
    public static readonly csesCaseSummary = 'application-process' + wso2Version + '/cses/fetch-ivd-case/~~';
    public static readonly csesPaymentDetails = 'application-process' + wso2Version + '/cses/fetch-payment-details/~~';
    public static readonly csesTcaCaseDetails = 'application-process' + wso2Version + '/cses/fetch-tca-case-dtls/~~';
    public static readonly suppressReason = 'application-process' + wso2Version + '/cc/suppressCorrespondenceManually/~~';
    public static readonly regenerate = 'application-process' + wso2Version + '/cc/generateCcPdf/~~';
    public static readonly reopenCorrespondence = 'application-process' + wso2Version + '/cc/removeCcSupression/~~';

    //worksinData
    public static readonly summarizeDataToWorks = 'application-process' + wso2Version + '/finalize/workflow/xfer/~~';
    public static readonly availableSlotsBasedOnLdss = 'case-management' + wso2Version + '/get-available-slots-ldss/~~/~~';

    //screenshot file upload
    public static readonly saveScreenshotFileToDB = 'application-process' + wso2Version + '/savescreenshot';

    public static readonly getLdssOfficeCode = 'public' + wso2Version + '/get-ldss-office/~~';
    public static readonly getDefectStatus = 'public' + wso2Version + '/get-status/~~';
    public static readonly getListOfJIRATickets = 'public' + wso2Version + '/customer-support/list-of-tickets/~~';
    public static readonly saveApplicationDate = 'application-process' + wso2Version + '/interview/~~/edit-programApplicationDate';

    public static readonly downloadCPForm = 'case-management' + wso2Version + '/cp_9701_download/~~';
    public static readonly saveDsnap = 'application-process' + wso2Version + '/saveDSNAP/~~';
    public static readonly getDsnapRecords = 'application-process' + wso2Version + '/getDSNAPRecord/~~';


    public lookUpModuleName: Map<string, boolean>;
    fileUploadtProgress$;
    fileUploadProgressObserver;
    private loaderStatus = new Subject<any>();
    personalId = '7c438d573165cdd1552231ed3bdd33'; // please add your personal id

    constructor(@Inject(DOCUMENT) private document: Document,
        private http: HttpClient,
        private storageService: StorageService,
        private slimLoadingBarService: SlimLoadingBarService,
        private alertService: AlertService,
        private emitter: EventEmitterService,
        private loader: LoaderService,
        private spinnerService: NgxSpinnerService) {
        this.fileUploadtProgress$ = Observable.create(observer => {
            this.fileUploadProgressObserver = observer
        }).pipe(share());
        this.lookUpModuleName = new Map<string, boolean>();
        this.lookUpModuleName.set('screening', false);
        this.lookUpModuleName.set('interview', false);
        this.lookUpModuleName.set('trialbudget', false);
        this.lookUpModuleName.set('inquiry', false);
        this.lookUpModuleName.set('benefitIssue', false);
        this.lookUpModuleName.set('benefitRecovery', false);
    }

    private emitTimer() {
        this.emitter.broadcast('timer', null);
    }

    getLoaderStatus(): Observable<any> {
        return this.loaderStatus.asObservable();
    }

    public makeFileRequest(url: string, params: any): Observable<HttpResponse<any>> {
        return Observable.create(observer => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            for (const key in params) {
                if (key) {
                    formData.append(key, params[key]);
                }
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        this.emitTimer();
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                this.fileUploadProgressObserver.next(Math.round(event.loaded / event.total * 100));
                this.emitTimer();
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept-Language', 'en-US');
            if (environment.addForgerockHeaders) {
                xhr.setRequestHeader('uid', 'localUser');
                xhr.setRequestHeader('uniqueid', this.personalId);
                xhr.setRequestHeader('role', 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,cn=EE_SUPUSR');
                xhr.setRequestHeader('caseId', ConstantsService.humanServiceModel.benefitsCase.caseId);
            }
            xhr.send(formData);
            this.emitTimer();
        });
    }

    public makeSdrRequest(url: string, params: any): Observable<HttpResponse<any>> {
        console.log('params sdr', params);
        return Observable.create(observer => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            for (const key in params) {
                if (key && params[key] && params[key] != '' && params[key] != undefined) {
                    formData.append(key, params[key]);
                }
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        this.emitTimer();
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };


            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept-Language', 'en-US');
            if (environment.addForgerockHeaders) {
                console.log('add sdr heafers');
                xhr.setRequestHeader('uid', 'localuser');
                xhr.setRequestHeader('uniqueid', this.personalId);
                xhr.setRequestHeader('role', 'cn=EE_SUPER,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_SCREE,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_FINAL,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_INTER,ou=groups,dc=openam,dc=forgerock,dc=org|cn=Internal Users,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_SUPUSR,ou=groups,dc=openam,dc=forgerock,dc=org');
                xhr.setRequestHeader('caseId', ConstantsService.humanServiceModel.benefitsCase.caseId);
            }
            this.emitTimer();
            xhr.send(formData);
        });
    }

    clientLogout() {
        this.document.location.href = ConstantsService.logoutUrl;
    }

    fetchData(url: string,
        params: any,
        requestType: number,
        body: any,
        successMethod?: Function,
        failureMethod?: Function,
        queryParams?: string[],
        file?: File) {

        this.loader.show();
        let result: Observable<HttpResponse<any>>;
        let api_response: string;
        let finalUrl: string = ApiManager[url];

        this.slimLoadingBarService.start(() => {
            console.log('Loading complete');
        });
        ConstantsService.loaderFlag = true;
        this.spinnerService.show();

        if (finalUrl && finalUrl.includes('~~') && queryParams != undefined && queryParams.length > 0) {
            for (let i = 0; i < queryParams.length; i++) {
                finalUrl = finalUrl.replace('~~', queryParams[i]);
            }
        }

        if (url === 'lookup') {
            const moduleName = params.moduleName;
            if (!this.localLookUps[moduleName]) {
                result = this.http.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptionsJsonResponse(params));
                console.log('lookup call is made');
                this.localLookUps[moduleName] = result;
            }
            else {
                this.getOptionsWithRequestOptions(params);
                result = this.localLookUps[moduleName]
            }
            // result = this.http.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptions(params));
        }
        else {
            if (requestType === ApiManager.GET) {
                result = this.http.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptionsJsonResponse(params));
            }
            else if (requestType === ApiManager.POST && url != 'sdrUrl') {
                result = this.http.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptionsJsonResponse(params, true));
            }
            else if (requestType === ApiManager.POST && url == 'sdrUrl') {
                result = this.makeSdrRequest(environment.sdrUrl + finalUrl, body);
            }
            else if (requestType === ApiManager.PUT) {
                result = this.http.put(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptionsJsonResponse(params));
            }
            else if (requestType === ApiManager.DELETE) {
                result = this.http.delete(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptionsJsonResponse(params));
            }
            else if (requestType === ApiManager.DELETE_BODY) {
                result = this.http.delete(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptionsJsonResponseWithBody(params, body));
            }
            else if (requestType === ApiManager.FILEUPLOAD) {
                result = this.makeFileRequest(environment.baseUrlDev + finalUrl, params);
            }
            else if (requestType === ApiManager.FILEDOWNLOAD) {
                result = this.http.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptionsBlobResponse(params, true));
            }
            else if (requestType === ApiManager.FILEDOWNLOADGET) {
                result = this.http.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptionsBlobResponse(params, false));
            }
            else if (requestType === ApiManager.FILEBULKDOWNLOAD) {
                result = this.http.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptionsBlobResponse(params, true));
            }
            else if (requestType === ApiManager.CUSTOMFILEDOWNLOAD) {
                result = this.http.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptionsBlobResponse(params, false));
            }
            else if (requestType === ApiManager.FILEDELETE) {
                result = this.http.post(environment.baseUrlDev + finalUrl, body, this.getOptionsWithRequestOptionsJsonResponse(params, true));
            }
        }
        return result.pipe(map((res: HttpResponse<any>) => {
            this.loader.hide();
            // if(requestType == ApiManager.FILEBULKDOWNLOAD || requestType == ApiManager.FILEDOWNLOAD) {
            //     return res;
            // }
            ConstantsService.loaderFlag = false;
            this.spinnerService.hide();
            api_response = JSON.stringify(res);
            if (api_response.includes('DHS Login')) {
                this.clientLogout();
            }
            else {
                console.log('API Call Success');
                this.slimLoadingBarService.complete();
                this.emitTimer();
                if (successMethod) {
                    successMethod();
                }
                if (res.body) {
                    return res.body;
                }
                else {
                    return res;
                }
            }
        }), catchError((error: HttpErrorResponse | any) => {
            this.loader.hide();
            this.spinnerService.hide();
            ConstantsService.loaderFlag = false;
            console.log('API call failure: Catch');
            if (error.error instanceof Blob) {
                this.handleBlobError(error);
            }
            else if (error.error) {
                let errorMessage = error.error;
                let displayErrorMsg = '';
                if (!errorMessage['userMessages']) {
                    displayErrorMsg = 'Unknown Issue';
                }
                else {
                    displayErrorMsg = errorMessage.userMessages[0].message;
                }
                this.alertService.error(displayErrorMsg, error.status);
            }
            // this.alertService.error('Error : Unexpected error.');
            this.document.body.scrollTop = 0;
            window.scrollTo(0, 0);
            this.slimLoadingBarService.stop();
            let errMsg = '';
            let errorStatus; // status code for response
            let errorShortMessage; // short message of exception thrown
            let errorJson; // JSON of exception thrown
            if (!(error instanceof ErrorEvent) && !(error instanceof Error)) {
                const errorBody = error.error || '';
                errorJson = errorBody.error || JSON.stringify(errorBody);
                if (!errorJson && error['_body']) {
                    errorJson = error['_body'];
                }
                errorStatus = error.status;
                errorShortMessage = error.statusText || '';
                errMsg = `${errorStatus} - ${errorShortMessage} ${errorJson}`;
            }
            else {
                errMsg = error.message ? error.message : error.toString();
            }
            // console.error('error ' + errMsg);
            if (failureMethod !== undefined) {
                failureMethod();
            }
            // this._utilService.hideLoader();
            this.loaderStatus.next(false);
            return observableThrowError(errMsg);
        }));
    }

    handleBlobError(error: HttpErrorResponse) {
        // if (error.headers.get('Content-Type') == 'application/json') {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
            let errorMsg = JSON.parse((<any>e.target).result);
            let displayErrorMsg = '';
            if (!errorMsg['userMessages']) {
                displayErrorMsg = 'Unknown Issue';
            }
            else {
                displayErrorMsg = errorMsg.userMessages[0].message;
            }
            this.alertService.error(displayErrorMsg, error.status)
        });
        reader.readAsText(error.error);
        // }
    }

    getOptionsWithRequestOptionsJsonResponseWithBody(params: any, body: any, addHeader: boolean = false): {
        headers?: HttpHeaders;
        observe: 'response';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType: 'json';
        withCredentials?: boolean;
        body?: any;
    } {
        return { ...this.getOptionsWithRequestOptions(params, addHeader), responseType: 'json', observe: 'response', body: body };
    }

    getOptionsWithRequestOptionsJsonResponse(params: any, addHeader: boolean = false): {
        headers?: HttpHeaders;
        observe: 'response';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType: 'json';
        withCredentials?: boolean;
    } {
        return { ...this.getOptionsWithRequestOptions(params, addHeader), responseType: 'json', observe: 'response' };
    }

    getOptionsWithRequestOptionsBlobResponse(params: any, addHeader: boolean = false): {
        headers?: HttpHeaders;
        observe: 'response';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType: 'blob';
        withCredentials?: boolean;
    } {
        return { ...this.getOptionsWithRequestOptions(params, addHeader), responseType: 'blob', observe: 'response' };
    }

    getOptionsWithRequestOptions(params: any, addHeader: boolean = false): {
        headers?: HttpHeaders;
        observe?: 'body';
        params?: HttpParams;
        reportProgress?: boolean;
        withCredentials?: boolean;
    } {
        let headers = new HttpHeaders();

        if (addHeader) {
            headers = headers.append('Content-Type', 'application/json');
        }

        if (environment.addForgerockHeaders) {
            headers = headers.append('uid', 'localUser');
            headers = headers.append('uniqueid', this.personalId);
            // headers = headers.append('fullName', 'Local User');
            // uncomment below code for vendor admin role
            // headers = headers.append('role', 'cn=EE_VEND_ADMN');
            // headers = headers.append('role', 'cn=EE_VEND_ADMN,|cn=EE_SUPUSR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=EE_SUPVRS,');
            // headers = headers.append('role', 'cn=EE_SUPUSR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=EE_SUPVRS,|cn=EE_FIS_SUPERVISOR,|cn=EE_FIS_CENTRALWKR');
            headers = headers.append('role', 'cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN');
            // headers = headers.append('role', 'cn=EE_SUPUSR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=EE_SUPVRS,|cn=EE_FIS_LDHWKR');
            // headers = headers.append('role', 'cn=EE_SUPUSR');//EE_EGWKR, EE_SUPVRS, EE_SUPUSR, EE_FIS_LDHWKR, EE_IAR_SUPERVISOR,EE_IAR_CASEWRKR
            // headers = headers.append('caseId', ConstantsService.humanServiceModel.benefitsCase.caseId);
            // headers = headers.append('department', 'EE_EGWKR');
        }
        // EE_SUPUSR
        headers = headers.append('Accept-Language', 'en-US');
        let tempCaseId = '';

        if (ConstantsService.humanServiceModel &&
            ConstantsService.humanServiceModel.benefitsCase &&
            ConstantsService.humanServiceModel.benefitsCase.caseId &&
            ConstantsService.humanServiceModel.benefitsCase.caseId != '') {
            tempCaseId = ConstantsService.humanServiceModel.benefitsCase.caseId;
        }
        else if (ConstantsService.humanServiceModel &&
            ConstantsService.humanServiceModel.benefitsCaseSearches &&
            ConstantsService.humanServiceModel.benefitsCaseSearches.search &&
            ConstantsService.humanServiceModel.benefitsCaseSearches.search.caseId) {
            tempCaseId = ConstantsService.humanServiceModel.benefitsCase.caseId;
        }

        headers = headers.append('caseId', tempCaseId);
        // if (isResponseBlob) {
        //     headers = headers.append('responseType','arraybuffer');
        // }

        if (this.storageService.allowTimeTravel && this.storageService.getTimeTravelDate()) {
            let appDate = this.storageService.getTimeTravelDate();
            headers = headers.append('X-DateTime', appDate.toISOString());
        }

        let customParams: HttpParams = new HttpParams();
        for (const key in params) {
            if (key) {
                customParams = customParams.append(key, params[key]);
            }
        }
        // let requestOptions: any = { headers: headers, search: customParams };
        // if (isResponseBlob) {
        //     requestOptions['responseType'] = ResponseContentType.Blob;
        // }
        this.emitTimer();

        return { headers, params: customParams };
    }

    fileChange(event) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('file', file);
            let headers = new HttpHeaders();
            /** No need to include Content-Type in Angular 4 */
            // headers = headers.append('Content-Type', 'multipart/form-data');
            // headers = headers.append('Accept', 'application/json');
            headers = headers.append('Accept-Language', 'en-US');
            this.http.post(environment.baseUrlDev + ApiManager.fileUpload, formData, { headers, observe: 'response' }).pipe(
                map((res: HttpResponse<any>) => res.body),
                catchError(error => observableThrowError(error)))
                .subscribe(
                    data => console.log('success'),
                    error => console.log(error)
                )
        }
    }

    ApiWithCaseId(
        url: string,
        params: any,
        requestType: number,
        caseId: string,
        body: any,
        option?: string,
        successMethod?: Function,
        failureMethod?: Function) {
        let result: Observable<HttpResponse<any>>;
        this.slimLoadingBarService.start(() => {
            console.log('Loading complete');
        });
        if (requestType === ApiManager.GET) {
            result = this.http.get(
                environment.baseUrlDev + ApiManager[url] + '/' + caseId + ApiManager[option], this.getOptionsWithRequestOptionsJsonResponse(params)
            );
        }
        else if (requestType === ApiManager.POST) {
            result = this.http.post(
                environment.baseUrlDev + ApiManager[url] + '/' + caseId +
                ApiManager[option], body, this.getOptionsWithRequestOptionsJsonResponse(params, true)
            );
        }
        else if (requestType === ApiManager.PUT) {
            result = this.http.put(
                environment.baseUrlDev + ApiManager[url] + '/' + caseId +
                ApiManager[option], body, this.getOptionsWithRequestOptionsJsonResponse(params)
            );
        }
        else if (requestType === ApiManager.DELETE) {
            result = this.http.delete(environment.baseUrlDev + ApiManager[url] + '/' + caseId +
                ApiManager[option], this.getOptionsWithRequestOptionsJsonResponse(params)
            );
        }

        return result.pipe(map((res: HttpResponse<any>) => {
            console.log('API Call Success');
            this.slimLoadingBarService.complete();
            this.emitTimer();
            if (successMethod !== undefined) {
                successMethod();
            }
            if (res.body) {
                return res.body;
            }
            else {
                return res;
            }
        }), catchError((error: HttpErrorResponse | any) => {
            console.log('API call failure: Catch');
            this.slimLoadingBarService.stop();
            let errMsg = '';
            if (!(error instanceof ErrorEvent) && !(error instanceof Error)) {
                const body = error.error || '';
                const err = body.error || JSON.stringify(body);
                errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
            }
            else {
                errMsg = error.message ? error.message : error.toString();
            }
            console.error(errMsg);
            if (failureMethod !== undefined) {
                failureMethod();
            }
            return observableThrowError(errMsg);
        }));
    }

    // getAgNumber(caseId): Observable<any> {
    //     let headers = new HttpHeaders();
    //      headers = headers.append('Content-Type', 'application/json');
    //      headers = headers.append('uid', 'localUser');
    //      headers = headers.append('role', 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL');
    //      headers = headers.append('cookie', 'amlbcookie=01; amlbcookie=01; iPlanetDirectoryPro=AQIC5wM2LY4SfczTrszle3aiyeSLDtbsTb-4NMNFx8CvrIo.*AAJTSQACMDIAAlNLABMxNDE3OTAxNzExODQ0MjE1MzE5AAJTMQACMDE.*; iPlanetDirectoryPro=AQIC5wM2LY4SfczTrszle3aiyeSLDtbsTb-4NMNFx8CvrIo.*AAJTSQACMDIAAlNLABMxNDE3OTAxNz');
    //     return this.http.get('http://localhost:8080/eeapi/application-process/bi/ag-num/' + caseId, {headers, observe: 'response' }).pipe(
    //       map((res: HttpResponse<any>) => res.body),
    //       catchError((error: HttpErrorResponse) => observableThrowError(error.error.error || 'Server error')),);
    //   }
}
