import { Injectable, Injector } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertType } from 'app/model/alert';
import { StorageService } from './storage.service';
import { ConstantsService } from './constants.service';

@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router, public storage: StorageService) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                }
                else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    clientLogout() {
        this.storage.destroyAll();
        window.location.reload(true);
        const goto = '&goto=';
        const currentUrl = ConstantsService.logoutUrl + goto + ConstantsService.loginURL;
        window.location.replace(currentUrl);
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, status: number, keepAfterRouteChange = false) {
        this.alert(AlertType.Success, message, keepAfterRouteChange, status);
    }

    error(message: string, status: number, keepAfterRouteChange = false) {
        this.alert(AlertType.Error, message, keepAfterRouteChange, status);
    }

    info(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Info, message, keepAfterRouteChange);
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Warning, message, keepAfterRouteChange);
    }

    alert(type: AlertType, message: string, keepAfterRouteChange = false, status?: number) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        if (status == 403) {
            this.subject.next(<Alert>{ type: type, message: "Server responded as Permission Denied", errorCode: status });
        }
        else if (status == 302) {
            this.subject.next(<Alert>{ type: type, message: "Session got Expired, Application will logout", errorCode: status });
            this.clientLogout();
        }
        else if (!status) {
            this.subject.next(<Alert>{ type: type, message: "Server not responding, Please contact Admin", errorCode: status });
            this.clientLogout();
        }
        else if (status == 502 || status == 501)
            this.subject.next(<Alert>{ type: type, message: "Proxy Error or Bad Gateway, Please contact Admin", errorCode: status });
        else if (status == 500)
            this.subject.next(<Alert>{ type: type, message: "Please Contact E&E Support Desk", errorCode: status });
        else
            this.subject.next(<Alert>{ type: type, message: message, errorCode: status });
    }

    clear() {
        // clear alerts
        this.subject.next();
    }
}