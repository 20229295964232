import {BaseBean} from "./base-bean.class";

export class AbsentParentPhysicalAttributes  extends BaseBean{
  constructor (
    public height: string = '',
    public weight: string = '',
    public hairColor: string = '',
    public eyeColor: string = ''
  ) {
    super();
  }
}
