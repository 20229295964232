import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiManager } from 'app/common/api-manager.service';
import { ConstantsService } from 'app/common/constants.service';
import { HelpTextContainer } from 'app/model/api/help-text-container.class';

@Injectable()
export class HelpTextService {

  constructor(private router: Router, private apiManager: ApiManager, private consts: ConstantsService) { }

  getHelpText(requestModuleName: string, requestPageName: string, requestSectionID: string) {
    let params: any = [];
    params['moduleName'] = requestModuleName;
    params['page'] = requestPageName;
    if (requestSectionID) {    // optional
      params['sectionId'] = requestSectionID;
    }
    this.apiManager.fetchData('helpText', params, ApiManager.GET, undefined).pipe(
      map((res: Response) => {
        return res;
      })).subscribe(res => {
        let helpContainer: HelpTextContainer = res as any as HelpTextContainer;
        if (helpContainer && helpContainer.helpTexts && helpContainer.helpTexts[requestModuleName]) {
          for (let module of helpContainer.helpTexts[requestModuleName]) {
            if (module[requestPageName]) {
              for (let hepltexts of module[requestPageName]) {
                ConstantsService.helpTextMap.set(hepltexts.sectionId, hepltexts.helpText);
                ConstantsService.helpTextMapBehaviorSubjectNext();
              }

            }
          }

        }
      });
  }
}